import * as React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Button } from '@mui/material';
import Input from 'Shared/Input';
import useForm from 'hooks/useForm';

const InfoLegal = ({
  user, onChange, onClose, onSave,
}) => {
  const { values, onChange: onChangeForm } = useForm(user || {});

  const OnHandleSave = () => {
    onChange({ target: { name: 'rc', value: values?.rc } });
    onChange({ target: { name: 'ice', value: values?.ice } });
    onChange({ target: { name: 'city', value: values?.city } });
    onChange({ target: { name: 'idFiscal', value: values?.idFiscal } });
    onChange({ target: { name: 'patente', value: values?.patente } });
    onSave(values);
  };

  return (
    <Box>
      <Typography
        sx={{
          color: '#4D4D4D',
          fontSize: '18px',
          fontWeight: 600,
          mb: '42px',
          textAlign: 'center',
        }}
      >
        Ajout d’informations légales
      </Typography>
      <Input
        value={values?.ice}
        onChange={onChangeForm}
        margin="dense"
        label="ICE:"
        name="ice"
      />
      <Box sx={{ display: 'flex', width: { xs: '100% !important', ms: '100% !important' }, flexDirection: { xs: 'column', ms: 'column', md: 'row' } }}>
        <Box sx={{ mr: { xs: 0, ms: 0, md: '20px' }, width: { xs: '100% !important', ms: '100% !important' } }}>
          <Input
            value={values?.rc}
            onChange={onChangeForm}
            margin="dense"
            label="Registre de commerce:"
            name="rc"
          />
        </Box>
        <Box sx={{ width: { xs: '100% !important', ms: '100% !important' } }}>
          <Input
            value={values?.city}
            onChange={onChangeForm}
            margin="dense"
            label="Ville:"
            name="city"
          />
        </Box>

      </Box>
      <Input
        value={values?.idFiscal}
        onChange={onChangeForm}
        margin="dense"
        label="Idientifiant Fiscal:"
        name="idFiscal"
      />
      <Input
        value={values?.patente}
        onChange={onChangeForm}
        margin="dense"
        label="Patente:"
        name="patente"
      />
      <Box sx={{
        height: '1px', backgroundColor: '#ccc', width: '100%', mb: '20px', mt: '20px',
      }}
      />
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <Button
          onClick={onClose}
          variant="contained"
          color="inherit"
          sx={{
            border: '2px solid #CCCCCC', color: '#CCCCCC', backgroundColor: '#FFFFFF', textTransform: 'capitalize', mr: '14px',
          }}
        >
          Annuler
        </Button>
        <Button
          onClick={OnHandleSave}
          variant="contained"
          color="primary"
          sx={{ textTransform: 'capitalize' }}
        >
          Enregistrer
        </Button>
      </Box>
    </Box>
  );
};

export default InfoLegal;
