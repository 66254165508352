import useDebounce from 'hooks/useDebounce';
import useNetworkLoader from 'hooks/useNetworkLoader';
import React, { useCallback, useState } from 'react';
import { toast } from 'react-toastify';
import ConfirmDialog from 'Shared/ConfirmDialog';
import EnhancedTable from 'Shared/EnhancedTable';
import Password from '../Password/Password';
import {
  deleteUser, getUsers,
} from '../UsersService';
import { columns } from './constants';

const UserList = ({ selectUser, update, filters }) => {
  const [usersData, setUsersData] = useState([]);
  const [user, setUser] = useState();
  const { callApi } = useNetworkLoader();
  // const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [openPassword, setOpenPassword] = useState(false);
  const deboucedFilter = useDebounce(filters, 1000);

  const handleClose = () => {
    setOpen(false);
  };
  const handleClosePassword = () => {
    setOpenPassword(false);
  };
  const firePopup = (u, setOpenFun) => () => {
    setUser(u);
    setOpenFun(true);
  };
  const getUserList = useCallback(async (page) => {
    callApi(() => getUsers(page, filters), (data) => {
      // eslint-disable-next-line no-param-reassign
      data.content = data.content.map((u) => ({
        ...u,
        action: {
          edit: selectUser(u),
          deleteUser: firePopup(u, setOpen),
          password: firePopup(u, setOpenPassword),
        },
      }));
      setUsersData(data);
    });
  }, [deboucedFilter]);

  const handleDelete = async () => {
    callApi(() => deleteUser(user.id), () => {
      setOpen(false);
      getUserList();
      toast.success('Utilisateur a été supprimé avec succès');
    });
  };
  return (
    <>
      {open && (
        <ConfirmDialog
          title="Suppression"
          content={`Voulez vous vraiment supprimer l'utilisateur ${user?.name} ?`}
          handleClose={handleClose}
          handleClick={handleDelete}
        />
      )}
      {openPassword && (
      <Password
        updateList={setUsersData}
        handleClose={handleClosePassword}
        user={user}
      />
      )}
      <EnhancedTable
        pagination
        getData={getUserList}
        rows={usersData.content || []}
        headCells={columns}
        update={update}
        count={usersData?.totalElements}
        rowsPerPageOptions={[10, 15, 20]}
      />
    </>
  );
};

export default UserList;
