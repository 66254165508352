import useNetworkLoader from 'hooks/useNetworkLoader';
import React, { useState, useEffect } from 'react';
import EnhancedTable from 'Shared/EnhancedTable';
import NoData from 'Shared/NoData';
import { formatAmount } from 'Utils/ulilities';
import {
  Box, Tab, Tabs, TextField, Typography,
} from '@mui/material';
import useForm from 'hooks/useForm';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import { format } from 'date-fns';
import { getExpireds, getInprogress, getPublicUrlByRef } from '../AccountServices';
import { unpaidColumns } from './constants';
import { PaimentDetails, PreviouInvoice } from './Unpaid';
import { getTitleOfTypeMF } from '../ActualState';
import { RestDate } from './shared';
import { OtherFilters } from '../../Catalog/Families';

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
    sx: {
      color: '#4D4D4D',
      fontFamily: 'Poppins',
      fontSize: { xs: '12px', md: '14px' },
      fontWeight: 600,
      letterSpacing: 0,
      textTransform: 'none',
      width: { xs: '50%', md: 'unset' },
    },
  };
}
const Inprogress = ({ isSmallScreen }) => {
  const [invoices, setInvoices] = useState([]);
  const [details, setDetails] = useState([]);
  const [paid, setImpaid] = useState(false);
  const [title, setTitle] = useState('');
  const [open, setOpen] = useState(false);
  const [linkPieceReg, setLinkPieceReg] = useState(null);
  const [openFile, setOpenFile] = useState(false);
  const { values, onChange } = useForm({
    tab: 0,
    startDate: null,
    endDate: null,
    souche: [],
  });
  const handleChange = (event, newValue) => {
    onChange({ name: 'tab', value: newValue });
  };

  const { callApi } = useNetworkLoader();
  const onHandleClickInprogress = (listTitle, echeances, rgImpaye) => {
    setOpen(true);
    setTitle(listTitle);
    if (echeances.length > 0) {
      const toIndex = Math.floor(echeances.length / 2);
      const newEcheances = echeances.map((echeance, index) => {
        if (toIndex === index) {
          return {
            ...echeance,
            totalOrderAmount: formatAmount(
              echeances.reduce((total, e) => total + e.orderAmount, 0),
            ),
          };
        }
        return echeance;
      });
      setDetails(newEcheances);
      setImpaid(!rgImpaye);
    } else {
      setDetails(echeances);
    }
  };
  useEffect(() => {
    callApi(
      () => {
        const filters = {
          startDate: values.startDate && format(values.startDate, 'yyyy-MM-dd'),
          endDate: values.endDate && format(values.endDate, 'yyyy-MM-dd'),
          souche: values.souche.length === 1 ? values.souche[0] : '',
        };
        return values.tab === 0
          ? getInprogress(filters, onHandleClickInprogress)
          : getExpireds(filters, onHandleClickInprogress);
      },
      (data) => {
        setInvoices(data);
      },
    );
  }, [values]);

  const onHandleDownloadFile = (event, reference) => {
    callApi(
      () => getPublicUrlByRef(reference),
      (data) => {
        window.open(data.fileName);
      },
    );
    event.preventDefault();
    event.stopPropagation();
  };
  const onHandlePrevious = (event, reference) => {
    callApi(
      () => getPublicUrlByRef(reference),
      (data) => {
        setLinkPieceReg(data.fileName);
        setOpenFile(true);
      },
    );
    event.preventDefault();
    event.stopPropagation();
  };
  const handleChangeD = (name) => (newValue) => {
    onChange({ name, value: newValue });
  };
  const onChangeType = (type) => {
    if (values.souche.indexOf(type) === -1) {
      onChange({ name: 'souche', value: [...values.souche, type] });
    } else {
      const newValues = values.souche.filter((item) => item !== type);
      onChange({ name: 'souche', value: newValues });
    }
  };
  const reg = getTitleOfTypeMF('reg');
  const expiredReg = getTitleOfTypeMF('expiredReg');
  console.log(invoices);
  return (
    <>
      <PaimentDetails
        open={open}
        setOpen={setOpen}
        title={title}
        details={details}
        inprogress={values.tab === 0}
        paid={paid}
      />
      <PreviouInvoice open={openFile} setOpen={setOpenFile} title={title} link={linkPieceReg} />
      <Box
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column-reverse', md: 'unset' },
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Tabs
          value={values.tab}
          onChange={handleChange}
          sx={{
            border: '1px solid #CCCCCC',
            backgroundColor: '#FFFFFF',
            borderBottom: 'none',
            display: 'inline-block',
            width: { xs: '100%', md: 'unset' },
          }}
          TabIndicatorProps={{
            sx: {
              height: '5px',
              backgroundColor: '#007BC6',
            },
          }}
        >
          <Tab
            label={(
              <>
                <Typography sx={{ fontSize: { xs: '11px', md: '16px' } }}>{reg}</Typography>
              </>
            )}
            {...a11yProps(0)}
          />
          <Tab
            label={(
              <>
                <Typography sx={{ fontSize: { xs: '11px', md: '16px' } }}>{expiredReg}</Typography>
              </>
            )}
            {...a11yProps(0)}
          />
        </Tabs>
        <Box
          sx={{
            display: 'flex',
            mt: { xs: '16px', md: 'unset' },
            mb: { xs: '20px', md: 'unset' },
            flexDirection: { xs: 'column', md: 'unset' },
            alignItems: 'center',
          }}
        >
          <Box sx={{ display: 'flex', mb: { xs: '16px', md: 'unset' } }}>
            <Box sx={{ backgroundColor: '#FFF', width: '154px', position: 'relative' }}>
              {values.startDate !== null && <RestDate onHandleRest={handleChangeD('startDate')} />}
              <DesktopDatePicker
                label="date début:"
                inputFormat="dd/MM/yyyy"
                value={values.startDate}
                onChange={handleChangeD('startDate')}
                renderInput={(params) => <TextField size={isSmallScreen && 'small'} {...params} />}
              />
            </Box>
            <Box
              sx={{
                backgroundColor: '#FFF',
                width: '154px',
                ml: '16px',
                mr: '16px',
                position: 'relative',
              }}
            >
              {values.endDate !== null && <RestDate onHandleRest={handleChangeD('endDate')} />}
              <DesktopDatePicker
                label="date de fin:"
                inputFormat="dd/MM/yyyy"
                value={values.endDate}
                onChange={handleChangeD('endDate')}
                renderInput={(params) => <TextField size={isSmallScreen && 'small'} {...params} />}
              />
            </Box>
          </Box>
          <OtherFilters
            filters={values.souche}
            onChangeFilter={onChangeType}
            items={[
              { name: 'Pièce Moteur', id: 'PM' },
              { name: 'Huile', id: 'HUILE' },
            ]}
            hideTitle
            isSmall
            sx={{ flexDirection: { xs: 'row', md: 'column' } }}
          />
        </Box>
      </Box>
      {invoices?.length > 0 && (
        <EnhancedTable
          static
          pagination
          rows={invoices || []}
          headCells={unpaidColumns(values.tab === 1, onHandleDownloadFile, onHandlePrevious)}
          // resetSort={initPageable}
          count={invoices?.length}
          rowsPerPageOptions={[10, 15]}
          fill={!isSmallScreen}
          tableWidth={isSmallScreen && { width: '1000px' }}
          slice
        />
      )}
      {invoices?.length === 0 && (
        <NoData message={`vous n’avez pas de ${values.tab === 0 ? reg : expiredReg}`} />
      )}
    </>
  );
};

export default Inprogress;
