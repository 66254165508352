import React from 'react';
import ReactDOM from 'react-dom';
import 'react-toastify/dist/ReactToastify.css';
import { debugContextDevtool } from 'react-context-devtool';
import { ThemeProvider } from '@mui/styles';
import { createTheme } from '@mui/material/styles';

import PreLogin from 'Components/PreLogin';
import 'fonts/SF-Pro-Display-Regular.otf';
import 'fonts/FontsFree-Net-SFProDisplay-Bold.ttf';
import 'fonts/SF-Pro-Display-Medium.otf';
import './index.css';
import 'Utils/ulilities';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import App from './App';

const theme = createTheme();
/*
{
  overrides: {
    MuiButton: {
      sizeLarge: {
        height: 56,
      },
      containedSizeSmall: {
        background: '#10277C',
        color: '#FFFFFF',
        fontFamily: 'SFProDisplayBold',
        borderRadius: 8,
        textTransform: 'Capitalize',
      },
    },
  },
}
*/

const container = document.getElementById('root');

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <PreLogin>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <App />
        </LocalizationProvider>
      </PreLogin>
    </ThemeProvider>
  </React.StrictMode>,
  container,
);

// eslint-disable-next-line no-underscore-dangle
if (window.__REACT_CONTEXT_DEVTOOL_GLOBAL_HOOK) {
  debugContextDevtool(container, {
    disable: process.env.NODE_ENV === 'production',
  });
}
