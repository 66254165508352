import { Typography } from '@mui/material';
import React from 'react';

export const applyRemise = (remise, remiseExceptionnelle) => (
  remiseExceptionnelle
    ? remise + remiseExceptionnelle
    : remise
);

const Available = ({ available, sx }) => (
  <Typography sx={{
    color: available ? '#27AE60' : '#EA2027',
    fontSize: { xs: '12px', md: 14 },
    fontWeight: '600',
    ...sx,
  }}
  >
    { (available && 'Disponible') || 'Non Disponible' }
  </Typography>
);

export default Available;
