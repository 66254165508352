import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import GlobalLoader from 'Shared/GlobalLoader/GlobalLoader';
import { addUser, defaultMessage, resetUserPassword } from '../UsersService';

const Password = ({ handleClose, user, updateList }) => {
  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState('');
  const handleUpdatePassword = async () => {
    try {
      setLoading(true);
      const pass = Math.random().toString(36).slice(-8);
      if (user.hasAccount) {
        await resetUserPassword({ code: user.code, newPassword: pass });
      } else {
        await addUser({
          password: pass,
          username: user.code,
          role: 'ROLE_CLIENT',
        });
      }

      setPassword(pass);
    } catch (e) {
      const { response: { data: { statusLabel = defaultMessage } = {} } = {} } = e;
      toast.error(statusLabel);
    } finally {
      setLoading(false);
    }
  };
  const handleCloseByLoading = () => {
    if (password && !user.hasAccount) {
      updateList((prev) => ({
        ...prev,
        content: prev.content.map((u) => {
          let localUser = u;
          if (u.code === user.code) {
            localUser = { ...localUser, hasAccount: true };
          }
          return localUser;
        }),
      }));
    }
    handleClose();
  };
  return (
    <>
      {loading && <GlobalLoader size="100vh" />}
      <Dialog open>
        <DialogTitle id="alert-dialog-title">
          {`Changer Le mot de passe de l'utilisateur ${user.name}`}
        </DialogTitle>
        <DialogContent>
          {!password && (
          <Typography>
            {!user.hasAccount && 'Êtes-vous sûr de vouloir Générer un mot de passe?'}
            {user.hasAccount && 'Êtes-vous sûr de vouloir Réinitialiser le mot de passe?'}
          </Typography>
          )}
          {password && (
          <Typography>
            Mot de passe :
            {' '}
            {password}
          </Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseByLoading}>
            Annuler
          </Button>
          {!password && (
          <Button onClick={handleUpdatePassword} color="primary" variant="contained">
            {!user.hasAccount && 'Générer'}
            {user.hasAccount && 'Réinitialiser'}
          </Button>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Password;
