export const SET_NETWORK_ON = 'SET_NETWORK_ON';
export const setNetworkON = () => ({
  type: SET_NETWORK_ON,
});

export const SET_NETWORK_OFF = 'SET_NETWORK_OFF';
export const setNetworkOFF = () => ({
  type: SET_NETWORK_OFF,
});

export const CHANGE_PANIER_OPEN_STATE = 'CHANGE_PANIER_OPEN_STATE';
export const setPanierOpenState = (isOpen) => ({
  type: CHANGE_PANIER_OPEN_STATE,
  isOpen,
});

export const ADD_PANIER = 'ADD_PANIER';
export const addPanier = (name, id) => ({
  type: ADD_PANIER,
  name,
  id,
});

export const ADD_PRODUCT_TO_PANIER = 'ADD_PRODUCT_TO_PANIER';
export const addProductToPanier = (toName, quantity) => ({
  type: ADD_PRODUCT_TO_PANIER,
  toName,
  quantity,
});

export const REMOVE_PANIER = 'REMOVE_PANIER';
export const removePanier = (name) => ({
  type: REMOVE_PANIER,
  name,
});

export const INIT_PANIERS = 'INIT_PANIERS';
export const initPaniers = (paniers) => ({
  type: INIT_PANIERS,
  paniers,
});

export const CHANGE_PANIER_LIST_OPEN = 'CHANGE_PANIER_LIST_OPEN';
export const setOpenPanierList = (isOpen, product) => ({
  type: CHANGE_PANIER_LIST_OPEN,
  isOpen,
  product,
});

export const SET_PRODUCT_TO_ADD = 'SET_PRODUCT_TO_ADD';
export const setSelectedProductToAdd = (product) => ({
  type: SET_PRODUCT_TO_ADD,
  product,
});

export const REMOVE_PRODUCT_FROM_CART = 'REMOVE_PRODUCT_FROM_CART';
export const removeProductFromCat = (name, ids) => ({
  type: REMOVE_PRODUCT_FROM_CART,
  ids,
  name,
});

export const SET_PROFILE = 'SET_PROFILE';
export const setProfile = (profile) => ({
  type: SET_PROFILE,
  profile,
});

export const SET_COMMERCIALS = 'SET_COMMERCIALS';
export const setCommercials = (data) => ({
  type: SET_COMMERCIALS,
  data,
});

export const SET_EXPEDITIONS = 'SET_EXPEDITIONS';
export const setExpeditions = (data) => ({
  type: SET_EXPEDITIONS,
  data,
});

export const VALIDATE_CART = 'VALIDATE_CART';
export const validatedCart = (name) => ({
  type: VALIDATE_CART,
  name,
});
export const SET_PANIER_PRODOCT = 'SET_PANIER_PRODOCT';
export const setPanierProducts = (name, products) => ({
  type: SET_PANIER_PRODOCT,
  name,
  products,
});
