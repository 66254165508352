import React from 'react';
import Verify from 'Images/verify.svg';
import Truckfast from 'Images/truck-fast.svg';
import Callfast from 'Images/call-calling.svg';
import ListBlue from 'Images/list-blue.svg';
import { CardMedia, Grid, Typography } from '@mui/material';
import { Box } from '@mui/system';

const Infos = [
  {
    Img: () => (
      <CardMedia
        component="img"
        src={Verify}
        sx={{
          heigth: '48px', width: '48px', mr: { xs: '10px', md: '21px' }, ml: { xs: '11px', md: 0 },
        }}
      />
    ),
    title: 'Pièces de qualité',
    label: 'Première monte',
  },
  {
    Img: () => (
      <CardMedia
        component="img"
        src={Truckfast}
        sx={{
          heigth: '48px', width: '48px', mr: { xs: '10px', md: '21px' }, ml: { xs: '11px', md: 0 },
        }}
      />
    ),
    title: 'Livraison express',
    label: 'J+1 partout au Maroc',
  },
  {
    Img: () => (
      <CardMedia
        component="img"
        src={Callfast}
        sx={{
          heigth: '48px', width: '48px', mr: { xs: '10px', md: '21px' }, ml: { xs: '11px', md: 0 },
        }}
      />
    ),
    title: 'SAV performant',
    label: 'Satisfaction garantie',
  },
  {
    Img: () => (
      <CardMedia
        component="img"
        src={ListBlue}
        sx={{
          heigth: '48px', width: '48px', mr: { xs: '10px', md: '21px' }, ml: { xs: '11px', md: 0 },
        }}
      />
    ),
    title: 'Large gamme',
    label: '+50 000 références',
  },
];
const InfoClientBar = () => (
  <Grid
    container
    sx={{
      backgroundColor: { md: '#fff', xs: '#f8f8f8' },
      mt: '21px',
      boxShadow: { md: '0 2px 4px 2px rgba(0,0,0,0.07)', xs: 'none' },
      heigth: '84px',
    }}
  >
    {Infos.map(({ Img, title, label }, index) => (
      <Grid
        item
        xs={6}
        md={3}
        sx={{
          heigth: '84px',
          mt: '7px',
          pr: { xs: index % 2 === 0 ? '3.5px' : 0, md: 0 },
          pl: { xs: index % 2 !== 0 ? '3.5px' : 0, md: 0 },
        }}
      >
        <Box sx={{
          display: 'flex',
          alignItems: 'center',
          p: { md: '18px 0 16px 0', xs: 0 },
          justifyContent: 'center',
          backgroundColor: { md: 'inherit', xs: '#F6F6F6' },
          boxShadow: { xs: '0 2px 4px 2px rgba(0,0,0,0.07)', md: 'none' },
          height: { xs: '60px', md: 'unset' },
        }}
        >
          <Img />
          <Box>
            <Typography sx={{
              color: '#4D4D4D',
              fontSize: { md: '16px', xs: '12px' },
              fontWeight: 500,
            }}
            >
              {title}
            </Typography>
            <Typography sx={{
              color: '#8F8F8F',
              fontSize: { md: '14px', xs: '10px' },
            }}
            >
              {label}
            </Typography>
          </Box>
        </Box>
      </Grid>
    ))}
  </Grid>
);

export default InfoClientBar;
