import { toast } from 'react-toastify';

import { securePost, secureGet } from 'config/apiConfig';

export const defaultMessage = 'Erreur système';

export const updateProfile = async (profile) => {
  const { data } = await securePost('/users/updateProfile', profile);
  return data;
};

export const getProfile = async () => {
  const { data } = await secureGet('/users/me');
  return data;
};

export const updatePassword = async (body) => {
  try {
    await securePost('/users/password/change', body);
  } catch (error) {
    const message = error.response.data?.statusLabel || 'ERROR';
    toast.error(message);
    return false;
  }
  return true;
};
