import React from 'react';
import { CircularProgress, Grid } from '@mui/material';

const GlobalLoader = ({ size }) => (
  <Grid
    container
    spacing={0}
    direction="column"
    alignItems="center"
    justify="center"
    style={{
      minHeight: size,
      backgroundColor: '#00000017',
      position: 'fixed',
      justifyContent: 'center',
      display: 'flex',
      top: 0,
      left: 0,
      zIndex: 10000000,
    }}
  >
    <CircularProgress />
  </Grid>
);

GlobalLoader.propTypes = {};

export default GlobalLoader;
