/* eslint-disable no-case-declarations */
/* eslint-disable prefer-template */
import {
  Button, CardMedia, Divider, Typography,
} from '@mui/material';
import { Box } from '@mui/system';
import React, { useMemo, useState } from 'react';
import ConfirmDialog from 'Shared/ConfirmDialog';
import { toast } from 'react-toastify';
import useNetworkLoader from 'hooks/useNetworkLoader';
import { ClientContactPhoneTypeList } from './constants';
import EditIcon from '../../Images/edit@3x.svg';
import Trash from '../../Images/trash.svg';
import { updateProfile } from './UsersService';

const styleLabel = {
  color: '#8F8F8F',
  fontSize: '14px',
  mr: '10px',
};
const styleValue = {
  color: '#4D4D4D',
  fontSize: '14px',
};
const ContactView = ({ user, edit, onUpdateProfile }) => {
  const [ind, setIndex] = useState(0);
  const [openDelete, setOpenDelete] = useState(false);
  const [selectedContact, setSelectedContact] = useState(null);
  const [indDelete, setIndDelete] = useState(0);
  const { callApi } = useNetworkLoader();

  const onClose = () => {
    setOpenDelete(false);
    setIndDelete(0);
  };
  const openDeletePopUp = (c, i) => () => {
    setSelectedContact(c);
    setOpenDelete(true);
    setIndDelete(i);
  };
  const contacts = useMemo(() => {
    switch (user?.contacts?.length) {
      case 0:
        setIndex(0);
        return [{ empty: true, primary: true }, { empty: true }];
      case 1:
        setIndex(1);
        return user?.contacts[0]?.primary
          ? [user?.contacts[0], { empty: true }]
          : [{ empty: true, primary: true }, user?.contacts[0]];
      default:
        // eslint-disable-next-line no-shadow
        let contacts = [...(user?.contacts || [])];
        if (contacts.length >= 2) {
          const [c1, c2, ...rest] = contacts;
          if (c2.primary) {
            contacts = [c2, c1, ...rest];
          } else {
            contacts = [c1, c2, ...rest];
          }
        }
        setIndex(contacts.length);
        return contacts;
    }
  }, [user?.contacts]);
  const handleDeleteProfile = async () => {
    const clonedContacts = [...contacts];
    clonedContacts.splice(indDelete, 1);
    callApi(() => updateProfile({
      ...user,
      contacts: clonedContacts.filter((e) => !e.empty),
    }), () => {
      toast.success('Votre Action a été bien effectuée');
      onClose();
      setSelectedContact(null);
      setIndDelete(0);
      onUpdateProfile();
    });
  };
  return (
    <>
      {openDelete && (
      <ConfirmDialog
        title="Suppression"
        content={'Voulez vous vraiment supprimer le contact ' + selectedContact?.fullName}
        handleClose={onClose}
        handleClick={handleDeleteProfile}
      />
      )}
      <Box>
        <Box sx={{ display: 'flex', marginBottom: '30px' }}>
          <Typography sx={{
            color: '#4D4D4D',
            fontSize: '18px',
            fontWeight: 'bold',
            alignSelf: 'flex-end',
          }}
          >
            Contact:
          </Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={() => edit(ind,
              contacts.find((c) => c.primary && c.id)
                ? {}
                : { primary: true })}
            sx={{
              border: '2px solid #007BC6',
              color: '#007BC6',
              backgroundColor: '#FFFFFF',
              textTransform: 'capitalize',
              '&:hover': { color: '#FFF' },
              marginRight: '0px',
              marginLeft: 'auto',
            }}
          >
            Ajouter un contact
          </Button>
        </Box>
        {contacts.map((contact, index) => (
          <Box sx={{ marginTop: '25px' }}>
            <Box sx={{ display: 'flex' }}>
              <Typography sx={{
                color: '#4D4D4D',
                fontSize: '16px',
                fontWeight: 'bold',
                marginBottom: '27px',
              }}
              >
                contact
                {' '}
                {index + 1}
                :
              </Typography>
              {!contact.empty && (
              <>
                <CardMedia
                  component="img"
                  src={EditIcon}
                  onClick={() => edit(index, contact)}
                  sx={{
                    height: '24px',
                    width: '24px',
                    cursor: 'pointer',
                    ml: '10px',
                    mr: '30px',
                  }}
                />
                <CardMedia
                  component="img"
                  src={Trash}
                  onClick={openDeletePopUp(contact, index)}
                  sx={{
                    height: '24px',
                    width: '24px',
                    cursor: 'pointer',
                    ml: '10px',
                  }}
                />
              </>
              )}
            </Box>
            {!contact.empty && (
            <>
              <Box sx={{ marginBottom: '22px' }}>
                <Typography sx={styleLabel} display="inline">Nom et prénom:</Typography>
                <Typography sx={styleValue} display="inline">{contact.fullName}</Typography>
              </Box>
              <Box sx={{ marginBottom: '22px' }}>
                <Typography sx={styleLabel} display="inline">Adresse:</Typography>
                <Typography sx={styleValue} display="inline">{contact.address}</Typography>
              </Box>
              <Box sx={{ marginBottom: '41px' }}>
                <Typography sx={styleLabel} display="inline">Numéro de télephone:</Typography>
                <Typography sx={styleValue} display="inline">{contact.phones?.reduce((prev, curr) => prev + (prev ? ', ' : '') + curr.phone + ' - ' + ClientContactPhoneTypeList.find((e) => e.value === curr.type)?.label, '')}</Typography>
              </Box>
              <Divider />
            </>
            )}
            {contact.empty && (
            <>
              <Typography sx={{ ...styleLabel, marginBottom: '22px' }}>
                Aucun numéro ne figure sur votre profile
              </Typography>
              <Typography
                sx={{
                  ...styleLabel, color: '#007BC6', marginBottom: '41px', cursor: 'pointer',
                }}
                onClick={() => edit(ind, contact)}
              >
                Ajouter un numéro de contact
                {' '}
                {index + 1}
              </Typography>
              <Divider />
            </>
            )}
          </Box>
        ))}
      </Box>
    </>
  );
};

export default ContactView;
