import React, { useContext, useEffect } from 'react';
import {
  Button,
  Typography,
} from '@mui/material';
import FullScreenDialog from 'Shared/FullModal';
import { addPanier, setPanierOpenState } from 'Shared/ConfigContext/Actions';
import ConfigContext from 'Shared/ConfigContext';
import Input from 'Shared/Input';
import useForm from 'hooks/useForm';
import { Box } from '@mui/system';
import { toast } from 'react-toastify';
import { addCart } from 'Services';
import useNetworkLoader from 'hooks/useNetworkLoader';

const AddNewPanier = () => {
  const {
    dispatch,
    state: { profile, paniers, panierOpenState },
  } = useContext(ConfigContext);
  const { callApi } = useNetworkLoader();
  const onClose = () => {
    dispatch(setPanierOpenState(false));
  };
  const { values, onChange } = useForm({ name: '' });

  const onHandleAdd = () => {
    const cartName = `${values.name}_${paniers.length}`;
    const isExist = paniers.find((item) => item.name === cartName);
    if (isExist) {
      toast.error('Panier existe déjà merci de choisir un autre nom');
      return;
    }
    callApi(() => addCart(cartName), (cartId) => {
      dispatch(addPanier(cartName, cartId));
      onChange({ target: { value: profile?.name, name: 'name' } });
      toast.success('Panier créé avec succès');
      onClose();
    });
  };
  useEffect(() => {
    if (profile?.name) {
      onChange({ target: { value: profile?.name, name: 'name' } });
    }
  }, [profile]);
  return (
    <FullScreenDialog open={panierOpenState} setOpen={onClose}>
      <Box sx={{ mr: { xs: 0, md: '150px' }, ml: { xs: 0, md: '150px' }, textAlign: 'center' }}>
        <Typography sx={{
          mt: '100px',
          color: '#4D4D4D',
          fontSize: { xs: '16px', md: '21px' },
          fontWeight: 600,
          textAlign: 'center',
        }}
        >
          Création d’un nouveau panier
        </Typography>
        <Typography sx={{
          m: '51px 0px',
          color: '#8F8F8F',
          fontSize: { xs: '16px', md: '14px' },
          textAlign: 'center',
        }}
        >
          La création d’un panier vous permet d’ajouter des produits et d’avoir un bon de commande
          et/ou une facture relatif à la commande que vous allez passer.
          Vous pouvez créer plusieurs commande avec différents produits.
        </Typography>
        <Input
          value={values.name}
          onChange={onChange}
          margin="dense"
          hint="Entrer le nom du panier"
          name="name"
          sx={{ width: '365px' }}
        />
        <Box sx={{
          display: 'flex', flexDirection: 'row', justifyContent: 'center', mt: '63px',
        }}
        >
          <Button
            onClick={onClose}
            variant="contained"
            color="inherit"
            sx={{
              border: '1px solid #CCCCCC', color: '#CCCCCC', backgroundColor: '#FFFFFF', textTransform: 'capitalize', width: '129px',
            }}
          >
            Annuler
          </Button>
          <Button
            disabled={values.name === ''}
            onClick={onHandleAdd}
            variant="contained"
            color="primary"
            sx={{ textTransform: 'capitalize', ml: '15px', width: '175px' }}
          >
            Enregistrer
          </Button>
        </Box>
      </Box>
    </FullScreenDialog>
  );
};

export default AddNewPanier;
