import React, { useEffect, useLayoutEffect } from 'react';
import withRoles, { WithTypesComponent } from 'HOC/withRoles';
import {
  Box, CardMedia, Typography, Tabs, Tab, useMediaQuery,
} from '@mui/material';
import { useTheme, styled } from '@mui/material/styles';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import List from '@mui/material/List';
import SyncSharpIcon from '@mui/icons-material/SyncSharp';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import MuiDrawer from '@mui/material/Drawer';
import { getDashboardData } from 'Components/Home/HomeServices';
import useNetworkLoader from 'hooks/useNetworkLoader';
import RenderOnDemandThenHide from 'Components/RenderOnDemandThenHide';
import useUser from 'hooks/useUser';
import Wallet from '../../Images/wallet.svg';
import Receipt from '../../Images/receipt.svg';
import ChartDashbord from './ChartDashbord';
import ActualState, { getTitleOfTypeMF } from './ActualState';
import Invoices from './Tabs/Invoices';
import Inprogress from './Tabs/Inprogress';
import { getInvoiceTotals } from './AccountServices';

const drawerWidth = 295;
const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  position: 'relative',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  'z-index': 1,
  position: 'relative',
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  'z-index': 1,
  overflowX: 'hidden',
  position: 'relative',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    boxShadow: '0 2px 4px 2px rgba(0,0,0,0.07)',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

const menu = [
  { key: 0, label: { MF: 'Tableau de Bord', default: 'Tableau de Bord' }, icon: Wallet },
  /* {
    key: 1,
    label: { MF: 'Suivi de commandes', default: 'Suivi de commandes' },
    icon: Dollar,
  },
  {
    key: 1,
    label: { MF: 'Devis Rejetés', default: 'Mes impayés' },
    icon: Dollar,
  }, */
  {
    key: 1,
    label: { MF: 'Devis approuvés', default: 'Mes factures' },
    icon: Receipt,
  },
  {
    key: 2,
    label: { MF: 'En cours de validation', default: 'Réglements' },
    icon: Wallet,
  },
];

const titles = [
  () => getTitleOfTypeMF('ecd'),
  // () => 'Mes impayés',
  () => getTitleOfTypeMF('invoice'),
  () => 'Mes régelement',
];

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
    sx: {
      '&.MuiButtonBase-root': { minHeight: '44px' },
      color: '#4D4D4D',
      fontFamily: 'Poppins',
      fontSize: '10px',
      fontWeight: 600,
      letterSpacing: 0,
      textTransform: 'none',
    },
  };
}

const Account = () => {
  const [open, setOpen] = React.useState(true);
  const ref = React.useRef(null);
  const [mainWidth, setMainWidth] = React.useState(0);
  const { callApi } = useNetworkLoader();
  const isMF = useUser();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

  useLayoutEffect(() => {
    setMainWidth(ref.current.offsetWidth - drawerWidth - 24);
  }, []);

  const [monthsData, setMonthsData] = React.useState({
    monthList: [],
    reglementMonthList: [],
  });
  const [selectedValue, setSelectedValue] = React.useState(0);
  const [totals, setTotals] = React.useState({ regle: 0, noRegle: 0 });
  useEffect(() => {
    callApi(getInvoiceTotals, (data) => {
      setTotals({ regle: data.totalRegle || 0, noRegle: data.totalNotRegle || 0 });
    });
  }, []);

  const onRefreshDashbord = () => {
    callApi(getDashboardData, (data) => setMonthsData(data));
  };

  useEffect(() => {
    onRefreshDashbord();
  }, []);

  const handleDrawerClose = () => {
    setOpen(!open);
    setMainWidth(open ? mainWidth + (drawerWidth - 65) : mainWidth - (drawerWidth - 65));
  };
  const handleSelectItem = (key) => () => {
    setSelectedValue(key);
  };
  return (
    <Box ref={ref} sx={{ display: 'flex' }}>
      <RenderOnDemandThenHide visible={!isSmallScreen}>
        <Drawer variant="permanent" open={open}>
          <Divider />
          <List>
            {menu.map((item) => (
              <>
                <ListItem
                  key={item.key}
                  disablePadding
                  sx={{
                    display: 'block',
                    borderLeft: selectedValue === item.key && '4px solid #007BC6',
                  }}
                >
                  <ListItemButton
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? 'initial' : 'center',
                      px: 2.5,
                    }}
                    selected={selectedValue === item.key}
                    onClick={handleSelectItem(item.key)}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 3 : 'auto',
                        justifyContent: 'center',
                      }}
                    >
                      <CardMedia component="img" src={item.icon} />
                    </ListItemIcon>
                    <ListItemText
                      primary={isMF ? item.label.MF : item.label.default}
                      sx={{
                        opacity: open ? 1 : 0,
                        color: selectedValue === item.key ? '#4D4D4D' : '#979797',
                      }}
                    />
                  </ListItemButton>
                </ListItem>
                <Divider />
              </>
            ))}
          </List>
        </Drawer>
      </RenderOnDemandThenHide>
      <Box
        sx={{
          width: { xs: '100%', md: mainWidth },
          p: { xs: 0, md: 3 },
          pt: { xs: '66.4px', md: 0 },
          minHeight: '500px',
        }}
      >
        <RenderOnDemandThenHide visible={isSmallScreen}>
          <Tabs
            value={selectedValue}
            onChange={(e, newValue) => setSelectedValue(newValue)}
            sx={{
              border: '1px solid #CCCCCC',
              backgroundColor: '#FFFFFF',
              borderBottom: 'none',
              display: 'inline-block',
              width: '100%',
            }}
            TabIndicatorProps={{
              sx: {
                height: '3px',
                backgroundColor: '#007BC6',
              },
            }}
            variant="scrollable"
          >
            {menu.map((item) => (
              <Tab
                icon={<CardMedia component="img" src={item.icon} sx={{ width: '16px' }} />}
                iconPosition="start"
                key={item.key}
                label={isMF ? item.label.MF : item.label.default}
                {...a11yProps(item.key)}
              />
            ))}
          </Tabs>
        </RenderOnDemandThenHide>
        <DrawerHeader>
          <IconButton
            onClick={handleDrawerClose}
            sx={{ display: { xs: 'none', md: 'unset' }, position: 'absolute', left: 0 }}
          >
            {open ? <ArrowBackIcon /> : <ArrowForwardIcon />}
          </IconButton>
          <Typography
            sx={{
              position: 'absolute',
              left: '9px',
              color: '#4D4D4D',
              fontSize: '12px',
              fontWeight: 'bold',
              display: { xs: 'unset', md: 'none' },
            }}
          >
            {isMF ? menu[selectedValue].label.MF : menu[selectedValue].label.default}
            {/* selectedValue === 3 && isSmallScreen && `(${totals.regle + totals.noRegle}DH)` */}
          </Typography>
          <IconButton>
            <SyncSharpIcon onClick={onRefreshDashbord} />
          </IconButton>
        </DrawerHeader>
        <Divider />
        <Typography
          sx={{
            color: '#4D4D4D',
            fontSize: { xs: '12px', md: '16px' },
            fontWeight: 'bold',
            ml: { xs: '7px', md: 0 },
            mb: { xs: '11px', md: '20px' },
            display: { xs: 'none', md: 'unset' },
          }}
        >
          {selectedValue === 0 && titles[selectedValue]()}
          {/* selectedValue === 3 && `(${formatAmount(totals.regle + totals.noRegle)}DH)` */}
        </Typography>
        <RenderOnDemandThenHide visible={selectedValue === 0}>
          <WithTypesComponent blockedTypes={['MF']}>
            <ChartDashbord
              monthList={monthsData.monthList}
              reglementMonthList={monthsData.reglementMonthList}
            />

            <Typography
              sx={{
                color: '#4D4D4D',
                fontSize: { xs: '12px', md: '16px' },
                fontWeight: 'bold',
                ml: { xs: '7px', md: 0 },
                mt: { xs: '20px', md: '30px' },
                mb: { xs: '14px', md: '20px' },
              }}
            >
              Encours Actuel
            </Typography>
          </WithTypesComponent>
          <ActualState {...monthsData} />
        </RenderOnDemandThenHide>
        {/* <RenderOnDemandThenHide visible={[1].includes(selectedValue)}>
          <UnderConstruction />
        </RenderOnDemandThenHide>
        <RenderOnDemandThenHide visible={selectedValue === 1}>
          <Unpaid />
        </RenderOnDemandThenHide> */}
        {/* hide only on production */}
        <RenderOnDemandThenHide visible={selectedValue === 1}>
          <Invoices totals={totals} isSmallScreen={isSmallScreen} />
        </RenderOnDemandThenHide>
        <RenderOnDemandThenHide visible={selectedValue === 2}>
          <Inprogress />
        </RenderOnDemandThenHide>
      </Box>
    </Box>
  );
};

export default withRoles('ROLE_CLIENT')(Account);
