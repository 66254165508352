import React from 'react';
import { Button, Typography } from '@mui/material';
import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import AutorenewIcon from '@mui/icons-material/Autorenew';

/* eslint-disable import/prefer-default-export */
const TitleStyle = {
  color: '#727CA2',
  fontSize: '12px',
  fontWeight: 500,
  background: '#F8F8FF',
  borderBottom: 'none',
  textTransform: 'uppercase',
};
const defaultColumnStyle = { color: '#333D66', fontSize: '14px' };
export const columns = [
  {
    id: 'code',
    label: 'Code client',
    style: TitleStyle,
    render: ({ value }) => <Typography sx={defaultColumnStyle}>{value}</Typography>,
  },
  {
    id: 'name',
    label: 'Nom client',
    style: TitleStyle,
    render: ({ value }) => <Typography sx={defaultColumnStyle}>{value}</Typography>,
  },
  {
    id: 'city',
    label: 'city',
    style: TitleStyle,
    render: ({ value }) => <Typography sx={defaultColumnStyle}>{value}</Typography>,
  },
  {
    id: 'email',
    label: 'email',
    style: TitleStyle,
    render: ({ value }) => <Typography sx={defaultColumnStyle}>{value}</Typography>,
  },
  {
    id: 'phone',
    label: 'phone',
    style: TitleStyle,
    render: ({ value }) => <Typography sx={defaultColumnStyle}>{value}</Typography>,
  },
  {
    id: 'action',
    label: 'Actions',
    style: TitleStyle,
    render: ({ value: { password }, row }) => (
      <>
        {!row.hasAccount && (
          <Button
            fullWidth
            variant="contained"
            color="success"
            startIcon={<AutorenewIcon />}
            onClick={password}
          >
            Générer mot de passe
          </Button>
        )}
        {row.hasAccount && (
          <Button
            fullWidth
            variant="contained"
            color="primary"
            startIcon={<RotateLeftIcon />}
            onClick={password}
          >
            Réinitialiser le mot de passe
          </Button>
        )}
      </>
    ),
  },
];
