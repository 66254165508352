import {
  // eslint-disable-next-line max-len
  USER_LOGIN, LOGIN_SUCCESS, LOGIN_ERROR, RESET_LOGIN, FIRST_LOGIN, SET_NETWORK_ON, SET_NETWORK_OFF,
} from './Actions';
import { initialState } from './UserContext';

const UserReducer = (state, action) => {
  switch (action.type) {
    case USER_LOGIN:
      return { ...state, status: 'loading' };
    case FIRST_LOGIN:
      return {
        ...state,
        firstLogin: {
          isFirstLogin: action.isFirstLogin, tmpToken: action.token, tmpUser: action.user,
        },
        status: 'success',
      };
    case LOGIN_SUCCESS:
      localStorage.setItem('token', action.token);
      localStorage.setItem('user', JSON.stringify(action.user));
      return {
        ...state, user: action.user, token: action.token, status: 'success',
      };
    case LOGIN_ERROR:
      return { ...state, status: 'error', errorMessage: action.message };
    case SET_NETWORK_ON:
      return { ...state, networking: state.networking + 1 };
    case SET_NETWORK_OFF:
      return { ...state, networking: state.networking - 1 };
    case RESET_LOGIN:
      return initialState;
    default:
      return state;
  }
};

export default UserReducer;
