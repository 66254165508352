import { securePut, secureGet } from 'config/apiConfig';
import { uniqBy, orderBy } from 'lodash';

export const defaultMessage = 'Erreur système';

export const validateCart = async (id, cart) => {
  const { data } = await securePut(`/carts/validate/${id}`, cart);
  return data;
};

export const getTimelineCart = async (bcNumber) => {
  const { data } = await secureGet(`/carts/timeline/${bcNumber}`);
  return uniqBy(orderBy(data, 'date', 'desc'), 'status');
};

export const getExpeditionFile = async (bcNumber) => {
  const { data } = await secureGet(`/carts/expedition/${bcNumber}`);
  return data;
};
