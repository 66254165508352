import {
  secureDelete, secureGet, securePost, securePut,
} from 'config/apiConfig';
import { orderBy } from 'lodash';

export const defaultMessage = 'Erreur système';

export const getCommercials = async () => {
  const { data } = await secureGet('/referentiel/commercials');
  return data;
};

export const getExpeditions = async () => {
  const { data } = await secureGet('/referentiel/expeditions');
  return data;
};

const getIntelCart = async (page, prevData) => {
  const { data } = await secureGet(`/carts?page=${page}&size=20`);

  if (data.length === 20) {
    return getIntelCart(page + 1, [...prevData, ...data]);
  }
  return orderBy([...prevData, ...data], 'createdAt', 'desc');
};
export const getCarts = async () => {
  const data = await getIntelCart(0, []);
  const finishedCart = data
    .filter((cart) => cart.status === 'BL_GENERE' && cart.orderedProductNoBLRefs.length > 0)
    .map((cart) => {
      const orderedProducts = cart.products
        .filter((product) => cart.orderedProductNoBLRefs.includes(product.reference));
      return { ...cart, products: orderedProducts, status: 'BL_GENERE_TERMINE' };
    })
    .filter((cart) => cart.products.length > 0);
  return [...data, ...finishedCart];
};

export const addCart = async (cartName) => {
  const { data } = await securePost('/carts/create', { name: cartName });
  return data;
};

export const addProductCart = async (cartId, reference, quantity) => {
  const { data } = await securePut(`/carts/addProduct/${cartId}`, { reference, quantity });
  return data;
};

export const deleteProductCart = async (cartId, productIds) => {
  const { data } = await securePut(`/carts/deleteProduct/${cartId}`, productIds);
  return data;
};

export const deleteCart = async (cartId) => {
  const { data } = await secureDelete(`/carts/delete/${cartId}`);
  return data;
};
