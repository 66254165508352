import {
  Box, Button, Grid, Paper, Typography, useMediaQuery,
} from '@mui/material';
import RenderOnDemandThenHide from 'Components/RenderOnDemandThenHide';
import withRoles from 'HOC/withRoles';
import useNetworkLoader from 'hooks/useNetworkLoader';
import useQuery from 'hooks/useQuery';
import React, {
  useCallback, useEffect, useState, useRef,
} from 'react';
import { Link } from 'react-router-dom';
import AnnouncementCard from 'Shared/AnnouncementCard';
import EdgeCard from 'Shared/EdgeCard/EdgeCard';
import Image from 'Shared/Image';
import InfoClientBar from 'Shared/InfoClientBar';
import Media from 'Components/Media';
import { useTheme } from '@mui/material/styles';
import defaultProduct from '../../Images/default_product.png';
import { getAnnoncementData, getCaterogyData } from './HomeServices';
import { ReactComponent as Logo } from '../../Images/logo_bar.svg';

const ProductFamily = ({
  familyPicture,
  name,
  subFamilies,
  id,
  familyLevel,
  parentId,
  totalProducts,
}) => {
  const ref = useRef();
  const [width, setWidth] = useState(null);
  useEffect(() => {
    setWidth(`${ref.current?.clientWidth}px`);
  }, [ref]);
  return (
    <Link
      to={familyLevel === 1 ? `/?family=${id}` : `/catalog?parentFamily=${parentId}&family=${id}`}
      style={{ textDecoration: 'none' }}
    >
      <Box
        sx={{
          display: 'flex',
          flexGrow: 1,
          backgroundColor: '#FFF',
          boxShadow: '0 2px 4px 2px rgba(0,0,0,0.07)',
          padding: '10px 10px',
          height: '100%',
        }}
      >
        <Box
          ref={ref}
          sx={{
            flex: 1,
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Image
            src={
              (familyPicture && `https://media.copima.ma/families/${familyPicture}`)
              || defaultProduct
            }
            alt="categorie"
            width={150}
          />
        </Box>
        <Box
          sx={{
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            position: 'relative',
            justifyContent: familyLevel !== 1 && 'center',
          }}
        >
          <Box>
            <Typography
              sx={{
                fontSize: 16,
                color: familyLevel === 1 ? '#4D4D4D' : '#007BC6',
                textTransform: 'uppercase',
                fontWeight: 600,
              }}
            >
              {name}
            </Typography>
            <RenderOnDemandThenHide visible={familyLevel === 1}>
              <Typography sx={{ fontSize: 12, color: '#4D4D4D', mb: 2 }}>
                {`${subFamilies?.length || 0} Sous-catégories`}
              </Typography>
              {subFamilies?.slice(0, 4).map((item) => (
                <Typography
                  sx={{
                    textTransform: 'lowercase',
                    color: '#8F8F8F',
                    fontWeight: 500,
                    fontSize: 14,
                    whiteSpace: 'nowrap',
                    width: width && width,
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                    '&:first-letter': { textTransform: 'uppercase' },
                  }}
                >
                  {item?.name}
                </Typography>
              ))}
            </RenderOnDemandThenHide>
            <RenderOnDemandThenHide visible={familyLevel !== 1}>
              <Typography sx={{ color: '#979797', fontSize: '14px', fontWeight: 600 }}>
                {`${totalProducts} produits`}
              </Typography>
            </RenderOnDemandThenHide>
          </Box>
        </Box>
      </Box>
    </Link>
  );
};

const Home = () => {
  const [products, setProducts] = useState([]);
  const [categoryLevel2, setCategoryLevel2] = useState([]);
  const [annoncements, setAnnoncements] = useState([]);
  const [annonceIndex, setAnnonceIndex] = useState(0);
  const [annoncementsData, setAnnoncementsData] = useState({});
  const [sliceTo, setSliceTo] = useState(6);
  const { callApi } = useNetworkLoader();
  const query = useQuery();
  const [selectedFamily, setSelectedFamily] = useState(null);

  // const { data: caterogies } = useCaterogyData();
  const onHandleSeeMore = () => {
    setSliceTo(products.length);
  };

  const getAnnouncements = useCallback(
    (p) => {
      callApi(
        () => getAnnoncementData(p),
        (as) => {
          setAnnoncementsData(as);
          setAnnoncements((prev) => [...prev, ...as.content]);
        },
      );
    },
    [setAnnoncements, setAnnoncementsData, setAnnonceIndex],
  );
  const getAnnonceByIndex = (index) => (event) => {
    event.stopPropagation();
    if (index + 1 >= annoncements.length && annoncements.length < annoncementsData.totalElements) {
      getAnnouncements(annoncementsData.number + 1);
    }
    setAnnonceIndex(index);
  };
  useEffect(() => {
    callApi(
      () => getCaterogyData(),
      (caterogies) => {
        const firstFamilies = caterogies.filter((item) => item.familyLevel === 1);
        setProducts(firstFamilies);
        getAnnouncements(0);
      },
    );
  }, []);
  useEffect(() => {
    const interval = setInterval(() => {
      if (annoncements.length) {
        setAnnonceIndex((prev) => (prev + 1) % annoncements.length);
      }
    }, 10000);
    return () => clearInterval(interval);
  }, [annoncements, setAnnonceIndex]);

  const restSelectedFamily = () => setSelectedFamily(null);
  useEffect(() => {
    const family = query.get('family');

    if (products.length === 0 || !family) {
      restSelectedFamily();
      return;
    }

    const category = products.find((product) => product.id === family);
    setSelectedFamily(category.name);
    setCategoryLevel2(category.subFamilies);
  }, [query, products]);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
  return (
    <Box sx={{ mb: 10, mt: 2 }}>
      <RenderOnDemandThenHide visible={!selectedFamily}>
        <Grid container spacing={{ xs: 0, md: 1 }}>
          <Grid item xs={12} md={8}>
            {annoncements?.length > 0 && annoncements[annonceIndex] && (
              <AnnouncementCard
                current={annonceIndex}
                announcement={annoncements[annonceIndex]}
                total={annoncementsData.totalElements}
                load={getAnnonceByIndex}
              />
            )}
            {!annoncements?.length && (
              <Paper
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  width: '100%',
                  height: '273px',
                  boxSizing: 'border-box',
                  padding: '25px',
                }}
              >
                <Typography sx={{ flexGrow: 1, fontSize: '29px' }}>
                  Bienvenue sur votre solution EDGE
                </Typography>
                <Logo />
              </Paper>
            )}
          </Grid>
          {!isSmallScreen && (
            <Grid item md={4} xs={0}>
              <EdgeCard />
            </Grid>
          )}
        </Grid>
      </RenderOnDemandThenHide>
      <RenderOnDemandThenHide visible={isSmallScreen}>
        <Media showArrows={false} showThumbs={false} isMobile />
      </RenderOnDemandThenHide>
      <InfoClientBar />
      <RenderOnDemandThenHide visible={!selectedFamily}>
        <Typography
          sx={{
            fontWeight: 600,
            fontSize: 20,
            color: '#4D4D4D',
            textTransform: 'uppercase',
            mb: 5,
            mt: 5,
          }}
        >
          Nos Catégories
        </Typography>
      </RenderOnDemandThenHide>
      <RenderOnDemandThenHide visible={selectedFamily}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            mt: '40px',
            mb: '40px',
          }}
        >
          <Typography
            sx={{
              color: '#007BC6',
              fontSize: '16px',
              fontWeight: 600,
              cursor: 'pointer',
              mr: '5px',
            }}
            onClick={restSelectedFamily}
          >
            Acceil
          </Typography>
          <Typography
            sx={{
              color: '#8F8F8F',
              fontSize: '16px',
              fontWeight: 600,
              textTransform: 'lowercase',
              '&:nth-letter(4)': { textTransform: 'uppercase' },
            }}
          >
            {` > ${selectedFamily}`}
          </Typography>
        </Box>
      </RenderOnDemandThenHide>
      <Grid container columnSpacing={{ xs: 0, md: 2 }} rowSpacing={5} sx={{ mb: 5 }}>
        {(selectedFamily ? categoryLevel2 : products).slice(0, sliceTo).map((product) => (
          <Grid item xs={12} md={4} key={product.id}>
            <ProductFamily {...product} />
          </Grid>
        ))}
      </Grid>
      <RenderOnDemandThenHide visible={!selectedFamily && sliceTo < 7}>
        <Box textAlign="center" sx={{ display: 'flow-root', mt: 4 }}>
          <Button color="primary" variant="contained" onClick={onHandleSeeMore}>
            Voir toutes les catégories
          </Button>
        </Box>
      </RenderOnDemandThenHide>
    </Box>
  );
};

export default withRoles('ROLE_CLIENT')(Home);
