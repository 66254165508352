import {
  secureGet, secureGetPdf, securePost, securePostPdf,
} from 'config/apiConfig';
import useSWR from 'swr';
import { format } from 'date-fns';
import { lastDateOnMonthDate } from 'Utils/ulilities';

export const defaultMessage = 'Erreur système';

const formatEcheance = (isRegle, data, profile) => {
  if (isRegle) {
    return data?.content.flatMap((inv) => (!inv.echeances || inv.echeances.length === 0
      ? inv
      : inv.echeances.map((echeance) => ({
        ...inv,
        ...echeance,
        echeance: new Date(
          lastDateOnMonthDate(inv.date).getTime()
                + parseInt(
                  inv.details[0]?.product.huile
                    ? profile?.nbrJourEcheanceH
                    : profile?.nbrJourEcheancePm,
                  10,
                )
                  * 24
                  * 60
                  * 60
                  * 1000,
        ),
      }))));
  }

  return data?.content.map((inv) => (!inv.echeances || inv.echeances.length === 0
    ? inv
    : {
      ...inv,
      rgDate: inv.echeances[0].rgDate,
      echeance: new Date(
        lastDateOnMonthDate(inv.date).getTime()
              + parseInt(
                inv.details[0]?.product.huile
                  ? profile?.nbrJourEcheanceH
                  : profile?.nbrJourEcheancePm,
                10,
              )
                * 24
                * 60
                * 60
                * 1000,
      ),
    }));
};

export function useMediaLinks() {
  // todo use SWR here get data and errors
  const { data } = useSWR('/attachments/media', secureGet);
  return { data: data?.data || [] };
}

export const getReleve = async (filters) => {
  const response = await securePostPdf('/orders/releve', filters, {
    headers: {
      'content-type': 'application/json',
      accept: 'application/pdf',
    },
  });
  return response;
};

export const getFactureByReference = async (reference) => {
  const response = await secureGetPdf(`/orders/getFacture/${reference}`, {
    headers: {
      'content-type': 'application/json',
      accept: 'application/pdf',
    },
  });
  return response;
};

export const getInvoices = async (pageable, filters, profile) => {
  let params = filters.tab !== 0 ? 'regle=true&' : 'regle=false&';
  params
    += filters.startDate && filters.startDate !== ''
      ? `startDate=${format(filters.startDate, 'yyyy-MM-dd')}&`
      : '';
  params
    += filters.endDate && filters.endDate !== ''
      ? `endDate=${format(filters.endDate, 'yyyy-MM-dd')}&`
      : '';
  params += filters.souche.length === 1 ? `souche=${filters.souche[0]}&` : '';
  const { data } = await secureGet(
    `/orders?${params}page=${pageable.page}&size=${pageable.size}&sort=${pageable.sort}`,
    {},
    {},
    false,
  );
  if (data?.content) {
    const invoices = formatEcheance(params === 'regle=true&', data, profile);
    return { ...data, content: invoices };
  }
  return data;
};

export const getInvoiceTotals = async () => {
  const { data } = await secureGet('/orders/totals');
  return data;
};

const formatter = (data, onClickRow, profile) => data.map(({ echeances, details, ...rest }) => {
  const echeancesUnique = echeances.unique();
  const numAttach = echeancesUnique.length;
  const rgImpaye = numAttach > 0 ? echeances[0].rgImpaye : null;
  return {
    ...rest,
    numAttach,
    rgImpaye,
    click: () => onClickRow(rest.reference, echeancesUnique, rgImpaye),
    echeance: profile
      ? new Date(
        lastDateOnMonthDate(rest.date).getTime()
              + parseInt(
                details[0]?.product.huile ? profile?.nbrJourEcheanceH : profile?.nbrJourEcheancePm,
                10,
              )
                * 24
                * 60
                * 60
                * 1000,
      )
      : undefined,
  };
});

export const getInprogress = async (filters, onHandleClickInprogress) => {
  let url = '/orders/encours';
  Object.keys(filters).forEach((key) => {
    if (filters[key]) {
      url += Array.isArray(filters[key])
        ? filters[key].reduce(
          (prev, curr) => `${prev}${!url?.includes('?') && !prev ? '?' : '&'}${key}=${curr}`,
          '',
        )
        : `${url?.includes('?') ? '&' : '?'}${key}=${filters[key]}`;
    }
  });
  const { data } = await securePost(url, null, {}, false);
  const formattedData = formatter(data, onHandleClickInprogress).map((e) => ({
    ...e,
    echeance: e.dateEcheance,
  }));
  return formattedData;
};

export const getExpireds = async (filters, onHandleClickInprogress) => {
  let url = '/orders/expireds';
  Object.keys(filters).forEach((key) => {
    if (filters[key]) {
      url += Array.isArray(filters[key])
        ? filters[key].reduce(
          (prev, curr) => `${prev}${!url?.includes('?') && !prev ? '?' : '&'}${key}=${curr}`,
          '',
        )
        : `${url?.includes('?') ? '&' : '?'}${key}=${filters[key]}`;
    }
  });
  const { data } = await securePost(url, null, {}, false);
  const formattedData = formatter(data, onHandleClickInprogress).map((e) => ({
    ...e,
    echeance: e.dateEcheance,
  }));
  return formattedData;
};

export const getUnpaid = async (onHandleClickUnpaid, profile) => {
  const { data } = await securePost('/orders/impaye');
  const formattedData = formatter(data, onHandleClickUnpaid, profile);
  return formattedData;
};

export const getPublicUrlByRef = async (ref) => {
  const { data } = await secureGet(`/orders/file/${ref}`);
  return data;
};
