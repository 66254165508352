import { secureGet } from 'config/apiConfig';
import useSWR from 'swr';

export const defaultMessage = 'Erreur système';

export function useMediaLinks() {
  // todo use SWR here get data and errors
  const { data } = useSWR('/attachments/media', secureGet);
  return { data: data?.data || [] };
}
