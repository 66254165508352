import React from 'react';
import defaultProduct from '../../Images/default_product.png';

const Image = ({ alt, ...props }) => (
  <img
    {...props}
    onError={({ currentTarget }) => {
      // eslint-disable-next-line no-param-reassign
      currentTarget.onerror = null; // prevents looping
      // eslint-disable-next-line no-param-reassign
      currentTarget.src = defaultProduct;
    }}
    alt={alt}
  />
);

export default Image;
