import {
  Box,
  Button,
  ClickAwayListener,
  Collapse,
  Grid,
  Grow,
  IconButton,
  InputBase,
  LinearProgress,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { SelectedPorduct } from 'Components/Catalog';
import { getProductsData } from 'Components/Catalog/CatalogServices';
import { getDashboardData } from 'Components/Home/HomeServices';
import RenderOnDemandThenHide from 'Components/RenderOnDemandThenHide';
import { format } from 'date-fns';
import React, {
  useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import FullScreenDialog from 'Shared/FullModal';
import { APP_PATH_NAME, findExpireDateAndToday, formatAmount } from 'Utils/ulilities';
import useDebounce from 'hooks/useDebounce';
import { toast } from 'react-toastify';
import ReactPiwik from 'react-piwik';
import { useHistory, useLocation } from 'react-router-dom';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import headerSearch from '../../Images/oem-auto-parts.png';
import { defaultMessage } from './LayoutServices';
import NoData from '../NoData';

export const round = (value, precision = 1) => Math.ceil(value * precision) / precision;

const HeaderCard = ({
  title,
  price,
  color,
  isProgress,
  value,
  headers,
  data,
  expanded,
  collapse = 0,
}) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
  const [visible, setVisible] = useState(false);
  return (
    <Paper
      elevation={isSmallScreen ? 1 : 3}
      sx={{
        mb: { xs: 0, md: 1.5 },
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          p: { xs: '0 0 0 10px', md: 3 },
          borderBottom: '1px solid #CCCCCC',
          background: '#FFF',
          zIndex: 1,
          alignItems: 'center',
        }}
      >
        <Typography
          sx={{
            flex: 2.5,
            color: '#4D4D4D',
            fontSize: { md: '20px', xs: '12px' },
            fontWeight: 'bold',
          }}
        >
          {title}
        </Typography>
        <RenderOnDemandThenHide visible={isProgress}>
          <Box
            sx={{
              width: '100%',
              flex: 1,
              textAlign: 'center',
              fontSize: '14px',
            }}
          >
            {`${round(value)}%`}
            <LinearProgress color="primary" variant="determinate" value={value} />
          </Box>
        </RenderOnDemandThenHide>
        <Typography
          sx={{
            color: `${color}`,
            fontSize: { md: '20px', xs: '12px' },
            fontWeight: 'bold',
            flex: 1.5,
            textAlign: 'right',
          }}
        >
          {`${price}DH`}
        </Typography>
        {visible && isSmallScreen && (
          <IconButton aria-label="expandLes" onClick={() => setVisible(false)}>
            <ExpandLessIcon fontSize="large" />
          </IconButton>
        )}
        {!visible && isSmallScreen && (
          <IconButton aria-label="expandMore" onClick={() => setVisible(true)}>
            <ExpandMoreIcon fontSize="large" />
          </IconButton>
        )}
      </Box>
      <Collapse in={isSmallScreen ? visible : expanded} timeout="auto" unmountOnExit>
        {data?.length > 0 && (
          <Grid
            container
            rowSpacing={3}
            sx={{
              p: '0 1.5em 1em 1.5em',
              height: 168,
              overflowX: 'hidden',
              overflowY: 'auto',
              mt: 0,
            }}
          >
            {!collapse && <Grid item xs={3} />}
            {data?.length > 0
              && headers?.map((item, index) => (
                <Grid item key={item} xs={collapse === index + 1 ? 6 : 3}>
                  <Typography sx={{ color: '#8F8F8F', fontSize: { xs: '11px', md: 12 } }}>
                    {item}
                  </Typography>
                </Grid>
              ))}
            {data?.map((item, index) => (
              <Grid item xs={collapse === (index % (4 - collapse)) + 1 ? 6 : 3}>
                <Typography
                  sx={{
                    color: item.color || '#4D4D4D',
                    fontSize: { xs: '11px', md: item.size || 14 },
                    fontWeight: 600,
                  }}
                >
                  {item.label}
                </Typography>
              </Grid>
            ))}
          </Grid>
        )}
        {!data?.length && <NoData message="vous n’avez pas de factures non réglées" />}
      </Collapse>
    </Paper>
  );
};

export const SearchResult = ({
  open,
  setOpen,
  anchorRef,
  setPage,
  total,
  products,
  isHeader = false,
}) => {
  const [selectedProduct, setSelectedProduct] = useState({});
  const [openProduct, setOpenProduct] = useState(false);
  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };
  const handleListKeyDown = (event) => {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  };
  const navigate = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setPage((p) => p + 1);
  };

  const onClickProduct = (product) => () => {
    setOpenProduct(!openProduct);
    setSelectedProduct(product);
  };

  return (
    <>
      <FullScreenDialog open={openProduct} setOpen={setOpenProduct}>
        <SelectedPorduct product={selectedProduct} onChangeProduct={setSelectedProduct} />
      </FullScreenDialog>
      <Popper
        style={{ top: isHeader && '150px' }}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <div>
                  <MenuList
                    id="menu-list-grow"
                    onKeyDown={handleListKeyDown}
                    sx={{
                      minWidth: '400px',
                      maxWidth: '900px',
                      maxHeight: '500px',
                      overflowY: 'auto',
                    }}
                  >
                    {products.map((po) => (
                      <MenuItem
                        sx={{ fontSize: { xs: '12px', sm: '16px' } }}
                        key={po.id}
                        onClick={onClickProduct(po)}
                      >
                        {`${po.reference}: ${po.name}`}
                      </MenuItem>
                    ))}
                  </MenuList>
                  {!products.length && (
                    <Typography fullWidth sx={{ textAlign: 'center' }}>
                      Aucun résultat trouvé
                    </Typography>
                  )}
                  {products.length < total && (
                    <Button onClick={navigate} fullWidth>
                      Voir plus
                    </Button>
                  )}
                </div>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};

const SearchArea = ({ isSmall, isSmallScreen }) => {
  const [products, setProducts] = useState([]);
  const [search, setSearch] = useState('');
  const [page, setPage] = useState(0);
  const [total, setTotal] = useState(20);
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  /* useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 250 && isSmallScreen) {
        setBockPosition('fixed');
      } else {
        setBockPosition('absolute');
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [isSmallScreen]); */
  const handleChange = (event) => {
    setPage(0);
    setSearch(event.target.value);
    if (!open) {
      setOpen(true);
    }
  };
  const handleToggle = () => {
    setProducts([]);
    setPage(0);
    setOpen(true);
  };

  const debounceValue = useDebounce(search, 600);

  /* const searchProduct = useCallback(async () => {
    const data = await getProductsData(`page=${page}`, { search });
    // todo send event to matomo when searched ref/page are empty
    console.log(page, search, data);
    return data;
  },
  [debounceValue, page]); */
  const searchDataProduct = useCallback(async () => {
    if (open) {
      try {
        const pos = await getProductsData(`page=${page}`, { search });
        if (pos.empty) {
          ReactPiwik.push(['trackEvent', 'Référence', 'Indisponible', search]);
        }
        setTotal(pos?.totalElements);
        setProducts((prev) => (pos.first ? pos?.content : [...prev, ...pos?.content]));
      } catch (e) {
        const { response: { data: { statusLabel = defaultMessage } = {} } = {} } = e;
        toast.error(statusLabel);
      }
    }
  }, [setProducts, setTotal, open, debounceValue, page]);
  useEffect(() => {
    searchDataProduct();
  }, [searchDataProduct]);
  const heightValue = useMemo(() => (isSmall ? 100 : 146), [isSmall]);
  return (
    <Box
      sx={{
        height: isSmallScreen ? 0 : heightValue,
        backgroundImage: isSmallScreen ? '' : `url(${headerSearch})`,
        backgroundSize: 'cover',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative',
      }}
    >
      <RenderOnDemandThenHide visible={!isSmallScreen}>
        <Paper
          component="form"
          sx={{
            display: 'flex',
            alignItems: 'center',
            width: '100%',
            borderRadius: 7,
            mr: '20%',
            ml: '20%',
            height: 62,
            position: 'relative',
          }}
        >
          <InputBase
            id="search-input-id"
            name="searchInput"
            type="text"
            autoComplete="on"
            value={search}
            ref={anchorRef}
            sx={{ ml: 5, flex: 1, height: '100%' }}
            placeholder="Rechercher par réference"
            inputProps={{ 'aria-label': 'Rechercher par réference' }}
            onChange={handleChange}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                e.preventDefault();
              }
            }}
          />
          <SearchResult
            products={products}
            total={total}
            open={open}
            setOpen={setOpen}
            anchorRef={anchorRef}
            setPage={setPage}
          />
          <Box
            sx={{
              backgroundColor: '#F79F1F',
              height: 62,
              display: 'flex',
              alignItems: 'center',
              pr: 4,
              pl: 4,
              borderTopRightRadius: 28,
              borderBottomRightRadius: 28,
              cursor: 'pointer',
            }}
            aria-label="search"
            onClick={handleToggle}
          >
            <Typography
              sx={{
                fontWeight: 'bold',
                color: '#FFF',
                fontSize: 14,
              }}
            >
              Lancer la recherche
            </Typography>
          </Box>
        </Paper>
      </RenderOnDemandThenHide>
    </Box>
  );
};

const SubHeader = ({ onSetSubHeaderH }) => {
  const { pathname } = useLocation();
  const ref = useRef();
  const [expanded, setExpanded] = React.useState(false);
  const [dashboard, setDashboard] = React.useState({});
  const history = useHistory();
  const isNotCatalog = history?.location.pathname !== APP_PATH_NAME.catalog;
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
  const extraProps = useMemo(
    () => (!isSmallScreen
      ? {
        onMouseEnter: () => setExpanded(true),
        onMouseLeave: () => setExpanded(false),
      }
      : {}),
    [isSmallScreen],
  );

  useEffect(() => {
    const optionHeight = 0;
    const height = ref.current?.clientHeight || 100;
    onSetSubHeaderH(height + optionHeight);
  }, [ref, pathname]);
  useEffect(async () => {
    const data = await getDashboardData();
    const encoursPm = data?.totalImpayePm + data?.totalNonReglePm + data?.totalRegEncoursPm;
    // eslint-disable-next-line max-len
    const encoursHuile = data?.totalImpayeHuile + data?.totalNonRegleHuile + data?.totalRegEncoursHuile;
    const total = data?.plafond + data?.plafondHuile;

    let totalUnpaid = 0;

    let unpaidMonthsFlatDatas = [];
    if (data?.impayes?.length) {
      unpaidMonthsFlatDatas = data?.impayes
        ?.map((item) => {
          totalUnpaid += item.amount;
          return [
            {
              label:
                item.echeances?.length > 0
                  ? format(new Date(item.echeances[0].rgImpaye), 'dd/MM/yyyy')
                  : '--',
            },
            { label: format(new Date(item.dateEcheance), 'dd/MM/yyyy'), color: '#EA2027' },
            // eslint-disable-next-line prefer-template
            { label: formatAmount(item.amount) + 'DH' },
          ];
        })
        .flatMap((item) => item);
    } else if (data?.nonRegles?.length) {
      const {
        amountEchue, countEchue, amountNonEchue, countNonEchue,
      } = data?.nonRegles?.reduce(
        (prev, curr) => {
          totalUnpaid += curr.amount;
          const { currentDate, dateEcheance } = findExpireDateAndToday(curr);
          if (dateEcheance < currentDate) {
            return {
              ...prev,
              amountEchue: prev.amountEchue + curr.amount,
              countEchue: prev.countEchue + 1,
            };
          }
          return {
            ...prev,
            amountNonEchue: prev.amountNonEchue + curr.amount,
            countNonEchue: prev.countNonEchue + 1,
          };
        },
        {
          amountEchue: 0,
          countEchue: 0,
          amountNonEchue: 0,
          countNonEchue: 0,
        },
      );
      unpaidMonthsFlatDatas = [
        { label: 'Facture non échues', color: '#CCCCCC' },
        { label: `${countNonEchue} factures`, color: '#8F8F8F' },
        { label: `${formatAmount(amountNonEchue)}DH`, color: '#8F8F8F' },
        { label: 'Facture échues', color: '#CCCCCC' },
        { label: `${countEchue} factures`, color: '#8F8F8F' },
        { label: `${formatAmount(amountEchue)}DH`, color: '#8F8F8F' },
      ];
    }
    setDashboard({
      encoursPm: formatAmount(encoursPm),
      encoursHuile: formatAmount(encoursHuile),
      encoursPmAndHuile: formatAmount(encoursPm + encoursHuile),
      total: formatAmount(total) || 0,
      progress: ((encoursPm + encoursHuile) * 100) / total || 0,
      progressPm: round((encoursPm * 100) / data?.plafond) || 0,
      progressHuile: round((encoursHuile * 100) / data?.plafondHuile) || 0,
      plafondPm: formatAmount(data?.plafond),
      plafondHuile: formatAmount(data?.plafondHuile),
      unpaidMonths: unpaidMonthsFlatDatas,
      totalUnpaid: formatAmount(totalUnpaid),
    });
  }, []);
  return (
    <Box
      ref={ref}
      sx={{
        position: { xs: 'relative', md: 'fixed' },
        backgroundColor: '#f8f8f8',
        width: '100%',
        zIndex: 3,
      }}
    >
      <Box sx={{ mt: 9, pl: { xs: 0, md: 17 }, pr: { xs: 0, md: 17 } }}>
        <RenderOnDemandThenHide visible={isNotCatalog}>
          <Grid container spacing={2} {...extraProps}>
            <Grid item xs={12} md={6}>
              <HeaderCard
                expanded={expanded}
                data={[
                  { label: 'PM (Pièces Moteurs):', color: '#CCCCCC', size: 11 },
                  { label: dashboard.encoursPm },
                  { label: dashboard.plafondPm },
                  { label: `${dashboard.progressPm}%` },
                  { label: ' H (Huile):', color: '#CCCCCC', size: 11 },
                  { label: dashboard.encoursHuile },
                  { label: dashboard.plafondHuile },
                  { label: `${dashboard.progressHuile}%` },
                ]}
                headers={['Encours', 'Plafond', 'Pourcentage']}
                title="Encours actuel:"
                price={dashboard.encoursPmAndHuile}
                color="#F79F1F"
                value={dashboard.progress && dashboard.progress > 0 ? dashboard.progress : 0}
                isProgress
              />
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              sx={{
                paddingTop: { xs: '0 !important', md: '16px !important' },
                mb: { xs: 1.5, md: 0 },
              }}
            >
              <HeaderCard
                expanded={expanded}
                data={dashboard.unpaidMonths}
                headers={
                  dashboard.unpaidMonths
                  && (dashboard.unpaidMonths[0]?.label === 'Facture non échues'
                    || !dashboard.unpaidMonths[0])
                    ? ['', '', '']
                    : ['Date impayé', 'Echéance', 'Montant']
                }
                title={
                  dashboard.unpaidMonths
                  && (dashboard.unpaidMonths[0]?.label === 'Facture non échues'
                    || !dashboard.unpaidMonths[0])
                    ? 'Factures non réglées:'
                    : 'Total impayés:'
                }
                price={dashboard.totalUnpaid || 0}
                color={
                  dashboard.unpaidMonths
                  && (dashboard.unpaidMonths[0]?.label === 'Facture non échues'
                    || !dashboard.unpaidMonths[0])
                    ? '#F79F1F'
                    : '#EA2027'
                }
                collapse={1}
              />
            </Grid>
          </Grid>
        </RenderOnDemandThenHide>
      </Box>
      <SearchArea isSmall={!isNotCatalog} isSmallScreen={isSmallScreen} />
    </Box>
  );
};

export default SubHeader;
