import React, { useContext, useEffect, useState } from 'react';
import { Box } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import UserContext from 'Shared/UserContext';
import useDebounce from '../../hooks/useDebounce';

export const isNetworkOnSelector = (state) => state.app.networking;

export default () => {
  // const classes = useStyles();
  const [open, setOpen] = useState(false);
  const {
    state: { networking },
  } = useContext(UserContext);
  const debouncedNetwork = useDebounce(networking > 0, 100);

  useEffect(() => {
    if (debouncedNetwork) {
      setOpen(true);
    } else if (open) {
      setTimeout(() => {
        setOpen(false);
      }, 200);
    }
  }, [debouncedNetwork]);
  if (!open) return null;

  // eslint-disable-next-line consistent-return
  return (
    <Box
      sx={{
        position: 'fixed',
        top: 0,
        left: 0,
        zIndex: '1500',
        padding: '2%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
        width: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
      }}
    >
      <CircularProgress size={100} color="primary" />
    </Box>
  );
};
