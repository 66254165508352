import React from 'react';
import {
  CardMedia,
  Checkbox, FormControlLabel, IconButton, Stack, Typography,
} from '@mui/material';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import { format } from 'date-fns';
import DeleteCart from '../../Images/delete_cart.svg';

/* eslint-disable import/prefer-default-export */
const TitleStyle = {
  color: '#727CA2',
  fontSize: '12px',
  fontWeight: 500,
  background: '#F8F8FF',
  borderBottom: 'none',
  textTransform: 'uppercase',
};
const defaultColumnStyle = { color: '#4D4D4D', fontSize: '12px' };
export const columns = (onHandleRemoveAnnouce, onHandleEdit) => ([
  {
    id: 'title',
    label: 'Titre',
    style: TitleStyle,
    order: 'title',
    render: ({ value }) => <Typography sx={defaultColumnStyle}>{value}</Typography>,
  },
  {
    id: 'startDate',
    label: 'Date début',
    style: TitleStyle,
    order: 'startDate',
    render:
      ({ value }) => <Typography sx={defaultColumnStyle}>{format(new Date(value?.replace('+0000', '+0100')), 'dd/MM/yyyy')}</Typography>,
  },
  {
    id: 'endDate',
    label: 'Date fin',
    style: TitleStyle,
    order: 'endDate',
    render:
      ({ value }) => <Typography sx={defaultColumnStyle}>{format(new Date(value?.replace('+0000', '+0100')), 'dd/MM/yyyy')}</Typography>,
  },
  {
    id: 'type',
    label: 'Type',
    style: TitleStyle,
    order: 'type',
    render: ({ value }) => <Typography sx={defaultColumnStyle}>{value}</Typography>,
  },
  {
    id: 'remise',
    label: 'Taux de remise',
    style: TitleStyle,
    order: 'remise',
    render: ({ value }) => <Typography sx={{ ...defaultColumnStyle, color: '#27AE60', fontWeight: 'bold' }}>{value && `-${value}%`}</Typography>,
  },
  {
    id: 'action',
    label: 'Actions',
    style: TitleStyle,
    render: ({ row }) => (
      <Stack direction="row" spacing={1}>
        <IconButton onClick={onHandleEdit(row)} aria-label="edit">
          <ModeEditIcon />
        </IconButton>
        <IconButton onClick={onHandleRemoveAnnouce(row.id)} aria-label="delete">
          <CardMedia
            component="img"
            src={DeleteCart}
          />
        </IconButton>
      </Stack>
    ),
  },
]);

export const columnsProduct = (allPromo, allNew, allInfo, onCheck, all) => [
  {
    id: 'reference',
    label: 'Référence',
    order: 'reference',
    render: ({ value }) => <Typography sx={defaultColumnStyle}>{value}</Typography>,
  },
  {
    id: 'name',
    label: 'Désignation',
    order: 'name',
    render: ({ value }) => <Typography sx={defaultColumnStyle}>{value}</Typography>,
  },
  {
    id: 'family',
    label: 'Famille',
    order: 'family',
    render: ({ value }) => <Typography sx={defaultColumnStyle}>{value?.name}</Typography>,
  },
  {
    id: 'promo',
    label: `Promotion (${all?.promo ? 'désélectionner' : 'tous'})`,
    style: { cursor: 'pointer' },
    onClick: allPromo,
    render: ({ value, row }) => (
      <FormControlLabel
        control={(
          <Checkbox
            onChange={onCheck(false, false, row.reference)}
            checked={value}
          />
            )}
        sx={{
          color: '#4D4D4D', textTransform: 'capitalize', fontSize: 10, fontWeight: 600,
        }}
      />
    ),
  },
  {
    id: 'new',
    label: `Nouveauté (${all?.new ? 'désélectionner' : 'tous'})`,
    style: { cursor: 'pointer' },
    onClick: allNew,
    render: ({ value, row }) => (
      <FormControlLabel
        control={(
          <Checkbox
            onChange={onCheck(true, false, row.reference)}
            checked={value}
          />
            )}
        sx={{
          color: '#4D4D4D', textTransform: 'capitalize', fontSize: 10, fontWeight: 600,
        }}
      />
    ),
  },
  {
    id: 'info',
    label: `Information (${all?.info ? 'désélectionner' : 'tous'})`,
    style: { cursor: 'pointer' },
    onClick: allInfo,
    render: ({ value, row }) => (
      <FormControlLabel
        control={(
          <Checkbox
            onChange={onCheck(false, true, row.reference)}
            checked={value}
          />
            )}
        sx={{
          color: '#4D4D4D', textTransform: 'capitalize', fontSize: 10, fontWeight: 600,
        }}
      />
    ),
  },
];

export const stepsAnnancement = ['Informations Générales', 'Séléction catégorie/produit'];

export const ANNOUCE_TYPE = { PRODUCT: 'PRODUCT', CATEGORY_PRODUCT: 'CATEGORY_PRODUCT' };

export const options = {
  options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'link', 'image', 'colorPicker', 'remove', 'history'],
  inline: {
    inDropdown: false,
    className: undefined,
    component: undefined,
    dropdownClassName: undefined,
    options: ['bold', 'italic', 'underline'],
  },
  blockType: {
    inDropdown: true,
    options: ['Normal', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6'],
    className: undefined,
    component: undefined,
    dropdownClassName: undefined,
  },
  fontSize: {
    options: [5, 6, 7, 8, 9, 10, 11, 12, 14, 16, 18, 24, 30, 36, 48, 60, 72, 96],
    className: undefined,
    component: undefined,
    dropdownClassName: undefined,
  },
  fontFamily: {
    options: ['Calibri', 'Times New Roman'],
    className: undefined,
    component: undefined,
    dropdownClassName: undefined,
  },
  list: {
    inDropdown: false,
    className: undefined,
    component: undefined,
    dropdownClassName: undefined,
    options: ['unordered', 'ordered', 'indent', 'outdent'],
  },
  textAlign: {
    inDropdown: false,
    className: undefined,
    component: undefined,
    dropdownClassName: undefined,
    options: ['left', 'center', 'right', 'justify'],
  },
  colorPicker: {
    className: undefined,
    component: undefined,
    popupClassName: undefined,
    colors: ['rgb(97,189,109)', 'rgb(26,188,156)', 'rgb(84,172,210)', 'rgb(44,130,201)',
      'rgb(147,101,184)', 'rgb(71,85,119)', 'rgb(204,204,204)', 'rgb(65,168,95)', 'rgb(0,168,133)',
      'rgb(61,142,185)', 'rgb(41,105,176)', 'rgb(85,57,130)', 'rgb(40,50,78)', 'rgb(0,0,0)',
      'rgb(247,218,100)', 'rgb(251,160,38)', 'rgb(235,107,86)', 'rgb(226,80,65)', 'rgb(163,143,132)',
      'rgb(239,239,239)', 'rgb(255,255,255)', 'rgb(250,197,28)', 'rgb(243,121,52)', 'rgb(209,72,65)',
      'rgb(184,49,47)', 'rgb(124,112,107)', 'rgb(209,213,216)'],
  },
  remove: { className: undefined, component: undefined },
  history: {
    inDropdown: false,
    className: undefined,
    component: undefined,
    dropdownClassName: undefined,
    options: ['undo', 'redo'],
    undo: { className: undefined },
    redo: { className: undefined },
  },
  image: {
    className: undefined,
    component: undefined,
    popupClassName: undefined,
    urlEnabled: true,
    uploadEnabled: true,
    alignmentEnabled: true,
    uploadCallback: undefined,
    previewImage: false,
    inputAccept: 'image/gif,image/jpeg,image/jpg,image/png,image/svg',
    alt: { present: false, mandatory: false },
    defaultSize: {
      height: 'auto',
      width: 'auto',
    },
  },
};
