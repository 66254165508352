import React from 'react';
import { Chip, Typography, Stack } from '@mui/material';
import { fr } from 'date-fns/locale';
import { format } from 'date-fns';
import { formatAmount, isDateValid, lastDateOnMonthDate } from 'Utils/ulilities';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import IconButton from '@mui/material/IconButton';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { getTitleOfTypeMF } from '../ActualState';

/* eslint-disable import/prefer-default-export */
const TitleStyle = {
  color: '#707070E0',
  fontSize: '12px',
  textTransform: 'uppercase',
  fontWeight: 600,
  background: 'transparent',
  borderBottom: 'none',
};
const defaultColumnStyle = { color: '#4D4D4D', fontSize: '12px' };
const fw = { fontWeight: 600 };

const regleColumns = (isRegle, isMF) => (isRegle
  ? [
    ...(isMF
      ? []
      : [
        {
          id: 'commandeId',
          label: getTitleOfTypeMF('pieceReg'),
          style: TitleStyle,
          render: ({ value }) => (
            <Typography sx={{ ...defaultColumnStyle, ...fw }}>{value}</Typography>
          ),
        },
      ]),
    {
      id: 'rgDate',
      label: getTitleOfTypeMF('recReg'),
      style: TitleStyle,
      render: ({ value }) => (
        <Typography sx={defaultColumnStyle}>
          {value ? format(new Date(value), 'dd/MM/yyyy', { locale: fr }) : ''}
        </Typography>
      ),
    },
    {
      id: 'statusReglement',
      label: 'Statut du règlement',
      style: TitleStyle,
      render: ({ row }) => (new Date(row.rgDate)
            <= new Date(lastDateOnMonthDate(row.date).getTime() + 15 * 24 * 60 * 60 * 1000) ? (
              <Chip style={{ backgroundColor: '#00B894', color: '#FFF' }} label="A Temps" />
        ) : (
          <Chip style={{ backgroundColor: '#EAC644', color: '#FFF' }} label="Retard" />
        )),
    },
    {
      id: 'statusPaiment',
      label: 'Statut du paiement',
      style: TitleStyle,
      render: ({ row }) => (new Date(row.rgDate) <= new Date(row.echeance) ? (
        <Chip style={{ backgroundColor: '#00B894', color: '#FFF' }} label="A Temps" />
      ) : (
        <Chip style={{ backgroundColor: '#EAC644', color: '#FFF' }} label="Retard" />
      )),
    },
  ]
  : []);

export const columns = (isRegle, isMF, onHandleDownloadFile) => [
  {
    id: 'date',
    label: 'Date',
    order: 'date',
    style: TitleStyle,
    render: ({ value }) => (
      <Typography sx={defaultColumnStyle}>
        {value
          ? format(new Date(value?.replace('+0000', '+0100')), 'dd/MM/yyyy', { locale: fr })
          : ''}
      </Typography>
    ),
  },
  {
    id: 'reference',
    label: isMF ? 'Devis' : 'Factures',
    order: 'reference',
    style: TitleStyle,
    render: ({ value }) => <Typography sx={{ ...defaultColumnStyle, ...fw }}>{value}</Typography>,
  },
  {
    id: 'amount',
    label: 'Montant (TTC)',
    order: 'amount',
    style: TitleStyle,
    render: ({ value }) => (
      <Typography sx={{ ...defaultColumnStyle, ...fw }}>{formatAmount(value)}</Typography>
    ),
  },
  {
    id: 'paymentMode',
    label: isRegle ? 'Condition paiement' : 'Moyen de réglement',
    order: 'paymentMode',
    style: TitleStyle,
    render: ({ value }) => <Typography sx={defaultColumnStyle}>{value?.name}</Typography>,
  },
  ...(isRegle && isMF
    ? []
    : [
      {
        id: 'echeance',
        label: 'Echéance',
        style: TitleStyle,
        render: ({ value }) => (
          <Typography sx={defaultColumnStyle}>
            {value && isDateValid(value) ? format(new Date(value), 'dd/MM/yyyy', { locale: fr }) : ''}
          </Typography>
        ),
      },
    ]),
  ...regleColumns(isRegle, isMF),
  {
    id: 'action',
    label: 'Actions',
    style: TitleStyle,
    render: ({ row }) => (
      <Stack direction="row" spacing={1}>
        <IconButton>
          <FileDownloadIcon onClick={() => onHandleDownloadFile(row.reference)} />
        </IconButton>
      </Stack>
    ),
  },
];

export const isValideDate = (date) => date === null || date?.toString() !== 'Invalid Date';

export const unpaidColumns = (isUnpaid, onHandleDownloadFile, onHandlePrevious) => [
  {
    id: 'reference',
    label: 'Numéro de pièce',
    style: TitleStyle,
    render: ({ value }) => <Typography sx={defaultColumnStyle}>{value}</Typography>,
  },
  {
    id: 'type',
    label: 'Nature',
    style: TitleStyle,
    render: ({ value }) => <Typography sx={{ ...defaultColumnStyle, ...fw }}>{value}</Typography>,
  },
  {
    id: 'amount',
    label: 'Montant',
    style: TitleStyle,
    render: ({ value }) => (
      <Typography sx={{ ...defaultColumnStyle, ...fw }}>{formatAmount(value)}</Typography>
    ),
  },
  {
    id: 'echeance',
    label: 'Date échéance',
    style: TitleStyle,
    render: ({ value }) => (
      <Typography sx={defaultColumnStyle}>
        {value ? format(new Date(value), 'dd/MM/yyyy', { locale: fr }) : ''}
      </Typography>
    ),
  },
  {
    id: 'rgDate',
    label: isUnpaid ? 'Date impayé' : 'Date de réception',
    style: TitleStyle,
    render: ({ row }) => {
      const value = isUnpaid ? row.rgImpaye : row.rgDate;
      return (
        <Typography sx={defaultColumnStyle}>
          {value ? format(new Date(value), 'dd/MM/yyyy', { locale: fr }) : ''}
        </Typography>
      );
    },
  },
  {
    id: 'numAttach',
    label: 'Nombre de Documents attachées',
    style: TitleStyle,
    render: ({ value }) => <Typography sx={defaultColumnStyle}>{value}</Typography>,
  },
  ...(isUnpaid
    ? [
      {
        id: 'status',
        label: 'STATUT',
        style: TitleStyle,
        render: ({ row }) => (!row.rgImpaye ? (
          <Chip style={{ backgroundColor: '#EAC644', color: '#FFF' }} label="PAYÉ" />
        ) : (
          <Chip style={{ backgroundColor: '#EAC644', color: '#FFF' }} label="IMPAYÉ" />
        )),
      },
    ]
    : []),
  {
    id: 'action',
    label: 'Actions',
    style: TitleStyle,
    render: ({ row }) => (
      <Stack direction="row" spacing={1}>
        <IconButton onClick={(e) => onHandlePrevious(e, row.reference)} aria-label="previou">
          <VisibilityIcon />
        </IconButton>
        <IconButton>
          <FileDownloadIcon onClick={(e) => onHandleDownloadFile(e, row.reference)} />
        </IconButton>
      </Stack>
    ),
  },
];

export const unpaidDetailColumns = [
  {
    id: 'rgImpaye',
    label: 'Date',
    style: TitleStyle,
    rowStyle: { borderBottom: 'unset' },
    render: ({ value }) => (
      <Typography sx={defaultColumnStyle}>
        {value ? format(new Date(value), 'dd/MM/yyyy', { locale: fr }) : ''}
      </Typography>
    ),
  },
  {
    id: 'commandeId',
    label: 'Devis',
    style: TitleStyle,
    rowStyle: { borderBottom: 'unset' },
    render: ({ value }) => <Typography sx={{ ...defaultColumnStyle, ...fw }}>{value}</Typography>,
  },
  {
    id: 'type',
    label: 'Nature',
    style: TitleStyle,
    rowStyle: { borderBottom: 'unset' },
    render: ({ value }) => <Typography sx={{ ...defaultColumnStyle, ...fw }}>{value}</Typography>,
  },
  {
    id: 'echeance',
    label: 'Echeance',
    style: TitleStyle,
    rowStyle: { borderBottom: 'unset' },
    render: ({ value }) => (
      <Typography sx={defaultColumnStyle}>
        {value ? format(new Date(value), 'dd/MM/yyyy', { locale: fr }) : ''}
      </Typography>
    ),
  },
  {
    id: 'orderAmount',
    label: 'Montant (TTC)',
    style: TitleStyle,
    rowStyle: { borderBottom: 'unset' },
    render: ({ value }) => (
      <Typography sx={{ ...defaultColumnStyle, ...fw }}>{formatAmount(value)}</Typography>
    ),
  },
  {
    id: 'amount',
    label: 'Montant imputé',
    style: TitleStyle,
    rowStyle: { borderBottom: 'unset' },
    render: ({ value }) => <Typography sx={defaultColumnStyle}>{value}</Typography>,
  },
  {
    id: 'totalOrderAmount',
    label: 'Total (TTC)',
    style: TitleStyle,
    rowStyle: { borderBottom: 'unset' },
    render: ({ value }) => <Typography sx={{ defaultColumnStyle, ...fw }}>{value}</Typography>,
  },
];
