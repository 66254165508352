import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Autocomplete from '@mui/material/Autocomplete';
import React, { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import GlobalLoader from 'Shared/GlobalLoader/GlobalLoader';
import ListboxComponent from 'Shared/ListboxComponent';
import { addUser, defaultMessage, getClients } from '../UsersService';
import { DEFAULT_VALUES, fields, ROLES } from './constants';

const UserAdd = ({ handleClose, user, updateList }) => {
  const [values, setValues] = useState(
    {
      ...DEFAULT_VALUES,
      password: DEFAULT_VALUES.password,
    },
  );
  const [clients, setClients] = useState([]);
  const [loading, setLoading] = useState(false);
  const getClientList = useCallback(async () => {
    try {
      setLoading(true);
      const clientData = await getClients();
      setClients(clientData);
    } catch (e) {
      const { response: { data: { statusLabel = defaultMessage } = {} } = {} } = e;
      toast.error(statusLabel);
    } finally {
      setLoading(false);
    }
  }, [setClients, setLoading]);
  useEffect(() => {
    getClientList();
  }, [getClientList]);
  const handleChange = ({
    target: {
      name, value, type, checked,
    },
  }) => {
    setValues({ ...values, [name]: type === 'checkbox' ? checked : value });
  };
  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const handleSubmit = async () => {
    try {
      setLoading(true);
      await addUser({
        ...Object.keys(DEFAULT_VALUES).reduce(
          (prev, curr) => ({ ...prev, [curr]: curr === 'username' ? values[curr]?.code : values[curr] }),
          {},
        ),
        role: 'ROLE_CLIENT',
        id: user ? user.id : undefined,
      });
      toast.success('Utilisateur ajouté avec succès');
      handleClose();
      updateList((prev) => !prev);
    } catch (e) {
      const { response: { data: { statusLabel = defaultMessage } = {} } = {} } = e;
      toast.error(statusLabel);
    } finally {
      setLoading(false);
    }
  };
  const isFilled = (user
    ? Object.keys(DEFAULT_VALUES).splice('password', 1)
    : Object.keys(DEFAULT_VALUES)
  ).reduce((prev, curr) => prev && values[curr], true);
  const mapValues = (name) => {
    switch (name) {
      case 'clients':
        return clients;
      case 'roles':
        return ROLES;
      default:
        return [];
    }
  };
  const handleInputChange = (a, value, name) => {
    setValues({ ...values, [name]: value });
  };
  return (
    <>
      {loading && <GlobalLoader size="100vh" />}
      <Dialog open onClose={handleClose} maxWidth="md" fullWidth>
        <DialogTitle>Ajouter Un utilisateur</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Grid container spacing={1}>
              {fields
                .filter((f) => (f.id !== 'password' ? true : !user))
                .map((field) => (
                  <Grid item xs={6} key={field.id}>
                    {field.type === 'text' && (
                      <TextField
                        size="small"
                        label={field.label}
                        variant="outlined"
                        value={values[field.id]}
                        name={field.id}
                        onChange={handleChange}
                        fullWidth
                      />
                    )}
                    {field.type === 'password' && (
                      <TextField
                        label={field.label}
                        size="small"
                        variant="outlined"
                        value={values[field.id]}
                        onChange={handleChange}
                        fullWidth
                        name={field.id}
                        type={values.showPassword ? 'text' : 'password'}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                                edge="end"
                              >
                                {values.showPassword ? <Visibility /> : <VisibilityOff />}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    )}
                    {field.type === 'select' && (
                    <FormControl
                      variant="outlined"
                      fullWidth
                      size="small"
                    >
                      <InputLabel>{field.label}</InputLabel>
                      <Select
                        name={field.id}
                        autoWidth
                        label={field.label}
                        onChange={handleChange}
                        value={values[field.id]}
                      >
                        {mapValues(field.valuesName).map((a) => (
                          <MenuItem value={field.valueItem(a)} key={field.valueItem(a)}>
                            {field.displayLabelItem(a)}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    )}
                    {
                      field.type === 'autocomplete' && (
                        <Autocomplete
                          options={mapValues(field.valuesName)}
                          onChange={(a, value) => handleInputChange(a, value, field.id)}
                          value={values[field.id]}
                          size="small"
                          fullWidth
                          autoHighlight
                          style={{ height: '56px' }}
                          getOptionLabel={(option) => option.reference || option.name}
                          ListboxComponent={ListboxComponent}
                          renderOption={(option) => (
                            <MenuItem value={field.valueItem(option)} key={field.valueItem(option)}>
                              {field.displayLabelItem(option)}
                            </MenuItem>
                          )}
                          filterOptions={(options, v) => options
                            .filter((option) => field.filterItems(option, v.inputValue))
                            .slice(0, 5000)}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={field?.label}
                              variant="outlined"
                              inputProps={{
                                ...params.inputProps,
                                autoComplete: 'new-password', // disable autocomplete and autofill
                              }}
                            />
                          )}
                        />
                      )
                    }
                  </Grid>
                ))}
            </Grid>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>
            Annuler
          </Button>
          <Button onClick={handleSubmit} variant="contained" color="primary" disabled={!isFilled}>
            Enregistrer
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default UserAdd;
