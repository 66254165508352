import React, { useContext, useEffect, useState } from 'react';
import {
  Button,
  Checkbox,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  TextField,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import {
  addProductToPanier,
  setPanierProducts,
  setOpenPanierList,
  setPanierOpenState,
} from 'Shared/ConfigContext/Actions';
import ConfigContext from 'Shared/ConfigContext';
import { Box } from '@mui/system';
import { toast } from 'react-toastify';
import { ReactComponent as Bag } from 'Images/bag-cross@3x.svg';
import RenderOnDemandThenHide from 'Components/RenderOnDemandThenHide';
import useNetworkLoader from 'hooks/useNetworkLoader';
import { addProductCart } from 'Services';
import Modal from '../Modal';

// eslint-disable-next-line no-unused-vars
const PaniersList = ({
  paniers,
  selectedPanier,
  handlePanierToggle,
  changeQuantity,
  quantity,
  gameQuantity = 1,
}) => (
  <List sx={{ width: '100%', maxHeight: '120px', overflowY: 'auto' }}>
    {paniers
      && paniers.map((panier, index) => {
        const labelId = `checkbox-${panier.name}`;
        const isChecked = panier.name === selectedPanier;
        return (
          <>
            <ListItem key={panier.name} disablePadding>
              <ListItemButton
                disabled={panier.validated !== 0}
                role={undefined}
                dense
                sx={{ display: 'flex', flexDirection: { md: 'row', xs: 'column' } }}
              >
                <Box sx={{ display: 'flex', alignSelf: { xs: 'flex-start', md: 'unset' } }}>
                  <ListItemIcon>
                    <Checkbox
                      onClick={() => handlePanierToggle(panier.name)}
                      edge="start"
                      checked={isChecked}
                      tabIndex={-1}
                      disableRipple
                      inputProps={{ 'aria-labelledby': labelId }}
                    />
                  </ListItemIcon>
                  <ListItemText
                    id={labelId}
                    onClick={() => handlePanierToggle(panier.name)}
                    primary={(
                      <>
                        <Typography
                          sx={{
                            color: '#4D4D4D',
                            fontWeight: 600,
                            fontSize: { xs: '12px', md: '14px' },
                            display: 'inline-block',
                          }}
                        >
                          {panier.name}
                        </Typography>
                        <Typography
                          sx={{
                            color: '#979797',
                            fontSize: { xs: '11px', md: '13px' },
                            display: 'inline-block',
                          }}
                        >
                          {` (${panier.products?.length || 0}produits)`}
                        </Typography>
                      </>
                    )}
                  />
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    height: { xs: '33px', md: '44px' },
                    width: { xs: '95px !important', md: '147px !important' },
                    border: '1px solid #CCCCCC',
                    backgroundColor: '#FFFFFF',
                    alignItems: 'center',
                    position: 'relative',
                  }}
                >
                  <Box
                    sx={{
                      color: '#007BC6',
                      fontSize: '16px',
                      fontWeight: 'bold',
                      height: '100%',
                      textAlign: 'center',
                      width: { xs: '30px !important', md: '41.5px !important' },
                      borderRight: '1px solid #CCCCCC',
                      alignItems: 'center',
                      display: 'flex',
                      flexShrink: 0,
                      cursor: 'pointer',
                    }}
                    onClick={
                      (quantity[index] || gameQuantity) > gameQuantity
                      && changeQuantity(index, -gameQuantity)
                    }
                  >
                    <span style={{ width: '100%' }}>-</span>
                  </Box>
                  <TextField
                    disabled={!isChecked && gameQuantity !== 1}
                    id="outlined-basic"
                    label=""
                    variant="standard" // <== changed this
                    sx={{
                      height: '100%',
                      width: '100%',
                    }}
                    autoComplete="off"
                    size="small"
                    type="tel"
                    value={quantity[index] || gameQuantity}
                    name="quantity"
                    onChange={changeQuantity(index)}
                    inputProps={{ sx: { height: '100%', border: 'none', textAlign: 'center' } }}
                    // eslint-disable-next-line react/jsx-no-duplicate-props
                    InputProps={{ sx: { height: '100%' }, disableUnderline: true }}
                  />
                  <Box
                    sx={{
                      color: '#007BC6',
                      fontSize: '16px',
                      fontWeight: 'bold',
                      height: '100%',
                      textAlign: 'center',
                      width: { xs: '30px !important', md: '41.5px !important' },
                      borderLeft: '1px solid #CCCCCC',
                      alignItems: 'center',
                      display: 'flex',
                      flexShrink: 0,
                      cursor: 'pointer',
                    }}
                    onClick={changeQuantity(index, gameQuantity)}
                  >
                    <span style={{ width: '100%' }}>+</span>
                  </Box>
                  <RenderOnDemandThenHide visible={!isChecked}>
                    <Box
                      sx={{
                        position: 'absolute',
                        backgroundColor: 'rgba(50,50,50, 0.4)',
                        height: { xs: '33px', md: '44px' },
                        width: { xs: '95px !important', md: '147px !important' },
                      }}
                    />
                  </RenderOnDemandThenHide>
                </Box>
              </ListItemButton>
            </ListItem>
            <Divider />
          </>
        );
      })}
  </List>
);

const AddProductToPanier = () => {
  const {
    dispatch,
    state: { paniers, panierListState, selectedProductTobeAdded },
  } = useContext(ConfigContext);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
  const [selectedPanier, setSelectedPanier] = useState(null);
  const [carts, setCarts] = useState([]);
  const [quantity, setQuantity] = useState({});
  const { callApi } = useNetworkLoader();
  useEffect(() => {
    if (paniers?.length > 0) {
      setCarts(paniers.filter((cart) => cart.validated === 0));
    }
  }, [paniers]);
  const onClose = () => {
    dispatch(setOpenPanierList(false));
    setQuantity({});
  };
  const gameQuantity = React.useMemo(
    () => (selectedProductTobeAdded?.gameQuantity === null
      || selectedProductTobeAdded?.gameQuantity === 0
      ? 1
      : selectedProductTobeAdded?.gameQuantity),
    [selectedProductTobeAdded],
  );
  const onHandleAdd = () => {
    let index = 0;
    const currPanier = carts.find((p, i) => {
      if (p.name === selectedPanier) {
        index = i;
        return true;
      }
      return false;
    });
    if (currPanier?.products?.length === 40) {
      toast.warn(
        'Vous ne peuvez pas ajouter plus de 40 produits sur la même commande , Merci de créer un autre panier pour continuer votre commande.',
      );
      return;
    }
    const isExist = currPanier?.products?.find(
      (product) => product.id === selectedProductTobeAdded.id,
    ) || { quantity: 0 };
    if (selectedProductTobeAdded.stock < isExist.quantity + (quantity[index] || gameQuantity)) {
      toast.warn(
        'La quantité saisie est supérieure à la quantité disponible , Merci de saisir une valeur inférieure',
      );
      return;
    }
    if (
      !currPanier?.products?.length
      || currPanier?.products[0].huile === selectedProductTobeAdded?.huile
    ) {
      callApi(
        () => addProductCart(
          currPanier.id,
          selectedProductTobeAdded.reference,
          isExist.quantity + (quantity[index] || gameQuantity),
        ),
        (resp) => {
          toast.success('Produit ajouté au panier avec succés');
          dispatch(addProductToPanier(selectedPanier, quantity[index] || gameQuantity));
          setSelectedPanier(null);
          if (selectedProductTobeAdded.linkedProduct) {
            const linkedProduct = resp.products.find(
              (p) => p.id === selectedProductTobeAdded.linkedProduct,
            );
            // dispatch(addProductToPanier(selectedPanier, quantity[index] || gameQuantity));
            dispatch(setPanierProducts(selectedPanier, resp.products));
            toast.info(
              `Le produit ${selectedProductTobeAdded.name} est lié à ${linkedProduct.name} donc ils seront ajouté à la fois sur votre panier, merci pour votre attention.`,
            );
          }
          onClose();
        },
      );
    } else {
      toast.warn('Votre panier doit contenir des produits de meme type (Huile ou Pièce moteur)');
    }
  };
  const addNewPanier = () => {
    dispatch(setPanierOpenState(true));
  };
  const handlePanierToggle = (name) => {
    setSelectedPanier(name === selectedPanier ? null : name);
  };
  const changeQuantity = (index, q) => (event) => {
    event.stopPropagation();
    if (q) {
      if ((quantity[index] || gameQuantity) + q >= gameQuantity) {
        // by game quantity
        setQuantity({ ...quantity, [index]: (quantity[index] || gameQuantity) + q });
      }
    } else {
      setQuantity({ ...quantity, [index]: parseInt(event.target.value || 0, 10) });
    }
  };
  return (
    <Modal open={panierListState} setOpen={onClose} isLarge disableRootStyle={isSmallScreen}>
      <Box sx={{ textAlign: 'center' }}>
        <Bag
          style={{
            width: isSmallScreen ? '80px' : '120px',
            height: isSmallScreen ? '80px' : '120px',
          }}
        />
        <Typography
          sx={{
            mt: { xs: '20px', md: '42px' },
            mb: { xs: '20px', md: '42px' },
            color: '#4D4D4D',
            fontSize: { xs: '16px', md: '18px' },
          }}
        >
          {`Vous avez ${carts?.length} Paniers.
          Sur quel panier voulez-vous placer ce produit?`}
        </Typography>
        <PaniersList
          paniers={carts}
          selectedPanier={selectedPanier}
          handlePanierToggle={handlePanierToggle}
          quantity={quantity}
          changeQuantity={changeQuantity}
          gameQuantity={gameQuantity}
        />
        <Typography
          onClick={addNewPanier}
          sx={{
            mt: '31px',
            mb: '22px',
            color: '#007BC6',
            fontSize: { xs: '12px', md: '14px' },
            fontWeight: 600,
            textAlign: 'center',
            cursor: 'pointer',
          }}
        >
          Ou Créer un nouveau panier
        </Typography>
        <Button
          onClick={onClose}
          variant="contained"
          color="inherit"
          sx={{
            border: '2px solid #CCCCCC',
            color: '#CCCCCC',
            backgroundColor: '#FFFFFF',
            textTransform: 'capitalize',
          }}
        >
          Annuler
        </Button>
        <Button
          disabled={selectedPanier === null || !quantity}
          onClick={onHandleAdd}
          variant="contained"
          color="inherit"
          sx={{
            border: '2px solid #007BC6',
            ml: '21px',
            color: '#007BC6',
            backgroundColor: '#FFFFFF',
            textTransform: 'capitalize',
          }}
        >
          Confirmer
        </Button>
      </Box>
    </Modal>
  );
};

export default AddProductToPanier;
