import {
  Button,
  CardMedia, FormControl, FormControlLabel, FormLabel, Grid, Radio, RadioGroup, Typography,
} from '@mui/material';
import { Box } from '@mui/system';
import React, { useEffect, useMemo, useState } from 'react';
import Input from 'Shared/Input';
import Trash from 'Images/trash.svg';
import AddCircle from 'Images/add-circle.svg';
import { toast } from 'react-toastify';
import useNetworkLoader from 'hooks/useNetworkLoader';
import { ClientContactPhoneTypeList } from '../constants';
import { updateProfile } from '../UsersService';

const ContactEdit = ({
  user, contact, onClose, contactIndex, onUpdateProfile,
}) => {
  const [values, setValues] = useState({ phones: [{}] });
  const { callApi } = useNetworkLoader();

  const handleChange = (indexContact = null) => (event) => {
    const { name, value } = event.target;
    if (indexContact === null) {
      setValues((prev) => ({ ...prev, [name]: value }));
    } else {
      let newValue = value;
      if (name === 'phone') {
        newValue = value.replace(/[^0-9]/g, '');
      }
      const clonedPhones = [...values.phones];
      clonedPhones[indexContact] = { ...clonedPhones[indexContact], [name]: newValue };
      setValues((prev) => ({ ...prev, phones: clonedPhones }));
    }
  };

  const handleUpdateProfile = async () => {
    const clonedContacts = [...user.contacts];
    clonedContacts[contactIndex] = { ...values, primary: contact.primary || false };
    callApi(() => updateProfile({ ...user, contacts: clonedContacts }), () => {
      toast.success('Votre Action a été bien effectuée');
      onClose();
      onUpdateProfile();
    });
  };
  const valid = useMemo(() => values.address
    && values.fullName
    && values.phones[0]?.phone && values.phones[0]?.type,
  [values]);
  useEffect(() => {
    if (contact) {
      setValues({ ...contact, phones: contact?.phones || [{}] });
    }
  }, [contact, setValues]);
  const addPhoneNumberLine = () => {
    setValues((prev) => ({ ...prev, phones: [...prev.phones, {}] }));
  };
  const deletePhoneNumberLine = (index) => () => {
    const clonedPhones = [...values.phones];
    clonedPhones.splice(index, 1);
    setValues((prev) => ({ ...prev, phones: clonedPhones }));
  };
  return (
    <Box>
      <Typography sx={{
        color: '#4D4D4D',
        fontSize: '18px',
        fontWeight: 600,
        textAlign: 'center',
      }}
      >
        {!contact ? 'Ajout d\'un contact' : "modification d'un contact"}
      </Typography>
      <Input
        margin="dense"
        label="Nom et prénom:"
        name="fullName"
        sx={{ mb: '25px' }}
        value={values.fullName}
        onChange={handleChange()}
      />
      <Input
        margin="dense"
        label="Adresse:"
        name="address"
        sx={{ mb: '25px' }}
        value={values.address}
        onChange={handleChange()}
      />
      {values.phones?.map((p, i) => (
        <Grid
          container
          alignItems="center"
          sx={{ mb: '25px', position: 'relative' }}
        >
          {values.phones?.length > 1 && (
          <CardMedia
            src={Trash}
            component="img"
            sx={{
              width: '24px', height: '24px', position: 'absolute', left: '-27px', top: '50%', cursor: 'pointer',
            }}
            onClick={deletePhoneNumberLine(i)}
          />
          )}
          <Grid item xs={12} ms={12} md={4}>
            <Input
              margin="dense"
              label="Numéro du téléphone:"
              name="phone"
              value={p.phone}
              onChange={handleChange(i)}
              type="tel"
            />
          </Grid>
          <Grid item xs={12} ms={12} md={8} sx={{ pl: '15px' }}>
            <FormControl>
              <FormLabel id="demo-row-radio-buttons-group-label">Whatsapp</FormLabel>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="type"
                value={p.type}
                onChange={handleChange(i)}
              >
                {ClientContactPhoneTypeList.map((e) => (
                  <FormControlLabel
                    value={e.value}
                    control={<Radio />}
                    label={e.label}
                    checked={p.type === e.value}
                  />
                ))}
              </RadioGroup>
            </FormControl>
          </Grid>
        </Grid>
      ))}
      <Box
        sx={{
          width: { xs: '100% !important', ms: '100% !important', md: '557px !important' },
          height: '64px',
          margin: 'auto',
          cursor: 'pointer',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          border: '2px dashed #007BC6',
          borderRadius: '5px',
        }}
        onClick={addPhoneNumberLine}
      >
        <CardMedia
          src={AddCircle}
          component="img"
          sx={{
            width: '24px', height: '24px', mr: '10px',
          }}
        />
        <Typography sx={{
          color: '#007BC6',
          fontSize: { sx: '12px', ms: '12px', md: '14px' },
          fontWeight: 500,
        }}
        >
          Ajouter un deuxième numéro de téléphone
        </Typography>
      </Box>
      <Box sx={{
        height: '1px', backgroundColor: '#ccc', width: '100%', mb: '20px', mt: '20px',
      }}
      />
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <Button
          onClick={onClose}
          variant="contained"
          color="inherit"
          sx={{
            border: '2px solid #CCCCCC', color: '#CCCCCC', backgroundColor: '#FFFFFF', textTransform: 'capitalize', mr: '14px',
          }}
        >
          Annuler
        </Button>
        <Button
          onClick={handleUpdateProfile}
          variant="contained"
          color="primary"
          sx={{ textTransform: 'capitalize' }}
          disabled={!valid}
        >
          Enregistrer
        </Button>
      </Box>
    </Box>
  );
};

export default ContactEdit;
