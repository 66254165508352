import React from 'react';
import * as PropTypes from 'prop-types';

const RenderOnDemandThenHide = ({ visible, children }) => {
  if (!visible) {
    return <></>;
  }
  return <>{children}</>;
};

RenderOnDemandThenHide.defaultProps = {
  visible: false,
};

RenderOnDemandThenHide.propTypes = {
  visible: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  children: PropTypes.any.isRequired,
};

export default RenderOnDemandThenHide;
