import React, { useState } from 'react';
import ArrowDropDownRoundedIcon from '@mui/icons-material/ArrowDropDownRounded';
import {
  Box,
  Typography,
  IconButton,
  Checkbox,
  FormGroup,
  FormControlLabel,
  Slider,
  Divider,
} from '@mui/material';
import RenderOnDemandThenHide from 'Components/RenderOnDemandThenHide';
import Input from 'Shared/Input';
import useForm from 'hooks/useForm';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';

export const scalePrice = (x) => (x * 10000) / 100;

export const OtherFilters = ({
  title,
  items,
  filters,
  setPrices,
  onChangeFilter,
  isBrand,
  isPrice,
  hideTitle,
  displayCancelIcon,
  isSmall,
  sx = {},
}) => {
  const [value, setValue] = React.useState([0, 100]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box>
      <RenderOnDemandThenHide visible={!hideTitle}>
        <Typography
          sx={{
            color: '#4D4D4D',
            fontSize: 18,
            fontWeight: 600,
            textTransform: 'uppercase',
            mb: 2.6,
          }}
        >
          {title}
        </Typography>
      </RenderOnDemandThenHide>
      {isPrice ? (
        <Box sx={{ width: '100%' }}>
          <Slider
            sx={{ '& .MuiSlider-thumb': { borderRadius: 0, width: 13, height: 15 } }}
            value={value}
            onChange={handleChange}
            valueLabelDisplay="auto"
            onChangeCommitted={() => setPrices([scalePrice(value[0]), scalePrice(value[1])])}
            valueLabelFormat={(x) => `${x}DHs`}
            scale={scalePrice}
          />
        </Box>
      ) : (
        <FormGroup sx={sx}>
          {items.map((item) => {
            const element = isBrand ? { id: item, name: item } : item;
            return (
              <>
                <FormControlLabel
                  key={element.id}
                  control={(
                    <Checkbox
                      // eslint-disable-next-line max-len
                      onChange={() => onChangeFilter(element.id, element.subFamilies, false, isBrand)}
                      checked={filters.includes(element.id)}
                      defaultChecked
                      sx={{ '&.MuiCheckbox-root': isSmall && { padding: 0 } }}
                    />
                  )}
                  sx={{
                    color: '#4D4D4D',
                    textTransform: 'capitalize',
                    fontSize: 10,
                    fontWeight: 600,
                    '.MuiTypography-root': {
                      width: '100%',
                    },
                  }}
                  label={(
                    <Box sx={{ width: '100%', fontSize: isSmall && '12px' }}>
                      {element?.name?.toLowerCase()}
                      {displayCancelIcon && (
                        <CancelRoundedIcon
                          sx={{
                            mt: '3px',
                            fontSize: 12,
                            float: 'right',
                            color: '#007BC6',
                          }}
                        />
                      )}
                    </Box>
                  )}
                />
              </>
            );
          })}
        </FormGroup>
      )}
    </Box>
  );
};

export const BrandsFilter = ({
  brands, filters, onChangeFilter, restAll,
}) => {
  const { values, onChange } = useForm({ brand: '' });
  const restBrands = brands?.filter((brand) => {
    const isSelected = filters?.includes(brand);
    return (
      !isSelected
        && (values.brand === ''
          || brand.toLocaleLowerCase().startsWith(values.brand.toLocaleLowerCase()))
    );
  }) || [];
  // filter selected ones
  return (
    <>
      <Typography
        sx={{
          color: '#4D4D4D',
          fontSize: 18,
          fontWeight: 600,
          textTransform: 'uppercase',
          mb: 2.6,
        }}
      >
        Filtre par marque
      </Typography>
      <Input
        value={values.brand}
        onChange={onChange}
        margin="dense"
        name="brand"
        hint="Recherche marque"
        iconEnd={<ArrowDropDownRoundedIcon />}
        isRegular
      />
      <Box
        sx={{
          height: '232px',
          border: '1px solid #CCCCCC',
          borderRadius: '5px',
          backgroundColor: '#FFFFFF',
          boxShadow: '0 2px 4px 0 rgba(0,0,0,0.17)',
          overflowX: 'auto',
          mt: '-5px',
          borderTopRightRadius: 0,
          borderTopLeftRadius: 0,
          '::-webkit-scrollbar': {
            width: '7px',
          },
          '::-webkit-scrollbar-track': {
            background: '#FFF',
            borderRadius: '10px',
          },
          '::-webkit-scrollbar-thumb': {
            background: '#D8D8D8',
            borderRadius: '10px',
            '& :hover': {
              background: '#888',
            },
          },
          pt: '16px',
          pl: '13px',
        }}
      >
        <>
          <RenderOnDemandThenHide visible={filters?.length > 0}>
            <Typography
              onClick={restAll}
              sx={{
                fontSize: '10px',
                fontWeight: 600,
                color: '#007BC6',
                textAlign: 'right',
                cursor: 'pointer',
              }}
            >
              Retirer tout
            </Typography>
          </RenderOnDemandThenHide>
          <OtherFilters
            items={filters}
            filters={filters}
            onChangeFilter={onChangeFilter}
            isBrand
            hideTitle
            displayCancelIcon
          />
          <Divider sx={{ display: filters?.length === 0 && 'none' }} />
          <OtherFilters
            items={restBrands}
            filters={[]}
            onChangeFilter={onChangeFilter}
            isBrand
            hideTitle
          />
        </>
      </Box>
    </>
  );
};

const Families = ({
  items, filters, onChangeFilter, hideTitle,
}) => {
  const [openedFamilies, setOpenedFamilies] = useState([]);

  const onOpenSubFamilies = (id) => {
    if (openedFamilies.includes(id)) {
      setOpenedFamilies(openedFamilies.filter((item) => item !== id));
    } else {
      setOpenedFamilies([...openedFamilies, id]);
    }
  };
  return (
    <Box>
      <RenderOnDemandThenHide visible={!hideTitle}>
        <Typography
          sx={{
            color: '#4D4D4D',
            fontSize: 18,
            fontWeight: 600,
            textTransform: 'uppercase',
            mb: 2.6,
          }}
        >
          Filtre par catégories
        </Typography>
      </RenderOnDemandThenHide>
      <FormGroup>
        {items
          && items.map((item) => {
            const subIds = item.subFamilies?.map((sub) => sub.id);
            const selectedSubIds = filters.filter((sub) => subIds.includes(sub));
            return (
              <>
                <Box sx={{ display: 'block', position: 'relative' }}>
                  <FormControlLabel
                    control={(
                      <Checkbox
                        onChange={() => onChangeFilter(item.id, item.subFamilies, true)}
                        checked={subIds.length === selectedSubIds.length}
                        // eslint-disable-next-line max-len
                        indeterminate={
                          subIds.length > 0
                          && selectedSubIds.length > 0
                          && subIds.length !== selectedSubIds.length
                        }
                        defaultChecked
                      />
                    )}
                    sx={{
                      color: '#4D4D4D',
                      textTransform: 'capitalize',
                      fontSize: 10,
                      fontWeight: 600,
                    }}
                    label={(
                      <>
                        <span style={{ color: '#4D4D4D' }}>{item?.name?.toLowerCase()}</span>
                        <span style={{ color: '#CCC', fontSize: '10px' }}>
                          {`(${item?.subFamilies.length})`}
                        </span>
                      </>
                    )}
                  />
                  <IconButton
                    sx={{ color: '#292D32', position: 'absolute', right: -4 }}
                    onClick={() => onOpenSubFamilies(item.id)}
                    size="small"
                  >
                    {openedFamilies.includes(item.id) ? '-' : '+'}
                  </IconButton>
                </Box>
                <RenderOnDemandThenHide visible={openedFamilies.includes(item.id)}>
                  {item.subFamilies.map((subItem) => (
                    <FormControlLabel
                      key={subItem.id}
                      control={(
                        <Checkbox
                          onChange={() => onChangeFilter(subItem.id)}
                          checked={filters.includes(subItem.id)}
                          defaultChecked
                        />
                      )}
                      sx={{
                        pl: 4,
                        color: '#8F8F8F',
                        fontSize: 8,
                      }}
                      label={<span style={{ color: '#4D4D4D' }}>{subItem?.name}</span>}
                    />
                  ))}
                </RenderOnDemandThenHide>
              </>
            );
          })}
      </FormGroup>
    </Box>
  );
};

export default Families;
