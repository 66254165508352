import React, { useEffect } from 'react';
import {
  Button, Chip, IconButton, Tooltip, TableBody, Table, TableCell, TableContainer, Avatar, TableRow,
} from '@mui/material';
import TablePagination from '@mui/material/TablePagination';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import EnhancedTableToolbar from './EnhancedTableToolbar';
import EnhancedTableHead from './EnhancedTableHead';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    top: 20,
    width: 1,
    padding: 0,
  },
  position: 'absolute',
  avatar: {
    color: '#FFFFFF',
    backgroundColor: '#88BCDE',
    width: 32,
    height: 32,
    fontSize: 14,
  },
  addIcon: {
    color: 'transparent',
    padding: 0,
  },
  title: {
    fontWeight: 'bold',
    fontSize: 11,
    color: 'rgba(0, 0, 0, 0.5)',
  },
  data: {
    fontSize: 12,
    color: '#000000',
    fontFamily: 'SFProDisplay !important',
  },
  pagination: {
    color: 'rgba(0, 0, 0, 0.5)',
    fontFamily: 'SFProDisplay !important',
  },
  row: {
    height: 48,
  },
  chip: {
    height: 20,
  },
}));
const specialCells = ['avatar', 'status', 'affect', 'count-down'];
function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}
function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

export default function EnhancedTableStatic({
  rows, headCells, count = 0, pagination = true, containerClass, defaultRowsPerPage = 5,
}) {
  const classes = useStyles();
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState(headCells[0]?.id);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(defaultRowsPerPage);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  useEffect(() => {
    setOrder('asc');
    setOrderBy(headCells[0].id);
    setPage(0);
    setRowsPerPage(defaultRowsPerPage);
  }, [rows]);

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, count - page * rowsPerPage);
  return (
    <div className={classes.root}>
      {false && <EnhancedTableToolbar />}
      <TableContainer className={containerClass || ''}>
        <Table
          className={classes.table}
          size="medium"
          stickyHeader
        >
          <EnhancedTableHead
            classes={classes}
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            rowCount={rows.length}
            headCells={headCells}
          />
          <TableBody>
            {
                            stableSort(rows, getComparator(order, orderBy))
                              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                              .map((row, index) => (
                                <TableRow
                                  hover
                                  tabIndex={-1}
                                  key={row.id}
                                  className={classes.row}
                                >
                                  {headCells.map((headCell) => (
                                    <TableCell
                                      align="left"
                                      style={headCell.parentStyle}
                                      // eslint-disable-next-line react/no-array-index-key
                                      key={`${headCell[0]?.id}-${headCell.id}-${index}`}
                                      className={clsx(
                                        classes.data,
                                        !specialCells.includes(headCell.type)
                                        && headCell.related && !row[headCell.related]
                                          ? row[headCell.parentClass] : '',
                                      )}
                                    >
                                      {headCell.type === 'status' && row[headCell.id] != null && (
                                        <Chip
                                          className={clsx(classes.chip, row[headCell.class])}
                                          icon={(
                                            <FiberManualRecordIcon
                                              style={{ width: 10, height: 10, color: 'inherit' }}
                                            />
                                        )}
                                          label={row[headCell.id]}
                                        />
                                      )}
                                      {headCell.type === 'affect' && row[headCell.related] !== headCell.relatedValue && (
                                        <Button variant="contained" color="primary" onClick={row[headCell.id]} size="small">Assigner</Button>
                                      )}
                                      {!specialCells.includes(headCell.type)
                                        && (
                                        <span
                                          className={
                                            headCell.related
                                            && row[headCell.related] === headCell.relatedValue
                                              ? clsx(row[headCell.class], row[headCell.customClass])
                                              : row[headCell.customClass]
                                          }
                                        >
                                          {row[headCell.id]}
                                        </span>
                                        )}
                                      {(headCell.type === 'avatar' && row[headCell.id])
                                                    && (
                                                      <Tooltip title={row[headCell.id]}>

                                                        <Avatar
                                                          className={classes.avatar}
                                                          onClick={row[headCell.action]}
                                                        >
                                                          {row[headCell.id].split(' ')[0][0].toUpperCase() + row[headCell.id].split(' ')[1][0].toUpperCase()}
                                                        </Avatar>
                                                      </Tooltip>
                                                    )}
                                      {(headCell.type === 'avatar' && !row[headCell.id])
                                                    && (
                                                    <IconButton
                                                      onClick={row[headCell.action]}
                                                      className={classes.addIcon}
                                                    >
                                                      <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <circle cx="16" cy="16" r="15.5" stroke="#10277C" strokeDasharray="4 4" />
                                                        <path d="M16 11.3333V20.6667" stroke="#10277C" strokeWidth="1.33333" strokeLinecap="round" strokeLinejoin="round" />
                                                        <path d="M11.3334 16H20.6667" stroke="#10277C" strokeWidth="1.33333" strokeLinecap="round" strokeLinejoin="round" />
                                                      </svg>

                                                    </IconButton>
                                                    )}
                                    </TableCell>

                                  ))}
                                </TableRow>
                              ))
            }
            {emptyRows > 0 && (
            <TableRow style={{ height: 53 * emptyRows }}>
              <TableCell colSpan={6} />
            </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {pagination && (
      <TablePagination
        labelRowsPerPage="Lignes par page"
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={count}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        classes={{ caption: classes.pagination, select: classes.pagination }}
      />
      )}
    </div>
  );
}
