import * as React from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

export default function SelectSmall({
  name, label, value, onChange, options, large, sxRoot, ...rest
}) {
  // const [age, setAge] = React.useState('');

  /* const handleChange = (event) => {
    onChange(event.target.value);
  }; */
  // MuiInputBase-root-MuiOutlinedInput
  return (
    <FormControl
      sx={{
        m: 1,
        minWidth: 120,
        maxWidth: '420px',
        width: large ? '100%' : '220px',
        '& .MuiInputBase-root': { height: '42px', boxShadow: '0 2px 4px 0 rgba(0,0,0,0.17)' },
        ...sxRoot,
      }}
      size="small"
    >
      <InputLabel id="demo-select-small">{label}</InputLabel>
      <Select
        labelId="demo-select-small"
        id="demo-select-small"
        value={value}
        label={label}
        name={name}
        onChange={onChange}
        {...rest}
      >
        {options?.map((option) => (
          <MenuItem value={option.value || option.id}>{option.label || option.name}</MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
