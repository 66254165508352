/* eslint-disable import/prefer-default-export */
export const fields = [
  {
    id: 'oldPassword',
    label: 'Ancien mot de passe',
    defaultValue: '',
  },
  {
    id: 'newPassword',
    label: 'Nouveau mot de passe',
    defaultValue: '',
  },
  {
    id: 'confirmPassword',
    label: 'Confirmer le nouveau mot de passe',
    defaultValue: '',
  },
];
export const DEFAULT_VALUES = fields.reduce(
  (prev, curr) => ({ ...prev, [curr.id]: curr.defaultValue }),
  {},
);
