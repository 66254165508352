import React, { useContext } from 'react';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';

import { useHistory } from 'react-router-dom';
import UserContext from 'Shared/UserContext';
import { resetLogin } from 'Shared/UserContext/Actions';
import { Divider, Menu, MenuItem } from '@mui/material';
import { WithRolesComponent } from 'HOC/withRoles';
import { Logout } from '@mui/icons-material';
import { authUrl } from 'Utils/authConfig';

export const logoutAndBackToLogin = (dispatch, history, role) => () => {
  localStorage.clear();
  if (role === 'ROLE_ADMIN') {
    window.location.href = authUrl();
  } else {
    dispatch(resetLogin());
    history.push('/login');
  }
};

export const SettingsItems = ({ setOpenModalPwd, setOpenModal, handleClose }) => {
  const {
    dispatch,
    state: { user },
  } = useContext(UserContext);

  const history = useHistory();
  const logout = logoutAndBackToLogin(dispatch, history, user.role);

  const handleEditProfile = () => {
    setOpenModal(true);
    handleClose();
  };

  const handleChangePassword = () => {
    setOpenModalPwd(true);
    handleClose();
  };
  return (
    <>
      <WithRolesComponent allowedRoles={['ROLE_CLIENT']}>
        <MenuItem
          sx={{
            color: '#4D4D4D',
            fontSize: '12px',
            fontWeight: 'bold',
          }}
        >
          {user?.code}
        </MenuItem>
      </WithRolesComponent>
      <MenuItem sx={{ color: '#4D4D4D', fontSize: 12, fontWeight: 500 }}>
        {user?.role === 'ROLE_ADMIN' ? 'Administrateur' : user?.name}
      </MenuItem>
      <WithRolesComponent allowedRoles={['ROLE_CLIENT']}>
        <MenuItem sx={{ color: '#4D4D4D', fontSize: 12, fontWeight: 500 }}>
          {user?.address}
        </MenuItem>
      </WithRolesComponent>
      <Divider />
      <WithRolesComponent allowedRoles={['ROLE_CLIENT']}>
        <MenuItem
          sx={{ color: '#4D4D4D', fontSize: 12, fontWeight: 600 }}
          onClick={handleEditProfile}
        >
          Modifier mes informations
        </MenuItem>
      </WithRolesComponent>
      <MenuItem
        sx={{ color: '#4D4D4D', fontSize: 12, fontWeight: 600 }}
        onClick={handleChangePassword}
      >
        Changer mon mot de passe
      </MenuItem>
      <Divider />
      <MenuItem sx={{ color: '#4D4D4D' }} onClick={logout}>
        <ListItemIcon>
          <Logout fontSize="small" sx={{ color: '#4D4D4D' }} />
        </ListItemIcon>
        <ListItemText sx={{ fontSize: 12, fontWeight: 600 }}>Se déconnecter</ListItemText>
      </MenuItem>
    </>
  );
};
const Settings = ({
  anchorEl, setAnchorEl, setOpenModalPwd, setOpenModal,
}) => {
  const openSettings = Boolean(anchorEl?.id === 'settings');

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Menu
      id="menu-appbar"
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      sx={{ '& .MuiMenu-paper': { borderRadius: 0 } }}
      open={openSettings}
      onClose={handleClose}
    >
      <SettingsItems
        setOpenModalPwd={setOpenModalPwd}
        setOpenModal={setOpenModal}
        handleClose={handleClose}
      />
    </Menu>
  );
};

Settings.propTypes = {};

export default Settings;
