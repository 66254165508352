import React from 'react';
import { Box } from '@mui/material';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const tabSx = {
  '&.Mui-selected': {
    backgroundColor: '#FFF',
    color: '#4D4D4D',
    fontSize: '14px',
    fontWeight: 600,
  },
  color: '#8F8F8F',
};

export const STATUS = {
  NON_ASSIGNEE: 'Envoyée',
  EN_ATTENTE_VALIDATION: 'En cours de traitement',
  BL_GENERE: 'Traitée',
};
const CartStatusFilter = ({
  cartStatus,
  setCartStatus,
  totalSend = 0,
  totalInprogress = 0,
  totalDone = 0,
  totalFinished = 0,
}) => {
  const handleChange = (event, newValue) => {
    setCartStatus(newValue);
  };
  return (
    <Box sx={{ borderBottom: 1, borderColor: 'divider', marginBottom: '20px' }}>
      <Tabs
        value={cartStatus}
        onChange={handleChange}
        aria-label="basic tabs example"
        sx={{
          // backgroundColor: '#FFFFFF',
          '& .MuiTabs-indicator': { height: '5px' },
        }}
        TabIndicatorProps={{
          sx: {
            height: '5px',
            backgroundColor: '#007BC6',
          },
        }}
        variant="scrollable"
        scrollButtons
        allowScrollButtonsMobile
      >
        <Tab
          sx={tabSx}
          label={`Envoyées(${totalSend})`}
          value="NON_ASSIGNEE"
          {...a11yProps('NON_ASSIGNEE')}
        />
        <Tab
          sx={tabSx}
          label={`En cours de traitement (${totalInprogress})`}
          value="EN_ATTENTE_VALIDATION"
          {...a11yProps('EN_ATTENTE_VALIDATION')}
        />
        <Tab
          sx={tabSx}
          label={`Traitées (${totalDone})`}
          value="BL_GENERE"
          {...a11yProps('BL_GENERE')}
        />
        <Tab
          sx={tabSx}
          label={`Non Livrés (${totalFinished})`}
          value="BL_GENERE_TERMINE"
          {...a11yProps('BL_GENERE_TERMINE')}
        />
      </Tabs>
    </Box>
  );
};

export default CartStatusFilter;
