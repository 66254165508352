import { IconButton, Tooltip } from '@mui/material';
import { Box } from '@mui/system';
import React, { useCallback, useEffect, useState } from 'react';
import Input from 'Shared/Input';
import { SearchResult } from 'Shared/Layout/SubHeader';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import useDebounce from 'hooks/useDebounce';
import { getProductsData } from 'Components/Catalog/CatalogServices';
import ReactPiwik from 'react-piwik';
import { toast } from 'react-toastify';
import { defaultMessage } from 'Services';

const SearchHeader = ({ onCloseSearch }) => {
  const [products, setProducts] = useState([]);
  const [search, setSearch] = useState('');
  const [page, setPage] = useState(0);
  const [total, setTotal] = useState(20);
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const handleChange = (event) => {
    setPage(0);
    setSearch(event.target.value);
    if (!open) {
      setOpen(true);
    }
  };

  const onHandleClose = () => {
    onCloseSearch(false);
    setOpen(false);
    setProducts([]);
    setPage(0);
  };

  const debounceValue = useDebounce(search, 600);

  const searchDataProduct = useCallback(async () => {
    if (open) {
      try {
        const pos = await getProductsData(`page=${page}`, { search });
        if (pos.empty) {
          ReactPiwik.push(['trackEvent', 'Référence', 'Indisponible', search]);
        }
        setTotal(pos?.totalElements);
        setProducts((prev) => (pos.first ? pos?.content : [...prev, ...pos?.content]));
      } catch (e) {
        const { response: { data: { statusLabel = defaultMessage } = {} } = {} } = e;
        toast.error(statusLabel);
      }
    }
  }, [setProducts, setTotal, open, debounceValue, page]);
  useEffect(() => {
    searchDataProduct();
  }, [searchDataProduct]);
  return (
    <>
      <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
        <Tooltip title="close">
          <IconButton
            size="large"
            id="close-search"
            onClick={onHandleClose}
            sx={{ color: 'gray', alignSelf: 'flex-end' }}
          >
            <CancelOutlinedIcon />
          </IconButton>
        </Tooltip>
        <Input
          placeholder="Rechercher par réference, nom, marque…"
          margin="dense"
          label="Recherche:"
          name="title"
          ref={anchorRef}
          onChange={handleChange}
          onKeyDown={(e) => { if (e.key === 'Enter') { e.preventDefault(); } }}
        />
      </Box>
      <SearchResult
        products={products}
        total={total}
        open={open}
        setOpen={setOpen}
        anchorRef={anchorRef}
        setPage={setPage}
        isHeader
      />
    </>
  );
};

export default SearchHeader;
