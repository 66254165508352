/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import {
  Box, Typography, CardMedia, Button,
} from '@mui/material';
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';
import RenderOnDemandThenHide from 'Components/RenderOnDemandThenHide';

import htmlToDraft from 'html-to-draftjs';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, ContentState } from 'draft-js';

const downloadFile = (file) => {
  window.open(file);
};
const testDuty = (announcement) => {
  console.log(announcement);
  return announcement?.title;
};

const initialHtml = (html) => {
  const contentBlock = htmlToDraft(html);
  if (contentBlock) {
    const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
    return EditorState.createWithContent(contentState);
  }

  return EditorState.createEmpty();
};
const Event = ({ announcement }) => (
  <Box
    sx={{
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      maxHeight: '800px',
      padding: '15px 30px',
      boxSizing: 'border-box',
    }}
  >
    <Typography
      sx={{
        color: '#4D4D4D',
        fontSize: '24px',
        fontWeight: 'bold',
        mb: '27px',
      }}
    >
      {testDuty(announcement)}
    </Typography>
    <Typography
      sx={{
        color: '#F79F1F',
        fontSize: '18px',
        fontWeight: 'bold',
        mb: '43px',
      }}
    >
      du
      {' '}
      {format(new Date(announcement?.startDate?.replace('+0000', '+0100')), 'dd/MM/yyyy', {
        locale: fr,
      })}
      {' au '}
      {format(new Date(announcement?.endDate?.replace('+0000', '+0100')), 'dd/MM/yyyy', {
        locale: fr,
      })}
    </Typography>
    <Editor
      editorState={initialHtml(announcement?.descriptionEvent)}
      toolbarClassName="toolbarClassName"
      wrapperClassName="wrapperClassName"
      toolbarStyle={{ display: 'none' }}
      toolbar={{
        link: {
          defaultTargetOption: '_blank',
          showOpenOptionOnHover: false,
        },
      }}
      editorClassName="editorClassName"
      onEditorStateChange={() => null}
      readOnly
      sx={{
        a: {
          cursor: 'pointer',
        },
      }}
    />
    {announcement?.imageEventUrl && (
      <CardMedia
        component="img"
        src={announcement?.imageEventUrl}
        sx={{ height: 'auto', width: '338px', mb: '40px' }}
      />
    )}
    <RenderOnDemandThenHide visible={announcement?.fileEventUrl}>
      <Button
        sx={{
          color: '#007BC6',
          fontSize: '14px',
          fontStyle: 'italic',
          alignSelf: 'self-start',
        }}
        variant="text"
        onClick={() => downloadFile(announcement?.fileEventUrl)}
      >
        Télécharger la brochure pour le salon
      </Button>
    </RenderOnDemandThenHide>
  </Box>
);

export default Event;
