import React from 'react';
import { Typography } from '@mui/material';

const Deposit = ({
  bouskoura, derbOmar, sx = {}, isSmall = false,
}) => (
  <Typography sx={{
    color: '#4D4D4D', fontSize: isSmall ? '11px' : '14px', fontWeight: 600, ...sx,
  }}
  >
    {bouskoura ? 'Bouskoura - ' : ''}
    {derbOmar ? 'Derb Omar' : ''}
  </Typography>
);

export default Deposit;
