import React, { useEffect } from 'react';
import { ACCESS_TOKEN_STORAGE, ACCESS_USER_STORAGE, authUrl } from 'Utils/authConfig';
import { JWE_KEY } from 'Utils/crypt';

const PreLogin = ({ children }) => {
  const receiveMessage = (e) => {
    if (e.origin === authUrl()) {
      localStorage.setItem(ACCESS_TOKEN_STORAGE, e.data.token);
      localStorage.setItem(JWE_KEY, e.data.key);
      localStorage.setItem(
        ACCESS_USER_STORAGE,
        JSON.stringify({ ...e.data.user, role: 'ROLE_ADMIN' }),
      );
    }
  };
  useEffect(() => {
    window.addEventListener('message', receiveMessage);
  }, []);
  return <>{children}</>;
};

export default PreLogin;
