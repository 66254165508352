export const USER_LOGIN = 'USER_LOGIN';
export const login = () => ({
  type: USER_LOGIN,
});

export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const loginSucces = (user, token) => ({
  type: LOGIN_SUCCESS,
  user,
  token,
});

export const LOGIN_ERROR = 'LOGIN_ERROR';
export const loginError = (message) => ({
  type: LOGIN_ERROR,
  message,
});

export const RESET_LOGIN = 'RESET_LOGIN';
export const resetLogin = () => ({
  type: RESET_LOGIN,
});

export const FIRST_LOGIN = 'FIRST_LOGIN';
export const firstLogin = (isFirstLogin, token, user) => ({
  type: FIRST_LOGIN,
  isFirstLogin,
  token,
  user,
});

export const SET_NETWORK_ON = 'SET_NETWORK_ON';
export const setNetworkON = () => ({
  type: SET_NETWORK_ON,
});

export const SET_NETWORK_OFF = 'SET_NETWORK_OFF';
export const setNetworkOFF = () => ({
  type: SET_NETWORK_OFF,
});
