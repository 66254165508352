import React, { useContext, useEffect, useState } from 'react';
import {
  Typography, Button, IconButton, useMediaQuery, Box,
} from '@mui/material';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import GlobalLoader from 'Shared/GlobalLoader/GlobalLoader';

import { Visibility, VisibilityOff } from '@mui/icons-material';
import { useTheme } from '@mui/material/styles';
import UserContext from '../../Shared/UserContext';
import { loginUser, changeFirstPassword } from './LoginService';
import { resetLogin } from '../../Shared/UserContext/Actions';
import Input from '../../Shared/Input';
// import { ReactComponent as ConnectIcon } from './icons/connect.svg';
import useForm from '../../hooks/useForm';
import RenderOnDemandThenHide from '../RenderOnDemandThenHide';
import bg from '../../Images/login_bg.png';
import { ReactComponent as Logo } from '../../Images/copima_logo_white.svg';

const InputPassword = (props) => {
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  return (
    <Input
      {...props}
      type={showPassword ? 'text' : 'password'}
      margin="dense"
      iconEnd={(
        <IconButton
          aria-label="toggle password visibility"
          onClick={handleClickShowPassword}
          onMouseDown={handleMouseDownPassword}
          edge="end"
        >
          {showPassword ? <VisibilityOff /> : <Visibility />}
        </IconButton>
      )}
    />
  );
};
export const ChangePasswordForm = ({ values, onChange }) => (
  <Box marginTop="50px" width="100%">
    <InputPassword
      value={values.currentPassword}
      onChange={onChange}
      name="currentPassword"
      label="Mot de passe actuel :"
    />
    <InputPassword
      value={values.newPassword}
      onChange={onChange}
      name="newPassword"
      label="Nouveau Mot de passe :"
    />
    <InputPassword
      value={values.newPassword2}
      onChange={onChange}
      name="newPassword2"
      label="Confirmer Nouveau Mot de passe :"
    />
  </Box>
);

const ChangePassword = () => {
  const history = useHistory();
  const { onChange, values } = useForm({
    currentPassword: '',
    newPassword: '',
    newPassword2: '',
    showPassword: false,
    showPassword2: false,
    showPassword3: false,
  });
  const {
    dispatch,
    state: { firstLogin },
  } = useContext(UserContext);
  const update = async () => {
    await changeFirstPassword(
      dispatch,
      {
        oldPassword: values?.currentPassword,
        newPassword: values.newPassword,
      },
      history,
      firstLogin.tmpUser,
      firstLogin.tmpToken,
    );
  };

  const onSubmit = (e) => {
    e.preventDefault();
    update();
  };

  const isValide = values.currentPassword
    && values.newPassword !== ''
    && values.newPassword === values.newPassword2;
  return (
    <>
      <ChangePasswordForm values={values} onChange={onChange} />
      <div
        style={{
          width: '100%',
          borderTop: '2px solid #CCCCCC',
          margin: '20px 0px',
          marginBottom: 35,
        }}
      />

      <Button
        disabled={!isValide}
        variant="contained"
        color="primary"
        fullWidth
        size="large"
        type="submit"
        onClick={onSubmit}
      >
        Sauvegarder
      </Button>
    </>
  );
};

const Login = () => {
  const history = useHistory();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
  const {
    dispatch,
    state: { status, errorMessage, firstLogin },
  } = useContext(UserContext);
  // const [hasError, setHasError] = useState(false);

  useEffect(() => {
    if (status === 'error') {
      toast.error(errorMessage);
    }
  }, [status, errorMessage, dispatch]);
  const hasError = status === 'error';

  const [values, setValues] = React.useState({
    username: '',
    password: '',
    showPassword: false,
  });
  const login = async () => {
    await loginUser(dispatch, values, history);
  };

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
    if (hasError) {
      dispatch(resetLogin());
    }
  };
  const onSubmit = (e) => {
    e.preventDefault();
    login();
  };
  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  return (
    <>
      {status === 'loading' && <GlobalLoader size="100vh" />}
      <Box
        style={{
          height: '100vh',
          display: 'flex',
          justifyContent: 'center',
          backgroundImage: `url(${bg})`,
          backgroundSize: 'cover',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            margin: { xs: '0 12px', sm: '0 30px' },
            width: { xs: '100%', sm: '60%' },
            maxWidth: '700px',
          }}
        >
          <Logo style={{ alignSelf: 'center', width: isSmallScreen && '240px' }} />
          <form noValidate autoComplete="off" onSubmit={onSubmit}>
            <Box
              // marginBottom="190px"
              sx={{
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column',
                backgroundColor: '#F7F7F7',
                border: '1px solid #979797',
                borderRadius: '5px',
                marginTop: '20px',
                paddingTop: '30px',
                paddingBottom: '30px',
                paddingLeft: { xs: '14px', sm: '141px' },
                paddingRight: { xs: '14px', sm: '141px' },
              }}
            >
              <Typography style={{ color: '#4D4D4D', fontSize: 21, fontWeight: 600 }}>
                CONNEXION
              </Typography>
              <Typography
                sx={{
                  color: hasError ? '#EA2027' : '#8F8F8F',
                  fontSize: '14px',
                  textAlign: { xs: 'center' },
                }}
              >
                <RenderOnDemandThenHide visible={!hasError}>
                  Utiliser les identifants fournis par COPIMA pour accèder
                </RenderOnDemandThenHide>
                <RenderOnDemandThenHide visible={hasError}>
                  Mot de passe erronée. En cas d’oublie, contactez-nous
                </RenderOnDemandThenHide>
              </Typography>

              <RenderOnDemandThenHide visible={!firstLogin?.isFirstLogin}>
                <Box marginTop="30px" width="100%">
                  <Input
                    label="Identifiant :"
                    fullWidth
                    autoFocus
                    style={{ marginBottom: 30 }}
                    hasError={hasError}
                    margin="dense"
                    value={values.username}
                    onChange={handleChange('username')}
                  />
                  <Input
                    label="Mot de passe :"
                    type={values.showPassword ? 'text' : 'password'}
                    fullWidth
                    margin="dense"
                    hasError={hasError}
                    value={values.password}
                    onChange={handleChange('password')}
                    iconEnd={(
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {values.showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    )}
                  />
                </Box>
                <div
                  style={{
                    width: '100%',
                    borderTop: '2px solid #CCCCCC',
                    margin: '20px 0px',
                    marginBottom: 35,
                  }}
                />
                <Button
                  disabled={!values.password || !values.username}
                  variant="contained"
                  color="primary"
                  fullWidth
                  size="large"
                  type="submit"
                >
                  Se connecter
                </Button>
              </RenderOnDemandThenHide>

              <RenderOnDemandThenHide visible={firstLogin?.isFirstLogin}>
                <ChangePassword dispatch={dispatch} />
              </RenderOnDemandThenHide>
            </Box>
          </form>
        </Box>
      </Box>
    </>
  );
};

Login.propTypes = {};

export default Login;
