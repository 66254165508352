import { getResponseData } from 'config/apiConfig';
import { useCallback, useContext } from 'react';
import { toast } from 'react-toastify';
import UserContext from 'Shared/UserContext';
import { setNetworkOFF, setNetworkON } from 'Shared/UserContext/Actions';
import { getJwkKeystore } from 'Utils/crypt';

export default function useNetworkLoader() {
  const {
    dispatch,
  } = useContext(UserContext);

  const on = useCallback(() => dispatch(setNetworkON()), []);
  const off = useCallback(() => dispatch(setNetworkOFF()), []);
  const callApi = useCallback(async (api, callBack, loader = true) => {
    try {
      if (loader) {
        on();
      }
      // todo use SWR here get data and errors
      const data = await api();
      callBack(data);
    } catch (e) {
      getJwkKeystore()
        .then((key) => getResponseData(e.response, key))
        .then((result) => {
          console.log(e);
          toast.error(result?.statusLabel || 'Erreur Système');
        });
    } finally {
      if (loader) {
        off();
      }
    }
  }, []);
  return {
    on,
    off,
    callApi,
  };
}
