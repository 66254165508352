import { Button, Paper, Typography } from '@mui/material';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import React from 'react';
import { Box } from '@mui/system';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import { useHistory } from 'react-router-dom';

const EdgeCard = () => {
  const history = useHistory();
  const labels = [
    'Consulter notre catalogue',
    'Passer vos commandes en toute simplicité',
    'Consulter nos articles/familles en promotion',
    'Consulter les nouveautés',
  ];
  return (
    <Paper
      sx={{
        width: '100%',
        height: '273px',
        p: '23px 15px 0 15px',
        boxSizing: 'border-box',
      }}
    >
      <Typography sx={{
        color: '#4D4D4D',
        fontSize: '18px',
        fontWeight: 'bold',
      }}
      >
        Bienvenue sur la nouvelle plateforme en ligne de Copima
      </Typography>
      {labels.map((label, index) => (
        <Box sx={{ display: 'flex', alignItems: 'center', mt: !index ? '22px' : 0 }}>
          <KeyboardArrowRightIcon sx={{
            mr: '10px',
            backgroundColor: '#8F8F8F',
            color: '#FFF',
            height: '17.5px',
            width: '17.5px',
            borderRadius: '4px',
          }}
          />
          <Typography
            display="inline"
            sx={{
              color: '#4D4D4D',
              fontSize: '14px',
            }}
          >
            {label}
          </Typography>
        </Box>
      ))}
      <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '25px' }}>
        <Button
          onClick={() => history.push('/media')}
          sx={{
            border: '1px solid #DF8502',
            borderRdius: '5px',
            color: '#F79F1F',
            fontSize: '14px',
            fontWeight: 600,
            '&:hover': {
              border: '1px solid #DF8502',
            },
          }}
          variant="outlined"
        >
          En savoir plus
        </Button>
      </Box>
    </Paper>
  );
};

export default EdgeCard;
