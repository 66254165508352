import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';
import {
  // eslint-disable-next-line max-len
  Box,
  Button,
  List,
  ListItem,
  Divider,
  ListItemText,
  IconButton,
  Radio,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  RadioGroup,
  Typography,
} from '@mui/material';
import Input from 'Shared/Input';
import RenderOnDemandThenHide from 'Components/RenderOnDemandThenHide';
import Families, { OtherFilters } from 'Components/Catalog/Families';
import { getBrands, getProductsData } from 'Components/Catalog/CatalogServices';
import useNetworkLoader from 'hooks/useNetworkLoader';
import EnhancedTable from 'Shared/EnhancedTable';
import Select from 'Shared/Select';
import SearchIcon from '@mui/icons-material/Search';
import useDebounce from 'hooks/useDebounce';
import usePrevious from 'hooks/usePrevious';
import { ANNOUCE_TYPE, columnsProduct } from './constants';

const SelectedItems = ({ categories, selected, removeOne }) => (
  <Box
    sx={{
      border: '1px solid #CCCCCC',
      borderRadius: '5px',
      boxShadow: '0 2px 4px 0 rgba(0,0,0,0.17)',
      backgroundColor: '#FFF',
      height: '252px',
      overflowY: 'auto',
      '::-webkit-scrollbar': {
        width: '7px',
      },
      '::-webkit-scrollbar-track': {
        background: '#FFF',
        borderRadius: '10px',
      },
      '::-webkit-scrollbar-thumb': {
        background: '#D8D8D8',
        borderRadius: '10px',
        '& :hover': {
          background: '#888',
        },
      },
    }}
  >
    {selected?.length > 0 ? (
      <List dense>
        {selected.map((item) => {
          const categoryAndSub = categories.find((element) => element.id === item.family);
          return item.brands.map((brand) => (
            <>
              <ListItem
                secondaryAction={(
                  <IconButton
                    onClick={() => removeOne(item.family, brand)}
                    edge="end"
                    aria-label="delete"
                  >
                    <Typography sx={{ color: '#007BC6', fontWeight: 600 }}>Retirer</Typography>
                  </IconButton>
                )}
              >
                <ListItemText
                  primary={`${categoryAndSub?.parent} > ${categoryAndSub?.name} (${brand})`}
                />
              </ListItem>
              <Divider />
            </>
          ));
        })}
      </List>
    ) : (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          height: '100%',
        }}
      >
        <Typography
          sx={{
            color: '#CCCCCC',
            fontSize: 16,
            fontWeight: 600,
            textAlign: 'center',
            mb: 2,
          }}
        >
          Liste des catégories/Marque
        </Typography>
        <Typography
          sx={{
            color: '#4D4D4D',
            fontSize: 14,
            textAlign: 'center',
          }}
        >
          La liste des catégories avec la ou les marques pour lesquels la promotion sera appiliquée
          seront affichées ici
        </Typography>
      </Box>
    )}
  </Box>
);

const Options = ({ children, disabled }) => {
  const ref = useRef();
  return (
    <Box sx={{ position: 'relative' }}>
      <RenderOnDemandThenHide visible={disabled}>
        <Box
          sx={{
            position: 'absolute',
            width: '100%',
            height: '100%',
            background: 'rgba(50,50,50,0.3)',
          }}
        />
      </RenderOnDemandThenHide>
      <Box
        ref={ref}
        sx={{
          p: '20px 8px 26px',
          height: '252px',
          maxHeight: '252px',
          backgroundColor: '#FFF',
          borderRadius: '5px',
          border: '1px solid #CCCCCC',
          boxShadow: '0 2px 4px 0 rgba(0,0,0,0.17)',
          overflowY: 'auto',
          '::-webkit-scrollbar': {
            width: '7px',
          },
          '::-webkit-scrollbar-track': {
            background: '#FFF',
            borderRadius: '10px',
          },
          '::-webkit-scrollbar-thumb': {
            background: '#D8D8D8',
            borderRadius: '10px',
            '& :hover': {
              background: '#888',
            },
          },
        }}
      >
        {children}
      </Box>
    </Box>
  );
};

// eslint-disable-next-line max-len
const getSubFamilies = (cats) => cats?.flatMap((i) => i.subFamilies.map((k) => ({ ...k, parent: i.name })));

const Category = ({ values, onChange, caterogies }) => {
  const [selectedCategory, setSelectedCategory] = useState([]);
  const [filterBrands, setFilterBrands] = useState([]);
  const [brands, setBrands] = useState(values?.brands || []);
  const [categoryBrands, setCategoryBrands] = useState(values?.linkedFamilies || []);
  const { callApi } = useNetworkLoader();

  const familiesAndSub = getSubFamilies(caterogies);

  useEffect(() => {
    onChange({ target: { name: 'linkedFamilies', value: categoryBrands } });
  }, [categoryBrands]);
  useEffect(async () => {
    let params = '?';
    selectedCategory?.forEach((item) => {
      if (!brands.includes(item)) {
        params += 'family='.concat(item).concat('&');
      }
      return item;
    });
    callApi(
      () => getBrands(params),
      (resp) => {
        const brandsData = resp?.map((item) => item.name) || [];
        setBrands(brandsData);
        if (selectedCategory.length > 0) {
          setFilterBrands(brandsData);
        }
      },
    );
  }, [selectedCategory]);

  const onHandleFiltersBrand = (id) => {
    if (!filterBrands.includes(id)) {
      setFilterBrands([...filterBrands, id]);
    } else {
      setFilterBrands(filterBrands.filter((item) => item !== id));
    }
  };

  const onHandleFilters = (id, subFamilies, isParent, isBrand) => {
    if (isBrand) {
      onHandleFiltersBrand(id);
    } else if (!isParent) {
      if (selectedCategory.length === 0 || !selectedCategory.includes(id)) {
        setSelectedCategory([id]);
      } else {
        setSelectedCategory([]);
      }
      setFilterBrands([]);
    }
  };

  const addSelectedBrands = () => {
    setCategoryBrands([...categoryBrands, { family: selectedCategory[0], brands: filterBrands }]);
    setSelectedCategory([]);
    setFilterBrands([]);
  };

  const onRemoveOne = (family, brand) => {
    const removeBrand = categoryBrands.map((item) => {
      if (item.family === family) {
        return { ...item, brands: item.brands.filter((brd) => brd !== brand) };
      }
      return item;
    });
    setCategoryBrands(removeBrand.filter((item) => item.brands.length !== 0));
  };
  return (
    <>
      <Grid item xs={5}>
        <Typography
          sx={{
            color: '#4D4D4D',
            fontSize: '14px',
            fontWeight: 600,
            mb: '22px',
          }}
        >
          Liste des catégories
        </Typography>
        <Options>
          <Families
            items={caterogies}
            filters={selectedCategory}
            onChangeFilter={onHandleFilters}
            hideTitle
          />
        </Options>
      </Grid>
      <Grid item xs={2} />
      <Grid item xs={5}>
        <Typography
          sx={{
            color: '#4D4D4D',
            fontSize: '14px',
            fontWeight: 600,
            mb: '22px',
          }}
        >
          Liste des marques liées aux catégories
        </Typography>
        <Options disabled={selectedCategory.length === 0}>
          <OtherFilters
            isBrand
            items={brands}
            filters={filterBrands}
            onChangeFilter={onHandleFilters}
            hideTitle
          />
        </Options>
      </Grid>
      <Grid xs={12}>
        <Button
          disabled={selectedCategory.length === 0 || filterBrands.length === 0}
          onClick={addSelectedBrands}
          variant="contained"
          color="inherit"
          sx={{
            mt: '20px',
            float: 'right',
            border: '2px solid #007BC6',
            color: '#007BC6',
            backgroundColor: '#FFF',
            textTransform: 'capitalize',
          }}
        >
          Ajouter
        </Button>
      </Grid>
      <Grid item xs={12}>
        <SelectedItems
          categories={familiesAndSub}
          selected={categoryBrands}
          removeOne={onRemoveOne}
        />
      </Grid>
    </>
  );
};

const Products = ({
  values,
  onChange,
  caterogies,
  savedCheckedProduct = [],
  formFitlers,
  onChangeFilters,
}) => {
  const [touched, setTouched] = useState(false);
  const [products, setProducts] = useState([]);
  const [brands, setBrands] = useState([]);
  const [promoIds, setPromoIds] = useState(values?.promoProducts || []);
  const [infoIds, setInfoIds] = useState(values?.infoProducts || []);
  const [newIds, setNewIds] = useState(values?.newProducts || []);
  const { callApi } = useNetworkLoader();
  /* const { values: formFitlers, onChange: onChangeFilters } = useForm({
    ref: '',
    family: values.families?.length > 0 ? values.families[0] : '',
    brand: values.brands?.length > 0 ? values.brands[0] : '',
  }); */
  const prevFormFitlers = usePrevious(formFitlers);
  const deboucedFilter = useDebounce(formFitlers, 500);
  const familiesAndSub = getSubFamilies(caterogies);
  const setAll = (all) => {
    onChange({ target: { name: 'all', value: all } });
  };
  const getData = useCallback(
    (params) => {
      const body = {
        search: formFitlers.ref || null,
        families: formFitlers.family !== '' ? [formFitlers.family] : null,
        brands: formFitlers.brand !== '' ? [formFitlers.brand] : null,
      };
      onChange({ target: { name: 'filter', value: formFitlers } });
      callApi(
        () => getProductsData(
          `page=${params?.page || 0}&size=${params?.size || 10}&sort=${params?.sort || ''}`,
          body,
        ),
        (resp) => {
          const oldRefs = savedCheckedProduct.map((item) => item.reference);
          const content = resp.content.filter((item) => !oldRefs.includes(item.reference));
          const data = { ...resp, content: [...savedCheckedProduct, ...content] };
          setProducts(data);
        },
      );
    },
    [formFitlers],
  );
  useEffect(() => {
    if (deboucedFilter) {
      getData();
    }
  }, [deboucedFilter]);

  useEffect(() => {
    if (formFitlers.family !== '') {
      callApi(() => getBrands(`?family=${formFitlers.family}`), setBrands);
    }
    if (touched) {
      // dont change select all if search by ref is changed
      if (prevFormFitlers.ref === formFitlers.ref) {
        if (savedCheckedProduct.length === 0) {
          // reset only if not mode edit
          setNewIds([]);
          setPromoIds([]);
          setInfoIds([]);
          setAll({ promo: false, new: false, info: false });
          onChange({ name: 'excludeNewProducts', value: [] });
          onChange({ name: 'promoProducts', value: [] });
        }
      }
    }
  }, [formFitlers, touched]);
  useEffect(() => {
    // onChange({ name: 'newProducts', value: newIds });
    onChange({ name: 'newProducts', value: values.all.new ? [] : newIds });
    onChange({ name: 'excludeNewProducts', value: values.all.new ? newIds : [] });
  }, [newIds]);

  useEffect(() => {
    // onChange({ name: 'promoProducts', value: promoIds });
    onChange({ name: 'promoProducts', value: values.all.promo ? [] : promoIds });
    onChange({ name: 'excludePromoProducts', value: values.all.promo ? promoIds : [] });
  }, [promoIds]);

  useEffect(() => {
    onChange({ name: 'infoProducts', value: values.all.info ? [] : infoIds });
    onChange({ name: 'excludeInfoProducts', value: values.all.info ? infoIds : [] });
  }, [infoIds]);

  const onSelectAllPromo = () => {
    // setAll({ ...all, promo: !all.promo });
    // setPromoIds(!all.promo ? products?.content?.map((item) => item.reference) : []);
    setAll({ ...values.all, promo: !values.all.promo });
    setPromoIds([]);
  };

  const onSelectAllNew = () => {
    // setAll({ ...all, new: !all.new });
    // setNewIds(!all.new ? products?.content?.map((item) => item.reference) : []);
    setAll({ ...values.all, new: !values.all.new });
    setNewIds([]);
  };

  const onSelectAllInfo = () => {
    setAll({ ...values.all, info: !values.all.info });
    setInfoIds([]);
  };

  const onCheckOn = (isNew, isInfo, id) => () => {
    if (isNew) {
      if (newIds.includes(id)) {
        setNewIds(newIds.filter((item) => item !== id));
      } else {
        setNewIds([...newIds, id]);
      }
    } else if (isInfo) {
      if (infoIds.includes(id)) {
        setInfoIds(infoIds.filter((item) => item !== id));
      } else {
        setInfoIds([...infoIds, id]);
      }
    } else if (promoIds.includes(id)) {
      setPromoIds(promoIds.filter((item) => item !== id));
    } else {
      setPromoIds([...promoIds, id]);
    }
  };

  const onChangeFamily = (e) => {
    onChangeFilters(e);
    onChangeFilters({ target: { name: 'brand', value: '' } });
    setTouched(true);
  };

  const isCheckedPromo = (id) => (promoIds.includes(id) ? !values.all.promo : values.all.promo);

  const isCheckedNew = (id) => (newIds.includes(id) ? !values.all.new : values.all.new);
  const isCheckedInfo = (id) => (infoIds.includes(id) ? !values.all.info : values.all.info);

  return (
    <>
      <Grid item xs={12} sx={{ pt: '16px !important' }}>
        <Box sx={{ display: 'inline-block' }}>
          <Input
            value={formFitlers.ref}
            onChange={onChangeFilters}
            margin="dense"
            hint="Recherche réf…."
            name="ref"
            large={false}
            iconEnd={<SearchIcon sx={{ cursor: 'pointer' }} color="primary" />}
          />
        </Box>
        <Box sx={{ display: 'inline-block', ml: '20px !important' }}>
          <Select
            options={familiesAndSub || []}
            value={formFitlers.family}
            label="Famille"
            name="family"
            onChange={onChangeFamily}
          />
        </Box>
        <Box sx={{ display: 'inline-block' }}>
          <Select
            options={brands}
            value={formFitlers.brand}
            label="Marque"
            name="brand"
            onChange={onChangeFilters}
            disabled={brands.length === 0}
          />
        </Box>
      </Grid>
      <Grid item xs={12} sx={{ pt: '16px !important' }}>
        <EnhancedTable
          pagination
          getData={getData}
          update={false}
          rows={
            products?.content?.map((item) => ({
              ...item,
              promo: isCheckedPromo(item.reference),
              new: isCheckedNew(item.reference),
              info: isCheckedInfo(item.reference),
            })) || []
          }
          headCells={columnsProduct(
            onSelectAllPromo,
            onSelectAllNew,
            onSelectAllInfo,
            onCheckOn,
            values.all,
          )}
          count={products?.totalElements}
          rowsPerPageOptions={[10, 15, 24]}
        />
      </Grid>
    </>
  );
};
const ProductCategory = ({
  values,
  onChange,
  onClose,
  onPrev,
  onSubmit,
  caterogies,
  savedCheckedProduct,
  formFitlers,
  onChangeFilters,
}) => (
  <Grid container sx={{ pt: 2.5 }} spacing={4}>
    <Grid item xs={2} />
    <Grid item xs={8}>
      <FormControl>
        <FormLabel
          id="buttons-group-label"
          sx={{
            color: '#8F8F8F',
            fontSize: '14px',
            fontWeight: 600,
            mb: '18px',
          }}
        >
          L’annonce concerne:
        </FormLabel>
        <RadioGroup row aria-labelledby="buttons-group-label" name="productToggle">
          <FormControlLabel
            value={ANNOUCE_TYPE.PRODUCT}
            checked={values?.productToggle === ANNOUCE_TYPE.PRODUCT}
            sx={{
              color: '#4D4D4D',
              fontSize: '14px',
              fontWeight: 500,
            }}
            onChange={onChange}
            control={<Radio />}
            label="Certains produits"
          />
          <FormControlLabel
            value={ANNOUCE_TYPE.CATEGORY_PRODUCT}
            checked={values?.productToggle === ANNOUCE_TYPE.CATEGORY_PRODUCT}
            sx={{
              ml: '172px',
              color: '#4D4D4D',
              fontSize: '14px',
              fontWeight: 500,
            }}
            onChange={onChange}
            control={<Radio />}
            label="Une catégorie de produit"
          />
        </RadioGroup>
      </FormControl>
      <Divider />
    </Grid>
    <Grid item xs={2} />
    <RenderOnDemandThenHide visible={values?.productToggle === ANNOUCE_TYPE.PRODUCT}>
      <Products
        values={values}
        onChange={onChange}
        caterogies={caterogies}
        savedCheckedProduct={savedCheckedProduct}
        formFitlers={formFitlers}
        onChangeFilters={onChangeFilters}
      />
    </RenderOnDemandThenHide>
    <RenderOnDemandThenHide visible={values?.productToggle === ANNOUCE_TYPE.CATEGORY_PRODUCT}>
      <Category values={values} onChange={onChange} caterogies={caterogies} />
    </RenderOnDemandThenHide>
    <Grid item xs={2} />
    <Grid item xs={3}>
      <Button
        fullWidth
        onClick={onClose}
        variant="contained"
        color="inherit"
        sx={{
          border: '1px solid #CCCCCC',
          color: '#CCCCCC',
          backgroundColor: '#FFFFFF',
          textTransform: 'capitalize',
        }}
      >
        Annuler
      </Button>
    </Grid>
    <Grid item xs={1} />
    <Grid item xs={3}>
      <Button
        fullWidth
        onClick={onPrev}
        variant="contained"
        color="inherit"
        sx={{
          border: '1px solid #007BC6',
          color: '#007BC6',
          backgroundColor: '#FFFFFF',
          textTransform: 'capitalize',
        }}
      >
        Précédent
      </Button>
    </Grid>
    <Grid item xs={3}>
      <Button
        fullWidth
        onClick={onSubmit}
        variant="contained"
        color="primary"
        sx={{ textTransform: 'capitalize' }}
      >
        Valider
      </Button>
    </Grid>
  </Grid>
);

export default ProductCategory;
