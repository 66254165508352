/* eslint-disable no-restricted-globals */
/* eslint-disable import/prefer-default-export */
/* eslint-disable no-extend-native */
/* eslint-disable no-plusplus */
Object.defineProperty(Array.prototype, 'unique', {
  enumerable: false,
  configurable: false,
  writable: false,
  value() {
    const a = this.concat();
    for (let i = 0; i < a.length; ++i) {
      for (let j = i + 1; j < a.length; ++j) {
        if (a[i] === a[j]) a.splice(j--, 1);
      }
    }
    return a;
  },
});
export const withoutTime = (date) => {
  const d = new Date(date);
  d.setHours(0, 0, 0, 0);
  return d;
};

export const formatDate = (date) => [date.getDate(), date.getMonth() + 1, date.getFullYear()].join('/');
export const formatAmount = (amount = 0, fraction = 2) => {
  const result = Number(amount).toLocaleString('de-DE', {
    style: 'currency',
    currency: 'MDH',
    minimumFractionDigits: fraction,
  });
  return result.split('MDH')[0].replaceAll('.', ' ');
};

export const findExpireDateAndToday = (invoice) => {
  const DAYS_ECHENACE = invoice.commandeId?.includes('H') ? 2 : 3;
  const dateEcheance = new Date(invoice.dateEcheance);
  const lastDayOfMonth = new Date(dateEcheance.getFullYear(), dateEcheance.getMonth() + 1, 1);
  lastDayOfMonth.setMonth(lastDayOfMonth.getMonth() + DAYS_ECHENACE);
  const today = new Date();
  return { dateEcheance: lastDayOfMonth, currentDate: today };
};

export const lastDateOnMonthDate = (dateIso) => {
  const date = new Date(dateIso);
  date.setMonth(date.getMonth() + 1); // Move to the next month
  date.setDate(0);
  return date;
};
export const APP_PATH_NAME = {
  catalog: '/catalog',
};

export const getStocks = (
  stockDerbOmar,
  reservedDO,
  stockBouskoura,
  stockAinSebaa,
  stockCfcim,
  reserved,
) => {
  const stockDisponibleBouskoura = stockBouskoura + stockAinSebaa + stockCfcim - reserved;
  const stockDisponibleDebOmar = stockDerbOmar - reservedDO;
  return { stockDisponibleBouskoura, stockDisponibleDebOmar };
};
export function isDateValid(dateStr) {
  return !isNaN(new Date(dateStr));
}
