import React, {
  useState, useEffect, useCallback, useContext,
} from 'react';
import {
  Box, Button, Divider, Tab, Tabs, TextField, Typography,
} from '@mui/material';
import useForm from 'hooks/useForm';
import MoveToInboxIcon from '@mui/icons-material/MoveToInbox';
import CloseIcon from '@mui/icons-material/Close';
import EnhancedTable from 'Shared/EnhancedTable';
import useNetworkLoader from 'hooks/useNetworkLoader';
import { format } from 'date-fns';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import { formatAmount } from 'Utils/ulilities';
import { toast } from 'react-toastify';
import useUser from 'hooks/useUser';
import ConfigContext from 'Shared/ConfigContext';
import { columns, isValideDate } from './constants';
import { getFactureByReference, getInvoices, getReleve } from '../AccountServices';
import { OtherFilters } from '../../Catalog/Families';
import { getTitleOfTypeMF } from '../ActualState';

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
    sx: {
      color: '#4D4D4D',
      fontFamily: 'Poppins',
      fontSize: { xs: '12px', md: '14px' },
      fontWeight: 600,
      letterSpacing: 0,
      textTransform: 'none',
      width: { xs: '50%', md: 'unset' },
    },
  };
}
const RestDate = ({ onHandleRest }) => (
  <Box
    sx={{
      position: 'absolute',
      zIndex: 1,
      top: '9px',
      right: '1px',
      background: '#fff',
      width: '40px',
      height: '35px',
      borderRadius: 10,
      color: '#5f5d5d',
      paddingTop: '6px',
      cursor: 'pointer',
      textAlign: 'center',
    }}
    onClick={() => onHandleRest(null)}
  >
    <CloseIcon />
  </Box>
);

const Invoices = ({ totals, isSmallScreen }) => {
  const [initPageable, setInitPageable] = useState(0);
  const isMF = useUser();
  const [invoices, setInvoices] = useState({
    content: [],
    size: 0,
    totalElements: 0,
  });
  const { callApi } = useNetworkLoader();
  const {
    state: { profile },
  } = useContext(ConfigContext);
  const { values, onChange } = useForm({
    tab: 1,
    startDate: null,
    endDate: null,
    souche: [],
  });
  const handleChange = (event, newValue) => {
    onChange({ name: 'tab', value: newValue });
  };
  const handleChangeD = (name) => (newValue) => {
    onChange({ name, value: newValue });
  };
  const onChangeType = (type) => {
    if (values.souche.indexOf(type) === -1) {
      onChange({ name: 'souche', value: [...values.souche, type] });
    } else {
      const newValues = values.souche.filter((item) => item !== type);
      onChange({ name: 'souche', value: newValues });
    }
  };

  const onHandleGenerateFactureByReference = (reference) => {
    callApi(
      () => getFactureByReference(reference),
      async (response) => {
        window.open(URL.createObjectURL(response.data));
      },
    );
  };
  const onHandleGenerateFile = (id) => {
    if (!isValideDate(values.startDate) || !isValideDate(values.endDate)) {
      toast.warn('Date non valide !');
      return;
    }
    callApi(
      () => getReleve({
        startDate: values.startDate && format(values.startDate, 'yyyy-MM-dd'),
        endDate: values.endDate && format(values.endDate, 'yyyy-MM-dd'),
        regle: values.tab === 1,
        souche: values.souche.length === 1 ? values.souche[0] : '',
        orderIds: id ? [id] : null,
      }),
      async (response) => {
        window.open(URL.createObjectURL(response.data));
      },
    );
  };
  const onHandleInvoices = useCallback(
    async (page) => {
      if (!isValideDate(values.startDate) || !isValideDate(values.endDate)) {
        return;
      }
      callApi(
        () => getInvoices(page, values, profile),
        (data) => {
          setInvoices(data);
        },
      );
    },
    [values, profile],
  );
  useEffect(() => setInitPageable(initPageable + 1), [values]);
  const notReg = getTitleOfTypeMF('notReg');
  const reg = getTitleOfTypeMF('reg');
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column-reverse', md: 'unset' },
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Tabs
          value={values.tab}
          onChange={handleChange}
          sx={{
            border: '1px solid #CCCCCC',
            backgroundColor: '#FFFFFF',
            borderBottom: 'none',
            display: 'inline-block',
            width: { xs: '100%', md: 'unset' },
          }}
          TabIndicatorProps={{
            sx: {
              height: '5px',
              backgroundColor: '#007BC6',
            },
          }}
        >
          <Tab
            label={(
              <>
                <Typography sx={{ fontSize: { xs: '11px', md: '16px' } }}>
                  {isSmallScreen ? `${notReg} (${formatAmount(totals.noRegle)}DH)` : notReg}
                </Typography>
                <Typography sx={{ display: { xs: 'none', md: 'unset' } }}>
                  {`${formatAmount(totals.noRegle)}DH`}
                </Typography>
              </>
            )}
            {...a11yProps(0)}
          />
          <Tab
            label={(
              <>
                <Typography sx={{ fontSize: { xs: '11px', md: '16px' } }}>
                  {isSmallScreen ? `${reg} (${formatAmount(totals.regle)}DH)` : reg}
                </Typography>
                <Typography sx={{ display: { xs: 'none', md: 'unset' } }}>
                  {`${formatAmount(totals.regle)}DH`}
                </Typography>
              </>
            )}
            {...a11yProps(1)}
          />
        </Tabs>
        <Box
          sx={{
            display: 'flex',
            mt: { xs: '16px', md: 'unset' },
            mb: { xs: '20px', md: 'unset' },
            flexDirection: { xs: 'column', md: 'unset' },
            alignItems: 'center',
          }}
        >
          <Box sx={{ display: 'flex', mb: { xs: '16px', md: 'unset' } }}>
            <Box sx={{ backgroundColor: '#FFF', width: '154px', position: 'relative' }}>
              {values.startDate !== null && <RestDate onHandleRest={handleChangeD('startDate')} />}
              <DesktopDatePicker
                label="date début:"
                inputFormat="dd/MM/yyyy"
                value={values.startDate}
                onChange={handleChangeD('startDate')}
                renderInput={(params) => <TextField size={isSmallScreen && 'small'} {...params} />}
              />
            </Box>
            <Box
              sx={{
                backgroundColor: '#FFF',
                width: '154px',
                ml: '16px',
                mr: '16px',
                position: 'relative',
              }}
            >
              {values.endDate !== null && <RestDate onHandleRest={handleChangeD('endDate')} />}
              <DesktopDatePicker
                label="date de fin:"
                inputFormat="dd/MM/yyyy"
                value={values.endDate}
                onChange={handleChangeD('endDate')}
                renderInput={(params) => <TextField size={isSmallScreen && 'small'} {...params} />}
              />
            </Box>
          </Box>
          <OtherFilters
            filters={values.souche}
            onChangeFilter={onChangeType}
            items={[
              { name: 'Pièce Moteur', id: 'PM' },
              { name: 'Huile', id: 'HUILE' },
            ]}
            hideTitle
            isSmall
            sx={{ flexDirection: { xs: 'row', md: 'column' } }}
          />
        </Box>
      </Box>
      <Divider />
      <EnhancedTable
        pagination
        getData={onHandleInvoices}
        rows={invoices?.content || []}
        headCells={columns(values.tab === 1, isMF, onHandleGenerateFactureByReference)}
        resetSort={initPageable}
        count={invoices?.totalElements}
        rowsPerPageOptions={[10, 15]}
        fill={!isSmallScreen}
        tableWidth={isSmallScreen && { width: '1000px' }}
      />
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', padding: '0 8px 15px' }}>
        <Button
          onClick={() => onHandleGenerateFile()}
          size="small"
          variant="contained"
          color="primary"
          endIcon={<MoveToInboxIcon />}
          sx={{
            height: '42px',
            borderRadius: 0,
            fontSize: '12px',
            textTransform: 'capitalize',
          }}
        >
          Télécharger
        </Button>
      </Box>
    </>
  );
};

export default Invoices;
