import useNetworkLoader from 'hooks/useNetworkLoader';
import React, { useState, useEffect, useContext } from 'react';
import EnhancedTable from 'Shared/EnhancedTable';
import NoData from 'Shared/NoData';
import FullScreenDialog from 'Shared/FullModal';
import { Typography } from '@mui/material';
import { formatAmount } from 'Utils/ulilities';
import ConfigContext from 'Shared/ConfigContext';
import { getUnpaid, getPublicUrlByRef } from '../AccountServices';
import { unpaidColumns, unpaidDetailColumns } from './constants';

export const PaimentDetails = ({
  open, setOpen, title, details, inprogress = false, paid = false,
}) => (
  <FullScreenDialog open={open} setOpen={setOpen}>
    <Typography
      sx={{
        color: '#4D4D4D',
        fontSize: '21px',
        fontWeight: 600,
        textAlign: 'center',
      }}
    >
      {`Détail ${inprogress ? 'des factures' : ''} pour ${
        paid ? 'la pièce' : 'l\'attestation d\'impayés'
      } ${title}`}
    </Typography>
    <EnhancedTable
      static
      pagination={false}
      rows={details || []}
      headCells={unpaidDetailColumns}
      count={details?.length}
      nthStyle={false}
      fill={false}
      slice={false}
    />
  </FullScreenDialog>
);

export const PreviouInvoice = ({ open, setOpen, link }) => (
  <FullScreenDialog open={open} setOpen={setOpen}>
    <Typography
      sx={{
        color: '#4D4D4D',
        fontSize: '21px',
        fontWeight: 600,
        textAlign: 'center',
      }}
    >
      Pièce de règlement
    </Typography>
    <embed
      src={link}
      width="100%"
      style={{ height: 'calc(100vh - 95px)' }}
      type="application/pdf"
    />
  </FullScreenDialog>
);

const Unpaid = ({ isSmallScreen }) => {
  const [invoices, setInvoices] = useState([]);
  const [details, setDetails] = useState([]);
  const [title, setTitle] = useState('');
  const [open, setOpen] = useState(false);

  const { callApi } = useNetworkLoader();
  const {
    state: { profile },
  } = useContext(ConfigContext);
  const onHandleClickUnpaid = (listTitle, echeances) => {
    setOpen(true);
    setTitle(listTitle);
    if (echeances.length > 0) {
      const toIndex = Math.floor(echeances.length / 2);
      const newEcheances = echeances.map((echeance, index) => {
        if (toIndex === index) {
          return {
            ...echeance,
            totalOrderAmount: formatAmount(
              echeances.reduce((total, e) => total + e.orderAmount, 0),
            ),
          };
        }
        return echeance;
      });
      setDetails(newEcheances);
    } else {
      setDetails(echeances);
    }
  };
  useEffect(() => {
    callApi(
      () => getUnpaid(onHandleClickUnpaid, profile),
      (data) => {
        setInvoices(data);
      },
    );
  }, [profile]);

  // useEffect(() => setInitPageable(initPageable + 1), [values]);

  const onHandleDownloadFile = (event, reference) => {
    callApi(
      () => getPublicUrlByRef(reference),
      (data) => {
        window.open(data.fileName);
      },
    );
    event.preventDefault();
    event.stopPropagation();
  };
  if (invoices?.length === 0) {
    return <NoData message="vous n’avez pas de factures impayés" />;
  }
  return (
    <>
      <PaimentDetails open={open} setOpen={setOpen} title={title} details={details} />
      <EnhancedTable
        slice
        rows={invoices || []}
        headCells={unpaidColumns(true, onHandleDownloadFile)}
        // resetSort={initPageable}
        count={invoices?.length}
        rowsPerPageOptions={[10, 15]}
        fill={!isSmallScreen}
        tableWidth={isSmallScreen && { width: '1000px' }}
      />
    </>
  );
};

export default Unpaid;
