import React, { useEffect, useState } from 'react';

export default function useUser() {
  const [user, setUSer] = useState({});
  useEffect(() => {
    setUSer(JSON.parse(localStorage.user || '{}'));
  }, []);

  return React.useMemo(() => user.type === 'MF', [user]);
}
