import axios from 'axios';
import { getJwkKeystore, jweDecryption, jweEncryption } from 'Utils/crypt';
import history from './history';

const confAxios = {
  baseURL: process.env.REACT_APP_JWE === 'false ' ? 'http://192.168.100.21:8060/api' : '/api',
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
};

const api = axios.create(confAxios);

const apiBlob = axios.create({
  ...confAxios,
  responseType: 'blob',
  // timeout: 5000,
});

const getToken = () => new Promise((resolve) => {
  resolve(localStorage.getItem('token'));
});

export const getResponseData = async (response, key, useJWE = true) => {
  if ([null, '', undefined].includes(response?.data) || !useJWE) {
    return response?.data;
  }
  if (process.env.REACT_APP_JWE === 'false ') {
    return response?.data;
  }
  const data = await jweDecryption(response?.data, key);
  return data;
};

const checkNotAuthored = (err) => {
  if (err.response?.status === 401) {
    history.push('/login');
  } else {
    console.log('err', err);
    throw err;
  }
};

export const secureGet = async (url, config = {}, params = {}, useJWE = true) => {
  const token = await getToken();
  const key = await getJwkKeystore();
  const { headers, ...restConfig } = config;
  const conf = {
    headers: {
      ...headers,
      Authorization: token,
    },
    ...restConfig,
    params,
  };
  return api
    .get(`${url}`, conf)
    .then(async (response) => {
      const data = await getResponseData(response, key, useJWE);
      return { ...response, data };
    })
    .catch(checkNotAuthored);
};

export const secureDelete = async (url) => {
  const token = await getToken();
  return api
    .delete(`${url}`, {
      headers: { Authorization: token },
    })
    .catch(checkNotAuthored);
};

export const securePost = async (url, data, config = {}, useJWE = true) => {
  const token = await getToken();
  const key = await getJwkKeystore();
  const { headers, ...restConfig } = config;
  const isJweEnabled = process.env.REACT_APP_JWE !== 'false';
  const dataJWE = useJWE && isJweEnabled && data ? await jweEncryption(data, key) : data;
  const conf = {
    headers: {
      ...headers,
      Authorization: token,
    },
    ...restConfig,
  };
  return api
    .post(`${url}`, dataJWE, conf)
    .then(async (response) => {
      if (response.headers['content-type']?.search('application/pdf') !== -1) {
        return response;
      }
      const result = await getResponseData(response, key, useJWE);
      return { ...response, data: result };
    })
    .catch(checkNotAuthored);
};

export const securePostPdf = async (url, data, config = {}) => {
  const token = await getToken();
  const key = await getJwkKeystore();
  const { headers, ...restConfig } = config;
  const dataJWE = await jweEncryption(data, key);
  const conf = {
    headers: {
      ...headers,
      Authorization: token,
    },
    ...restConfig,
  };
  return apiBlob
    .post(`${url}`, dataJWE, conf)
    .then(async (response) => response)
    .catch(checkNotAuthored);
};

export const secureGetPdf = async (url, config = {}) => {
  const token = await getToken();
  const { headers, ...restConfig } = config;
  const conf = {
    headers: {
      ...headers,
      Authorization: token,
    },
    ...restConfig,
  };
  return apiBlob
    .get(`${url}`, conf)
    .then(async (response) => response)
    .catch(checkNotAuthored);
};

export const securePatch = async (url, data) => {
  const token = await getToken();
  return api
    .patch(`${url}`, data, {
      headers: { Authorization: token },
    })
    .catch(checkNotAuthored);
};

export const securePut = async (url, data) => {
  const token = await getToken();
  const key = await getJwkKeystore();
  const isJweEnabled = process.env.REACT_APP_JWE !== 'false';
  const dataJWE = data && isJweEnabled ? await jweEncryption(data, key) : data;
  return api
    .put(`${url}`, dataJWE, {
      headers: { Authorization: token },
    })
    .then(async (response) => {
      const result = await getResponseData(response, key);
      return { ...response, data: result };
    })
    .catch(checkNotAuthored);
};

export default api;
