import React from 'react';
import { makeStyles } from '@mui/styles';
import {
  Typography, TextField, InputAdornment, Box, IconButton,
} from '@mui/material';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

export const useBasicInputStyles = (large, hasError, isRegular, multiline) => makeStyles((thm) => ({
  nativeInput: {
    // color: colors.indigo2,
    fontWeight: 500,
    boxShadow: isRegular ? '0 -1px 4px 0 rgba(0,0,0,0.17)' : '0 2px 4px 0 rgba(0,0,0,0.17)',
  },
  inputBase: {
    backgroundColor: 'white',
    height: multiline ? 'auto' : '42px',
    maxWidth: large ? 'auto' : '320px',
    [thm.breakpoints.up('xl')]: {
      maxWidth: large ? 'auto' : '360px',
    },
  },
  inputBaseExpanded: {
    height: multiline ? 'auto' : '42px',
    maxWidth: 'auto',
    [thm.breakpoints.up('xl')]: {
      maxWidth: 'auto',
    },
  },
  label: {
    color: hasError ? '#EA2027' : '#8F8F8F',
  },
  inputLabel: {
    // transform: 'translate(12px, 15px) scale(1)',
    // zIndex: 3,
    top: '-6px',
    color: '#CCCCCC',
  },
  border: {
    borderColor: hasError ? '#EA2027' : '#CCCCCC',
    borderBottomRightRadius: isRegular && 0,
    borderBottomLeftRadius: isRegular && 0,
  },
}))();

export const InputFile = ({
  name, label, large, hasError, onChange, value, onResetFile,
}) => {
  const classes = useBasicInputStyles(large, hasError);
  return (
    <>
      <Typography className={classes.label}>{label}</Typography>
      <Box
        component="label"
        for={`file-upload-${name}`}
        sx={{
          display: 'flex',
          alignItems: 'center',
          cursor: 'pointer',
          marginTop: '6.5px',
          height: '43px',
          border: '1px dashed #CCCCCC',
          borderRadius: '5px',
          paddingLeft: '14px',
          paddingRight: '14px',
          backgroundColor: '#FFFFFF',
          boxShadow: '0 2px 4px 0 rgba(0,0,0,0.17)',
        }}
      >
        <Box sx={{ color: '#CCCCCC', whiteSpace: 'nowrap', overflow: 'hidden' }}>
          {value || label}
        </Box>
        <UploadFileIcon
          sx={{
            marginRight: 0,
            marginLeft: 'auto',
            borderLeft: '1px solid #CCCCCC',
            height: '46px',
            paddingLeft: '7px',
          }}
        />
      </Box>
      <input name={name} onChange={onChange} hidden id={`file-upload-${name}`} type="file" />
      {value && (
        <IconButton onClick={() => onResetFile(name)} sx={{ left: 0, top: 0 }}>
          <DeleteForeverIcon sx={{ color: 'red', height: '18px' }} />
        </IconButton>
      )}
    </>
  );
};

const Input = ({
  type,
  name,
  value,
  onChange,
  expanded,
  large = true,
  label,
  hint,
  iconEnd,
  hasError,
  isRegular,
  multiline,
  ...rest
}) => {
  const classes = useBasicInputStyles(large, hasError, isRegular, multiline);

  return (
    <>
      <Typography className={classes.label}>{label}</Typography>
      <TextField
        name={name}
        value={value}
        type={type || 'text'}
        label={hint || ''}
        onChange={onChange}
        variant="outlined"
        InputProps={{
          className: classes.nativeInput,
          classes: {
            root: classes.inputBase,
            notchedOutline: classes.border,
          },
          inputProps: rest.type === 'number' ? { min: 0, max: 100 } : {},
          endAdornment: iconEnd ? (
            <InputAdornment
              sx={{
                maxHeight: 'none',
                height: '100%',
                paddingLeft: '10px',
                borderLeft: '1px solid #CCC',
              }}
              position="end"
            >
              {iconEnd}
            </InputAdornment>
          ) : null,
        }}
        // eslint-disable-next-line react/jsx-no-duplicate-props
        inputProps={{
          className: classes.nativeInput,
          'data-testid': name,
          classes: {
            // root: cx({
            //  [classes.inputBase]: !expanded,
            //  [classes.inputBaseExpanded]: expanded && !rest.multiline,
            // }),
            root: classes.inputBase,
            notchedOutline: classes.border,
          },
        }}
        InputLabelProps={{
          classes: {
            outlined: classes.inputLabel,
          },
        }}
        fullWidth={large}
        multiline={multiline}
        {...rest}
      />
    </>
  );
};

export default Input;
