import React from 'react';
import Button from '@mui/material/Button';

const EdgeButton = ({ sx, ...props }) => (
  <Button
    {...props}
    sx={{
      // fontSize: 14,
      // fontWeight: 600,
      borderRadius: 0,
      textTransform: 'capitalize',
      ...sx,
    }}
  />
);

export default EdgeButton;
