/* eslint-disable import/prefer-default-export */
import { defaultMessage } from 'Components/Home/HomeServices';
import {
  aes256gcm,
  getJwkKeystore,
  getTransKey,
  jweEncryption,
  JWE_KEY,
  KEYSTORE,
} from 'Utils/crypt';
import {
  login, loginSucces, loginError, firstLogin,
} from '../../Shared/UserContext/Actions';
import api from '../../config/apiConfig';

export const loginUser = async (dispatch, credential, history) => {
  try {
    const transKey = await getTransKey();
    const bodyFormData = new FormData();
    bodyFormData.set('username', credential.username);
    bodyFormData.set('password', credential.password);
    if (process.env.REACT_APP_JWE !== 'false ') {
      bodyFormData.set('transKey', transKey.encrypted);
    }
    dispatch(login());

    const {
      data: { key, user, token },
    } = await api.post(
      '/login',
      bodyFormData,
      {
        headers: {
          'content-type': 'multipart/form-data',
        },
      },
      false,
    );
    if (process.env.REACT_APP_JWE !== 'false ') {
      const aesCipher = await aes256gcm(Buffer.from(transKey.clair, 'hex'));
      const keyJWE = await aesCipher.decrypt(
        Buffer.from(key.slice(0, 32), 'hex'),
        '0000000000000000',
        Buffer.from(key.slice(32, 64), 'hex'),
      );
      await localStorage.setItem(JWE_KEY, keyJWE);
      const keystore = await getJwkKeystore(keyJWE);
      await localStorage.setItem(KEYSTORE, keystore.toString());
    }
    if (user?.firstLogin) {
      dispatch(firstLogin(true, token, user));
    } else {
      dispatch(loginSucces(user, token));
      history.push('/');
    }
  } catch (e) {
    const {
      response: {
        data: { statusLabel = defaultMessage },
      },
    } = e;
    dispatch(loginError(statusLabel));
  }
};

export const changeFirstPassword = async (dispatch, body, history, user, token) => {
  try {
    const conf = {
      headers: {
        Authorization: token,
      },
    };
    const key = await getJwkKeystore();
    const dataJWE = await jweEncryption(body, key);

    await api.post('/users/password/change', dataJWE, conf);

    dispatch(firstLogin(false));
    dispatch(loginSucces(user, token));
    history.push('/');
  } catch (e) {
    const {
      response: {
        data: { statusLabel = defaultMessage },
      },
    } = e;
    dispatch(loginError(statusLabel));
  }
};
