import { useState } from 'react';

/**
 *
 * @param initialState: must include all fields keys (names)
 * @param submit: function. on form submit, may be useful for validation
 * @returns {{onChange: function, values: any}}
 *
 * Supports only fields with event.target.{name, value}
 */
export default function useForm(initialState, submit) {
  const [values, setValues] = useState(initialState);

  function handleChange(e) {
    const {
      name, value, type, checked, files,
    } = e.target || e;
    switch (type) {
      case 'checkbox':
        setValues((oldValues) => ({
          ...oldValues,
          [name]: checked,
        }));
        break;
      case 'file':
        setValues((oldValues) => ({
          ...oldValues,
          [name]: files[0],
        }));
        break;
      default:
        setValues((oldValues) => ({
          ...oldValues,
          [name]: value,
        }));
        break;
    }
  }

  function handleSubmit(e) {
    e?.preventDefault();
    submit({ ...values });
  }

  return {
    onChange: handleChange,
    onSubmit: handleSubmit,
    values,
  };
}
