import { Box, Button, Typography } from '@mui/material';
import { WithRolesComponent } from 'HOC/withRoles';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Header from 'Shared/Header';
import footer from '../../Images/footer.png';
import AddNewPanier from './AddNewPanier';
import AddProductToPanier from './AddProductToPanier';
import SubHeader from './SubHeader';

export const formatDate = (date) => [date.getMonth() + 1, date.getDate(), date.getFullYear()].join('-');
export const formatAmount = (amount = 0, fraction = 0) => {
  const result = Number(amount).toLocaleString('de-DE', {
    style: 'currency',
    currency: 'MDH',
    minimumFractionDigits: fraction,
  });
  return result.split('MDH')[0];
};

const Footer = () => (
  <>
    <Box
      textAlign="center"
      sx={{ height: '160px', backgroundImage: `url(${footer})`, display: 'flow-root' }}
    >
      <Typography
        sx={{
          color: '#fff',
          fontSize: '20px',
          fontWeight: 'bold',
          mb: 3,
          mt: 4,
          textAlign: 'center',
        }}
      >
        Vous ne trouvez pas ce que vous cherchez?
      </Typography>
      <Button target="_blank" variant="contained" href="http://copima.ma/contact" color="primary">
        Contactez nous
      </Button>
    </Box>
    <Typography
      sx={{
        color: '#8F8F8F',
        fontSize: '14px',
        mt: 3,
        pb: 2,
        textAlign: 'center',
      }}
    >
      COPIMA 2022 © - Tous droits resérvés
    </Typography>
  </>
);
const NO_SUB_HEADER = ['/cart', '/media', '/account'];
const Layout = ({ children }) => {
  const { pathname } = useLocation();
  // const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down('md'));
  const [h, setH] = useState(100);
  useEffect(() => {
    if (NO_SUB_HEADER.includes(pathname)) {
      setH(100);
    }
  }, [pathname]);
  return (
    <>
      <Header />
      {!NO_SUB_HEADER.includes(pathname) && (
        <WithRolesComponent allowedRoles={['ROLE_CLIENT']}>
          <SubHeader onSetSubHeaderH={setH} />
        </WithRolesComponent>
      )}
      <>
        <AddNewPanier />
        <AddProductToPanier />
        <Box
          sx={{
            backgroundColor: '#f8f8f8',
            zIndex: 1,
            pt: { md: `${h}px`, xs: 0 },
          }}
        >
          <Box sx={{ pl: { xs: 0, md: 17 }, pr: { xs: 0, md: 17 } }}>{children}</Box>
          <Footer />
        </Box>
      </>
    </>
  );
};

export default Layout;
