import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import { Modal, useMediaQuery } from '@mui/material';
import Fade from '@mui/material/Fade';
import { useTheme } from '@mui/material/styles';

const style = (disableRootStyle) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: disableRootStyle ? '97%' : 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  pr: disableRootStyle ? 0 : 4,
  pl: disableRootStyle ? 0 : 4,
});

export default function TransitionsModal({
  open,
  setOpen,
  children,
  isLarge = false,
  xLarge = false,
  sx = {},
  disableRootStyle,
}) {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

  const handleClose = () => setOpen(false);
  const styleSX = isLarge || xLarge
    ? {
      '& .MuiBox-root': {
        width: xLarge ? '1000px' : '700px',
      },
    }
    : {};
  const styleSXMobile = {
    '& .MuiBox-root': {
      width: '80%',
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  };
  return (
    <Modal
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
      sx={!disableRootStyle && (isSmallScreen ? styleSXMobile : styleSX)}
    >
      <Fade in={open}>
        <Box sx={{ ...style(disableRootStyle), ...sx }}>{children}</Box>
      </Fade>
    </Modal>
  );
}
