import {
  Autocomplete, Box, Button, TextField, Typography, useMediaQuery,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import Modal from 'Shared/Modal';
import React, { useContext, useMemo } from 'react';
import ConfigContext from 'Shared/ConfigContext';
import useNetworkLoader from 'hooks/useNetworkLoader';
import Input from 'Shared/Input';
import { validatedCart } from 'Shared/ConfigContext/Actions';
import RenderOnDemandThenHide from 'Components/RenderOnDemandThenHide';
import { toast } from 'react-toastify';
import { validateCart } from './services';

const ValidateCart = ({
  open, setOpen, selectedCart, onSuccessValidation, values, onChange,
}) => {
  const {
    dispatch,
    state: { commercials, expeditions },
  } = useContext(ConfigContext);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
  const { callApi } = useNetworkLoader();

  const handleAddCart = () => {
    const cart = {
      expeditionId: values.expedition.id,
      commercialId: values.commerial,
      comment: values.comment,
      name: selectedCart.name,
      products: selectedCart.products.map(({ reference, quantity, stock }) => ({
        quantity,
        reference,
        isExist: quantity <= stock,
      })),
    };
    const notExist = cart.products.filter((product) => !product.isExist);
    if (notExist.length) {
      const refs = notExist.map(({ reference }) => reference).toString();
      toast.info(`${refs}, les quantities de ces références n'existent pas`);
    }
    callApi(
      () => validateCart(selectedCart.id, cart),
      () => {
        dispatch(validatedCart(selectedCart.name));
        setOpen(false);
        onSuccessValidation(true);
      },
    );
  };
  const isExpeditionAvailibale = useMemo(() => {
    if (values?.expedition?.max) {
      const now = new Date();
      return (
        values.expedition.max[0] > now.getHours()
        || (values.expedition.max[0] === now.getHours()
          && values.expedition.max[1] >= now.getMinutes())
      );
    }
    return true;
  }, [values?.expedition]);
  return (
    <Modal open={open} setOpen={setOpen} isLarge>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Typography
          sx={{
            color: '#8F8F8F',
            fontSize: '14px',
            fontWeight: 600,
            mt: '20px',
            mb: '30px',
            textAlign: 'center',
          }}
        >
          Vous avez prèsque terminé
        </Typography>
        <Typography
          sx={{
            color: '#8F8F8F',
            fontSize: '14px',
            mb: '20px',
            textAlign: 'center',
          }}
        >
          Merci de choisir le moyen de transport et le commercial qui vous accompagnera tout au long
          de la procédure.
        </Typography>
        <Autocomplete
          options={expeditions || []}
          onChange={(e, v) => onChange({ target: { name: 'expedition', value: v } })}
          // value={values.expedition}
          size="small"
          autoHighlight
          fullWidth
          style={{ height: '56px' }}
          getOptionLabel={(option) => option.name}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Transport:"
              variant="outlined"
              inputProps={{
                ...params.inputProps,
                autoComplete: 'new-password', // disable autocomplete and autofill
              }}
            />
          )}
        />
        <RenderOnDemandThenHide visible={!isExpeditionAvailibale}>
          <Box>
            <Typography sx={{ color: '#ea2027', fontSize: '12px' }}>
              Merci de noter que le transport choisie dispose d’une heure limité d’envoi de la
              commande.
            </Typography>
            <Typography sx={{ color: '#ea2027', fontSize: '12px' }}>
              Merci de choisir un autre transport qui ne dispose pas de cette contrainte à fin de
              garantir le traitement de la commande.
            </Typography>
          </Box>
        </RenderOnDemandThenHide>

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            mt: '20px',
            width: '100% !important',
          }}
        >
          <Autocomplete
            options={commercials || []}
            onChange={(e, v) => onChange({ target: { name: 'commerial', value: v.id } })}
            // value={values.commerial}
            size="small"
            fullWidth
            autoHighlight
            style={{ height: '56px' }}
            getOptionLabel={(option) => option.name}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Commercial:"
                variant="outlined"
                inputProps={{
                  ...params.inputProps,
                  autoComplete: 'new-password', // disable autocomplete and autofill
                }}
              />
            )}
          />
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            mb: '30px',
            width: '100% !important',
          }}
        >
          <Input
            value={values.comment}
            onChange={onChange}
            margin="dense"
            label="Commentaire:"
            name="comment"
            multiline
            rows={4}
          />
        </Box>
        <Box sx={{ display: 'flex' }}>
          <Button
            fullWidth
            onClick={() => setOpen(false)}
            variant="contained"
            color="inherit"
            sx={{
              ml: { xs: 0, md: '100px' },
              border: '2px solid #CCCCCC',
              color: '#CCCCCC',
              backgroundColor: '#FFFFFF',
              textTransform: 'capitalize',
            }}
          >
            Annuler
          </Button>
          <Button
            fullWidth
            disabled={!values.commerial || !values.expedition}
            onClick={handleAddCart}
            variant="contained"
            color="success"
            sx={{ mr: { xs: 0, md: '100px' }, ml: '14px', textTransform: 'capitalize' }}
          >
            {isSmallScreen ? 'Valider' : 'Valider le panier'}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default ValidateCart;
