import {
  Box, Button, Typography, Divider, useMediaQuery, TextField,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import Modal from 'Shared/Modal';
import React, { useContext, useMemo } from 'react';
import ConfigContext from 'Shared/ConfigContext';
import { setPanierOpenState } from 'Shared/ConfigContext/Actions';
import EnhancedTable from 'Shared/EnhancedTable';
import { ConfirmRemoveProductOrCart } from 'Shared/Header/Cart';
import { useHistory } from 'react-router-dom';
import { formatAmount, APP_PATH_NAME } from 'Utils/ulilities';
import useForm from 'hooks/useForm';
import RenderOnDemandThenHide from 'Components/RenderOnDemandThenHide';
import ThumbUpOutlinedIcon from '@mui/icons-material/ThumbUpOutlined';
import InfoClientBar from 'Shared/InfoClientBar';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import useNetworkLoader from 'hooks/useNetworkLoader';
import { cartColumns } from '../columns';
import ValidateCart from '../ValidateCart';
import Footer from './Footer';
import CartDetails, { Indecators } from './CartDetails';
import CartStatusFilter from './CartStatusFilter';
import { getExpeditionFile } from '../services';

const index = () => {
  const {
    dispatch,
    state: { paniers: carts },
  } = useContext(ConfigContext);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
  const { values, onChange } = useForm({
    comment: '',
    currentCarts: true,
    startDate: null,
    endDate: null,
  });
  const paniers = carts?.filter((p) => p?.validated === (values.currentCarts ? 0 : 1)) || [];
  const isAllEmpty = !(carts?.length !== 0);
  const [value, setValue] = React.useState(0);
  const [openCartConfirm, setOpenCartConfirm] = React.useState(false);
  const [openConfirm, setOpenConfirm] = React.useState(false);
  const [productCart, setProductCart] = React.useState({ name: null, id: null });
  const [open, setOpen] = React.useState(false);
  const [openCartDetail, setOpenCartDetail] = React.useState(false);
  const [openSuccessValidation, setOpenSuccessValidation] = React.useState(false);
  const history = useHistory();
  const [cartStatus, setCartStatus] = React.useState('NON_ASSIGNEE');
  const { callApi } = useNetworkLoader();
  const handleChangeCurrentCart = () => {
    setValue(0);
    onChange({ name: 'currentCarts', value: !values.currentCarts });
  };
  const handleOpenDetail = (cartIndex) => () => {
    setValue(cartIndex);
    setOpenCartDetail(true);
  };
  const {
    totalSend, totalInprogress, totalDone, totalFinished,
  } = useMemo(
    () => ({
      totalSend: paniers?.filter((cart) => cart.status === 'NON_ASSIGNEE').length,
      totalInprogress: paniers?.filter((cart) => cart.status === 'EN_ATTENTE_VALIDATION').length,
      totalDone: paniers?.filter((cart) => cart.status === 'BL_GENERE').length,
      totalFinished: paniers?.filter((cart) => cart.status === 'BL_GENERE_TERMINE').length,
    }),
    [paniers],
  );
  const total = useMemo(() => {
    let sum = 0;
    paniers
      .filter((cart) => values.currentCarts || cart.status === cartStatus)
      .forEach((element) => {
        sum += element?.products.reduce(
          (prev, curr) => prev
            + curr.price
              * ((100.0
                // eslint-disable-next-line max-len
                - (curr.remiseExceptionnelle
                  ? curr.remise + curr.remiseExceptionnelle
                  : curr.remise))
                / 100.0)
              * curr.quantity,
          0,
        );
      });
    return sum;
  }, [paniers]);
  const totalBrut = useMemo(() => {
    let sum = 0;
    paniers
      .filter((cart) => values.currentCarts || cart.status === cartStatus)
      .forEach((element) => {
        sum += element?.products.reduce((prev, curr) => prev + curr.price * curr.quantity, 0);
      });
    return sum;
  }, [paniers, cartStatus, values.currentCarts]);
  const onHandleRemove = (cartIndex) => {
    setValue(cartIndex);
    setOpenCartConfirm(true);
  };
  const onHandleDownloadExpedition = (bcNumber) => {
    callApi(
      () => getExpeditionFile(bcNumber),
      (response) => {
        window.open(response.fileName);
      },
    );
  };
  const onHandleAddCart = () => {
    dispatch(setPanierOpenState(true));
  };
  const onValidCart = (cartIndex) => {
    setValue(cartIndex);
    setOpen(true);
  };
  const navToCataloge = () => {
    history.push(APP_PATH_NAME.catalog);
  };
  const callBackAfterRemoveCart = () => {
    setValue(Math.max(value - 1, 0));
  };
  const handleChangeD = (name) => (newValue) => {
    onChange({ name, value: newValue });
  };
  const currentCarts = paniers.filter((cart) => !cart.status || cart.status === cartStatus);
  return (
    <Box sx={{ pt: { xs: 10, md: 0 }, pl: 0, pr: 0 }}>
      <Modal open={openSuccessValidation} setOpen={setOpenSuccessValidation} isLarge>
        <Box sx={{ textAlign: 'center' }}>
          <ThumbUpOutlinedIcon fontSize="large" color="primary" />
          <Typography
            sx={{
              color: '#8F8F8F',
              fontSize: { xs: '12px', md: '14px' },
              fontWeight: 600,
              textAlign: 'center',
              mt: '25px',
            }}
          >
            Votre panier a été validée avec succès
          </Typography>
          <Typography
            sx={{
              color: '#8F8F8F',
              fontSize: { xs: '12px', md: '14px' },
              textAlign: 'center',
              mt: '32px',
            }}
          >
            Vous pouvez suivre votre commande sur votre éspace mon compte
          </Typography>
          <Box
            sx={{
              display: 'flex',
              flexDirection: { xs: 'column', md: 'row' },
              mt: '45px',
              mr: { xs: 0, md: '20px' },
              ml: { xs: 0, md: '20px' },
            }}
          >
            <Button
              fullWidth
              onClick={() => setOpenSuccessValidation(false)}
              variant="contained"
              color="primary"
              sx={{
                mr: { xs: 0, md: '26px' },
                mb: { xs: '7px', md: 0 },
                textTransform: 'capitalize',
              }}
            >
              {isSmallScreen ? 'Mon compte' : 'Accéder à mon compte'}
            </Button>
            <Button
              fullWidth
              onClick={navToCataloge}
              variant="contained"
              color="inherit"
              sx={{
                border: '2px solid #CCCCCC',
                color: '#CCCCCC',
                backgroundColor: '#FFFFFF',
                textTransform: 'capitalize',
              }}
            >
              Continuer mes Achats
            </Button>
          </Box>
        </Box>
      </Modal>
      <CartDetails
        totalBrut={totalBrut}
        total={total}
        paniers={currentCarts}
        value={value}
        open={openCartDetail}
        setOpen={setOpenCartDetail}
        values={values}
        setOpenConfirm={setOpenConfirm}
        setProductCart={setProductCart}
        onHandleRemove={onHandleRemove}
      />
      <ValidateCart
        open={open}
        setOpen={setOpen}
        selectedCart={paniers[value]}
        onSuccessValidation={setOpenSuccessValidation}
        values={values}
        onChange={onChange}
      />
      <Box sx={{ display: 'flex', flexDirection: 'row', marginBottom: { xs: '24px', md: '64px' } }}>
        <Typography
          sx={{
            color: '#4D4D4D',
            fontSize: { xs: '16px', md: '21px' },
            fontWeight: 600,
          }}
        >
          Paniers (
          {carts?.length}
          )
        </Typography>
        <Button
          onClick={onHandleAddCart}
          variant="contained"
          color="primary"
          sx={{
            border: '2px solid #007BC6',
            color: '#007BC6',
            backgroundColor: '#FFFFFF',
            textTransform: 'capitalize',
            '&:hover': { color: '#FFF' },
            marginRight: '0px',
            marginLeft: 'auto',
          }}
        >
          Ajouter un nouveau panier
        </Button>
      </Box>
      <RenderOnDemandThenHide visible={!isAllEmpty}>
        <Box>
          <Box
            sx={{ display: 'flex', justifyContent: 'flex-end', mb: { xs: '16px', md: 'unset' } }}
          >
            <Box sx={{ backgroundColor: '#FFF', width: '244px' }}>
              <DesktopDatePicker
                label="date début:"
                maxDate={values.endDate}
                inputFormat="dd/MM/yyyy"
                value={values.startDate}
                onChange={handleChangeD('startDate')}
                renderInput={(params) => (
                  <TextField
                    size={isSmallScreen && 'small'}
                    {...params}
                    sx={{
                      svg: { color: '#007BC6' },
                      input: { borderRight: '1px solid #c4c4c4' },
                      borderRadius: 0,
                    }}
                  />
                )}
              />
            </Box>
            <Box
              sx={{
                backgroundColor: '#FFF',
                width: '244px',
                ml: '16px',
                mr: '16px',
              }}
            >
              <DesktopDatePicker
                label="date de fin:"
                inputFormat="dd/MM/yyyy"
                minDate={values.startDate}
                value={values.endDate}
                onChange={handleChangeD('endDate')}
                renderInput={(params) => (
                  <TextField
                    size={isSmallScreen && 'small'}
                    {...params}
                    sx={{
                      svg: { color: '#007BC6' },
                      input: { borderRight: '1px solid #c4c4c4' },
                      borderRadius: 0,
                    }}
                  />
                )}
              />
            </Box>
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: { xs: 'column', md: 'row' },
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: { xs: 'space-evenly', md: 'unset' },
                // mb: { xs: '30px', md: 'unset' },
              }}
            >
              <Typography
                sx={{
                  textTransform: 'uppercase',
                  cursor: 'pointer',
                  color: values.currentCarts ? '#007BC6' : '#8F8F8F',
                  fontSize: { xs: '12px', md: '14px' },
                  fontWeight: 600,
                  backgroundColor: 'white',
                  padding: '17px 22px',
                  borderRadius: '15px 15px 0 0',
                  border: '2px solid',
                  width: { xs: '50%', md: 'unset' },
                  textAlign: 'center',
                }}
                onClick={handleChangeCurrentCart}
              >
                paniers encours
              </Typography>
              <Typography
                sx={{
                  textTransform: 'uppercase',
                  cursor: 'pointer',
                  color: values.currentCarts ? '#8F8F8F' : '#007BC6',
                  fontSize: { xs: '12px', md: '14px' },
                  fontWeight: 600,
                  backgroundColor: 'white',
                  padding: '17px 22px',
                  borderRadius: '15px 15px 0 0',
                  border: '2px solid',
                  width: { xs: '50%', md: 'unset' },
                  textAlign: 'center',
                }}
                onClick={handleChangeCurrentCart}
              >
                paniers validés
              </Typography>
            </Box>
          </Box>
          <Divider sx={{ borderWidth: { xs: 0, md: '2px' } }} />
          <Typography
            sx={{
              color: '#8F8F8F',
              fontSize: '10px',
              fontStyle: 'italic',
              mt: '13px',
              mb: '25px',
            }}
          >
            Certains articles peuvent ne plus être DISPONIBLES sur nos stock entre le temps de
            création du panier et sa validation. Vous pouvez supprimer les articles NON-DISPONIBLE
            de votre panier en cliquant sur l’icone
          </Typography>
          <RenderOnDemandThenHide visible={paniers[0]?.validated === 1}>
            <CartStatusFilter
              cartStatus={cartStatus}
              setCartStatus={setCartStatus}
              totalSend={totalSend}
              totalInprogress={totalInprogress}
              totalDone={totalDone}
              totalFinished={totalFinished}
            />
          </RenderOnDemandThenHide>
          <Typography
            sx={{
              color: '#4D4D4D',
              fontSize: { xs: '16px', md: '18px' },
              fontWeight: 600,
              marginLeft: { xs: 'unset', md: 'auto' },
            }}
          >
            Total des paniers
            {values.currentCarts ? ' en cours: ' : ' validés: '}
            {formatAmount(total?.toFixed(2))}
            Dhs
          </Typography>
        </Box>
      </RenderOnDemandThenHide>
      <RenderOnDemandThenHide visible={paniers?.length > 0}>
        <>
          {false && (
            <RenderOnDemandThenHide visible={!isSmallScreen}>
              <Indecators />
            </RenderOnDemandThenHide>
          )}
          <Box sx={{ border: '1px solid #CCCCCC', borderBottom: 'unset' }}>
            <EnhancedTable
              static
              pagination={false}
              rows={
                paniers
                  ?.filter((cart) => values.currentCarts || cart.status === cartStatus)
                  .filter((cart) => {
                    const leftJoin = values.startDate
                      ? new Date(cart.createdAt) >= new Date(values.startDate)
                      : true;
                    const rightJoin = values.endDate
                      ? new Date(cart.createdAt) <= new Date(values.endDate)
                      : true;
                    return leftJoin && rightJoin;
                  }) || []
              }
              headCells={cartColumns(
                onHandleRemove,
                onHandleDownloadExpedition,
                onValidCart,
                handleOpenDetail,
                isSmallScreen,
                values.currentCarts,
                false,
                cartStatus === 'BL_GENERE',
              )}
              count={paniers?.length}
              nthStyle={false}
              fill={false}
              slice={false}
            />
          </Box>
          <Footer totalBrut={totalBrut} total={total} />
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              mb: '40px',
            }}
          >
            <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-end' }}>
              <Box sx={{ mt: '10px' }}>
                <Typography
                  display="inline"
                  sx={{
                    color: '#4D4D4D',
                    fontSize: { xs: '12px', md: '14px' },
                    fontWeight: 500,
                  }}
                >
                  Ou&nbsp;
                </Typography>
                <Typography
                  display="inline"
                  sx={{
                    color: '#F79F1F',
                    fontSize: { xs: '12px', md: '14px' },
                    fontWeight: 500,
                    cursor: 'pointer',
                  }}
                  onClick={() => history.push(APP_PATH_NAME.catalog)}
                >
                  Continuer vos achats
                </Typography>
              </Box>
            </Box>
          </Box>
        </>
      </RenderOnDemandThenHide>
      {!paniers?.length && (
        <Box
          sx={{
            display: 'flex',
            alignContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
          }}
        >
          <img src="/bag-cross.svg" alt="test" style={{ marginBottom: '16px' }} />
          <Typography
            sx={{
              color: '#4D4D4D',
              fontSize: { xs: '12px', md: '16px' },
              fontWeight: 'bold',
            }}
          >
            Votre panier est actuellement vide.
          </Typography>
          <Typography
            sx={{
              color: '#4D4D4D',
              fontSize: { xs: '12px', md: '16px' },
              fontWeight: 'bold',
              textAlign: 'center',
              mb: { xs: '30px', md: '39px' },
            }}
          >
            <Typography
              component="span"
              onClick={onHandleAddCart}
              sx={{
                color: '#007BC6',
                fontSize: { xs: '12px', md: '16px' },
                fontWeight: 'bold',
                cursor: 'pointer',
              }}
            >
              Ajouter un nouveau panier
            </Typography>
            {' '}
            et commencer à ajouter des produits
          </Typography>
          <Button
            onClick={() => history.push('catalog')}
            variant="contained"
            color="primary"
            sx={{
              textTransform: 'capitalize',
              mt: '20px',
              my: 4,
              border: '2px solid #007BC6',
              color: '#007BC6',
              backgroundColor: '#FFFFFF',
              '&:hover': { color: '#FFF' },
            }}
          >
            Commencer vos achats
          </Button>
        </Box>
      )}
      <ConfirmRemoveProductOrCart
        open={openCartConfirm}
        setOpen={setOpenCartConfirm}
        cartName={paniers[value]?.name}
        cartId={paniers[value]?.id}
        callBackAfterRemove={callBackAfterRemoveCart}
      />
      <ConfirmRemoveProductOrCart
        open={openConfirm}
        setOpen={setOpenConfirm}
        id={productCart.id}
        linkedProductId={productCart.linkedProduct}
        cartName={productCart.name}
        cartId={paniers[value]?.id}
        isProduct
      />
      <InfoClientBar />
    </Box>
  );
};

export default index;
