export const fields = [
  {
    id: 'username',
    label: 'Username',
    type: 'autocomplete',
    defaultValue: '',
    valuesName: 'clients',
    displayLabelItem: (a) => `${a.code} : ${a.name}`,
    valueItem: (a) => a.code,
    filterItems: (option, value) => option.code
      ?.toLowerCase()
      .includes(value.toLowerCase())
    || option.name
      ?.toLowerCase()
      .includes(value.toLowerCase()),
  },
  {
    id: 'password',
    label: 'password',
    type: 'password',
    defaultValue: '',
  },
];

export const DEFAULT_VALUES = fields.reduce(
  (prev, curr) => ({ ...prev, [curr.id]: curr.defaultValue }), {},
);
export const ROLES = [
  { value: 'ROLE_ADMIN', label: 'Administrateur' },
  { value: 'ROLE_CLIENT', label: 'Client' },
];
