import React from 'react';
import TickCircle from 'Images/tick-circle.svg';
import { Box, CardMedia, Typography } from '@mui/material';

const NoData = ({ message }) => (
  <Box
    sx={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      height: 168,
    }}
  >
    <CardMedia component="img" src={TickCircle} sx={{ heigth: '42px', width: '42px' }} />
    <Typography
      sx={{
        mt: '5px',
        color: '#4D4D4D',
        fontSize: '16px',
      }}
    >
      {message}
    </Typography>
  </Box>
);

export default NoData;
