import React from 'react';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';

export function DefaultNotAllowedFallback() {
  return (
    <Paper
      sx={{
        minWidth: 150,
        minHeight: 250,
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <Typography
        sx={{
          fontWeight: 500,
          color: 'red',
          textOverflow: 'ellipsis',
          overflow: 'hidden',
          textAlign: 'center',
          width: '100%',
        }}
      >
        You have no access to this resource
      </Typography>
    </Paper>
  );
}

export default function withRoles(allowedRoles, fallback = <DefaultNotAllowedFallback />) {
  return function withRolesHoc(WP) {
    return function WithRolesImpl(props) {
      const user = JSON.parse(localStorage.user || '{}');
      if (allowedRoles?.includes(user.role)) {
        return <WP {...props} />;
      }
      return fallback || null;
    };
  };
}

export function WithRolesComponent({ allowedRoles, children, fallback }) {
  const user = JSON.parse(localStorage.user || '{}');
  if (allowedRoles?.includes(user.role)) {
    return children;
  }
  return fallback || null;
}

export function WithTypesComponent({ blockedTypes, children, fallback }) {
  const user = JSON.parse(localStorage.user || '{}');
  if (blockedTypes?.includes(user.type)) {
    return fallback || null;
  }
  return children;
}
