import { createContext } from 'react';

export const initialState = {
  user: localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null,
  token: localStorage.getItem('token') ? localStorage.getItem('token') : null,
  firstLogin: { tmpToken: null, tmpUser: null, isFirstLogin: false },
  config: {},
  status: '',
  errorMessage: '',
  networking: 0,
};

const UserContext = createContext(initialState);

export const { Provider } = UserContext;

export default UserContext;
