import { Box } from '@mui/material';
import React from 'react';
import CloseIcon from '@mui/icons-material/Close';

export const RestDate = ({ onHandleRest }) => (
  <Box
    sx={{
      position: 'absolute',
      zIndex: 1,
      top: '9px',
      right: '1px',
      background: '#fff',
      width: '40px',
      height: '35px',
      borderRadius: 10,
      color: '#5f5d5d',
      paddingTop: '6px',
      cursor: 'pointer',
      textAlign: 'center',
    }}
    onClick={() => onHandleRest(null)}
  >
    <CloseIcon />
  </Box>
);
export function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
    sx: {
      color: '#4D4D4D',
      fontFamily: 'Poppins',
      fontSize: { xs: '12px', md: '14px' },
      fontWeight: 600,
      letterSpacing: 0,
      textTransform: 'none',
      width: { xs: '50%', md: 'unset' },
    },
  };
}
