/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import Layout from 'Shared/Layout';

const ProtectedRoute = ({ component: Component, ...rest }) => {
  const token = localStorage.getItem('token');
  const renderPage = (props) => {
    const { location } = props;
    const roleStore = localStorage.getItem('user');
    const role = roleStore == null ? null : JSON.parse(roleStore).role;
    const PERMITTED_ROLES = ['ROLE_ADMIN', 'ROLE_CLIENT'];
    if (!token || role == null || !PERMITTED_ROLES.includes(role)) {
      return (
        <Redirect
          to={{
            pathname: '/login',
            state: { from: location },
          }}
        />
      );
    }
    return (
      <Layout>
        <Component {...rest} {...props} />
      </Layout>
    );
  };
  return <Route {...rest} render={renderPage} />;
};

ProtectedRoute.propTypes = {
  component: PropTypes.func.isRequired,
};

export default ProtectedRoute;
