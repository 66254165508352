import { Box, Typography } from '@mui/material';
import React from 'react';
import { formatAmount } from 'Utils/ulilities';

const Footer = ({ totalBrut = 0, total = 0, isLightView = false }) => (
  <Box
    sx={{
      height: { xs: '43px', md: '83.5px' },
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'row-reverse',
      justifyContent: { xs: 'space-evenly', md: 'unset' },
      border: isLightView ? 'none' : '1px solid #CCCCCC',
      background: isLightView ? 'none' : '#FFF',
      marginBottom: isLightView ? 0 : { xs: '17px', md: '41.5px' },
      borderBottomLeftRadius: isLightView ? 0 : '5px',
      borderBottomRightRadius: isLightView ? 0 : '5px',
    }}
  >
    <Box>
      <Typography
        display="inline"
        sx={{
          color: '#4D4D4D',
          fontSize: { xs: '12px', md: '16px' },
          fontWeight: 'bold',
          marginRight: { xs: '12px', md: '45px' },
        }}
      >
        TOTAL NET:
      </Typography>
      <Typography
        display="inline"
        sx={{
          color: '#4D4D4D',
          fontSize: { xs: '12px', md: '16px' },
          fontWeight: 'bold',
          marginRight: { xs: '7px', md: '19.5px' },
        }}
      >
        {formatAmount(total?.toFixed(2))}
        Dhs
      </Typography>
    </Box>
    <Box>
      <Typography
        display="inline"
        sx={{
          color: '#4D4D4D',
          fontSize: { xs: '12px', md: '16px' },
          fontWeight: 'bold',
          marginRight: { xs: '12px', md: '45px' },
        }}
      >
        TOTAL BRUTE:
      </Typography>
      <Typography
        display="inline"
        sx={{
          color: '#4D4D4D',
          fontSize: { xs: '12px', md: '16px' },
          fontWeight: 'bold',
          marginRight: { xs: '7px', md: '19.5px' },
        }}
      >
        {formatAmount(totalBrut?.toFixed(2))}
        Dhs
      </Typography>
    </Box>
  </Box>
);

export default Footer;
