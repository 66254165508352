import React from 'react';
import {
  Box, Button, Typography,
} from '@mui/material';
import Image from 'Shared/Image';
import { formatAmount } from 'Utils/ulilities';
import AddIcon from '@mui/icons-material/Add';

import RenderOnDemandThenHide from 'Components/RenderOnDemandThenHide';
import Available, { applyRemise } from './Available';
import defaultProduct from '../../Images/default_product.png';
import Remise from './Remise';

export const BoxItem = ({ title, isSmallScreen, children }) => (
  <Box sx={{
    display: 'flex', flexDirection: 'row', justifyContent: 'space-between', mt: isSmallScreen ? '2px' : 1.5, mb: isSmallScreen ? '2px' : 1.5,
  }}
  >
    <Typography sx={{
      color: '#8F8F8F',
      fontSize: { xs: '12px', md: 14 },
      fontWeight: 600,
      pr: { xs: '9px', md: 0 },
    }}
    >
      {title}
    </Typography>
    {children}
  </Box>
);

export const TagPromoNew = ({
  title, color, borderColor, addMargin = false,
}) => (
  <Box
    sx={{
      position: 'absolute',
      top: -10,
      right: { xs: addMargin ? 62 : 0, md: addMargin ? 62 : -10 },
      backgroundColor: color,
      p: '3px 8px',
      '&::before': {
        content: '""',
        borderStyle: 'solid',
        borderWidth: '15px 0 10px 10px',
        borderColor: `transparent transparent ${borderColor} transparent`,
        position: 'absolute',
        left: '-10px',
        top: '-15px',
      },
      '&::after': {
        content: '""',
        borderStyle: 'solid',
        borderWidth: ' 0 10px 10px 10px',
        borderColor: {
          xs: 'transparent',
          md: `transparent transparent transparent ${addMargin ? 'transparent' : borderColor}`,
        },
        position: 'absolute',
        right: '-10px',
        bottom: '-10px',
      },
    }}
  >
    <Typography sx={{
      verticalAlign: 'middle', color: '#fff', fontSize: '10px', fontWeight: 600,
    }}
    >
      {title}
    </Typography>
  </Box>
);

const ProductDetail = ({
  product,
  setOpenGarantee,
  stockDisponibleDebOmar,
  stockDisponibleBouskoura,
  addToPanier,
  isSmallScreen,
  onHandleClickCard,
  showRemise,
}) => (
  <Box
    sx={{
      position: 'relative',
      backgroundColor: '#FFFFFF',
      boxShadow: '0 2px 4px 2px rgba(0,0,0,0.07)',
      p: '20px 10px',
      height: 'auto',
      cursor: 'pointer',
    }}
    onClick={() => onHandleClickCard(product)}
  >
    <RenderOnDemandThenHide visible={applyRemise(product?.remise, product?.remiseExceptionnelle)}>
      <Remise
        promo={product?.promo}
        remise={product?.remise}
        remiseExceptionnelle={product?.remiseExceptionnelle}
      />
    </RenderOnDemandThenHide>
    <RenderOnDemandThenHide visible={product?.isNew}>
      <TagPromoNew title="Nouveau" color="#27AE60" borderColor="#1C8A4A" />
    </RenderOnDemandThenHide>
    <RenderOnDemandThenHide visible={product?.promo}>
      <TagPromoNew title="En promo" color="#F79F1F" borderColor="#CA7800" addMargin={product?.isNew} />
    </RenderOnDemandThenHide>
    <Box sx={{
      display: 'flex', flexDirection: 'row', justifyContent: 'space-around', ml: isSmallScreen ? 0 : 6, mr: isSmallScreen ? 0 : 6,
    }}
    >
      <Box sx={{
        justifyContent: 'center',
        display: 'flex',
        flexDirection: 'column',
        minHeight: 150,
        mr: { xs: '10px', md: 0 },
      }}
      >
        <Image
          src={(product?.image && product?.image) || defaultProduct}
          alt="categorie"
          width={isSmallScreen ? 100 : 150}
          style={{ maxHeight: 150, alignSelf: 'center' }}
        />
        {product?.familyTwo === '110' && setOpenGarantee && (
        <Typography
          sx={{
            color: '#007BC6',
            fontSize: '12px',
            fontWeight: 500,
            cursor: 'pointer',
            width: '100%',
            textAlign: 'center',
            mt: '10px',
          }}
          onClick={() => setOpenGarantee(true)}
        >
          Consultez la fiche garantie du produit
        </Typography>
        )}
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <Typography sx={{
          color: '#4D4D4D',
          fontSize: { xs: '12px', md: 21 },
          fontWeight: 600,
        }}
        >
          {product?.name}
        </Typography>
        <Typography sx={{
          color: '#8F8F8F',
          fontSize: { xs: '9px', md: 10 },
          fontWeight: '600',
          textTransform: 'uppercase',
          mb: 1,
        }}
        >
          {product?.family?.name}
        </Typography>
        <BoxItem title={`Réf: ${product?.reference}`} isSmallScreen={isSmallScreen}>
          <Box sx={{
            flex: 1,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'end',
          }}
          >
            <Image
              src={(product?.brand?.logoImage && product?.brand?.logoImage) || defaultProduct}
              alt="categorie"
              width={isSmallScreen ? 50 : 75}
            />
          </Box>
        </BoxItem>
        <BoxItem title="Dépot Bouskoura:" isSmallScreen={isSmallScreen}>
          <Available sx={{}} available={stockDisponibleBouskoura > 0} />
        </BoxItem>
        <BoxItem title="Dépot Derb Omar:" isSmallScreen={isSmallScreen}>
          <Available sx={{}} available={stockDisponibleDebOmar > 0} />
        </BoxItem>
        <RenderOnDemandThenHide
          visible={applyRemise(product?.remise, product?.remiseExceptionnelle)}
        >
          <BoxItem title="Prix brut TTC:" isSmallScreen={isSmallScreen}>
            <Typography sx={{
              color: '#4D4D4D',
              fontSize: { xs: '12px', md: 14 },
              fontWeight: 500,
            }}
            >
              {`${formatAmount(product?.price)}DH`}
            </Typography>
          </BoxItem>
        </RenderOnDemandThenHide>
        <RenderOnDemandThenHide
          visible={showRemise && applyRemise(product?.remise, product?.remiseExceptionnelle)}
        >
          <BoxItem title="Remise appliquée:" isSmallScreen={isSmallScreen}>
            <Typography sx={{
              color: '#4D4D4D',
              fontSize: { xs: '12px', md: 18 },
              fontWeight: 800,
            }}
            >
              {`-${applyRemise(product?.remise, product?.remiseExceptionnelle)}%`}
            </Typography>
          </BoxItem>
        </RenderOnDemandThenHide>
        <BoxItem title="Prix net TTC:" isSmallScreen={isSmallScreen}>
          <Typography sx={{
            color: applyRemise(product?.remise, product?.remiseExceptionnelle) ? '#F79F1F' : '#4D4D4D',
            fontSize: { xs: '12px', md: 18 },
            fontWeight: 800,
          }}
          >
            {!applyRemise(product?.remise, product?.remiseExceptionnelle) ? `${formatAmount(product?.price)}DH` : `${formatAmount(Number((product?.price * (100 - applyRemise(product?.remise, product?.remiseExceptionnelle))) / 100), 2)}DH`}
          </Typography>
        </BoxItem>
      </Box>
    </Box>
    <Box sx={{
      width: '100%',
      textAlign: 'center',
    }}
    >
      <Button onClick={addToPanier} color="primary" variant="outlined" startIcon={<AddIcon />}>Ajouter au panier</Button>
    </Box>
  </Box>
);

export default ProductDetail;
