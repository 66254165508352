import React, {
  useCallback, useContext, useEffect, useMemo, useState,
} from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import { useTheme } from '@mui/material/styles';

import { useHistory } from 'react-router-dom';
import UserContext from 'Shared/UserContext';
import { resetLogin } from 'Shared/UserContext/Actions';
import {
  Box, Button, Tooltip, Badge, useMediaQuery,
} from '@mui/material';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import NotificationsNoneRoundedIcon from '@mui/icons-material/NotificationsNoneRounded';
import ShoppingBagOutlinedIcon from '@mui/icons-material/ShoppingBagOutlined';
import { WithRolesComponent } from 'HOC/withRoles';
import FullScreenDialog from 'Shared/FullModal';
import useForm from 'hooks/useForm';
import ThumbUpOutlinedIcon from '@mui/icons-material/ThumbUpOutlined';
import { ChangePasswordForm } from 'Components/Login/Login';
import ConfigContext from 'Shared/ConfigContext';
import { setProfile } from 'Shared/ConfigContext/Actions';
import ProfileDetail from 'Components/Users/ProfileDetail';
import RenderOnDemandThenHide from 'Components/RenderOnDemandThenHide';
import { authUrl } from 'Utils/authConfig';
import { formatAmount } from 'Utils/ulilities';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import { ReactComponent as Logo } from '../../Images/logo_bar.svg';
import { updateProfile, getProfile, updatePassword } from './services';
import Modal from '../Modal';
import Cart from './Cart';
import Settings from './Settings';
import DrawerMenu from './DrawerMenu';
import SearchHeader from './SearchHeader';

export const applyRemise = (po) =>
  // eslint-disable-next-line implicit-arrow-linebreak
  (po?.remiseExceptionnelle ? po?.remise + po?.remiseExceptionnelle : po?.remise);
export const SuccessModal = ({ open, setOpen, onCloseAll }) => (
  <Modal open={open} setOpen={setOpen}>
    <Box sx={{ p: '20px 40px', textAlign: 'center' }}>
      <ThumbUpOutlinedIcon fontSize="large" color="primary" />
      <Typography
        sx={{
          color: '#8F8F8F',
          fontSize: 14,
          fontWeight: 600,
          mt: 2.5,
          mb: 2.5,
        }}
      >
        Modification enregistrée avec succès
      </Typography>
      <Button
        onClick={onCloseAll}
        variant="contained"
        color="primary"
        sx={{ textTransform: 'capitalize' }}
      >
        Fermer
      </Button>
    </Box>
  </Modal>
);

// eslint-disable-next-line no-unused-vars
const ProfileInfo = ({ user, onUpdateProfile, onClose }) => {
  const { values, onChange } = useForm(user);
  const [open, setOpen] = useState(false);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

  const onSubmit = (data) => {
    // e.preventDefault();
    onUpdateProfile(data);
    if (!isSmallScreen) {
      setOpen(true);
    }
  };
  const onCloseAll = () => {
    // onClose();
    setOpen(false);
  };

  return (
    <Box sx={{ p: { xs: '10px 10px 100px', sm: '10px 150px 100px' } }}>
      <Typography
        sx={{
          color: '#4D4D4D',
          fontSize: { xs: '16px', sm: 21 },
          fontWeight: 600,
          textAlign: { xs: 'left', sm: 'center' },
          mb: 4,
        }}
      >
        Mes informations personnelles
      </Typography>
      <ProfileDetail user={values} onChange={onChange} onSubmit={onSubmit} />
      <SuccessModal open={open} setOpen={setOpen} onCloseAll={onCloseAll} />
    </Box>
  );
};

const ChangePasswordBody = ({ onClose }) => {
  const { onChange, values } = useForm({
    currentPassword: '',
    newPassword: '',
    newPassword2: '',
    showPassword: false,
    showPassword2: false,
    showPassword3: false,
  });
  const [open, setOpen] = useState(false);
  const onCloseAll = () => {
    onClose();
    setOpen(false);
  };

  const isValide = values.currentPassword
    && values.newPassword !== ''
    && values.newPassword === values.newPassword2;

  const onUpdatePwd = async () => {
    const data = await updatePassword({
      oldPassword: values?.currentPassword,
      newPassword: values.newPassword,
    });
    if (data) {
      setOpen(true);
    }
  };

  return (
    <Box sx={{ p: { xs: '10px 44px 100px', sm: '10px 250px 100px' } }}>
      <Typography
        sx={{
          color: '#4D4D4D',
          fontSize: '16px',
          fontWeight: 600,
          textAlign: 'center',
        }}
      >
        Changement de mot de passe
      </Typography>
      <ChangePasswordForm values={values} onChange={onChange} />
      <Box sx={{ mt: 4, display: 'flex', justifyContent: { xs: 'center', sm: 'end' } }}>
        <Button
          onClick={onClose}
          variant="contained"
          color="inherit"
          sx={{
            border: '2px solid #CCCCCC',
            color: '#CCCCCC',
            backgroundColor: '#FFFFFF',
            textTransform: 'capitalize',
          }}
        >
          Annuler
        </Button>
        <Button
          disabled={!isValide}
          onClick={onUpdatePwd}
          variant="contained"
          color="primary"
          sx={{ ml: '10px', textTransform: 'capitalize' }}
        >
          Enregistrer
        </Button>
      </Box>
      <Modal open={open} setOpen={setOpen}>
        <Box sx={{ p: '20px 40px', textAlign: 'center' }}>
          <ThumbUpOutlinedIcon fontSize="large" color="primary" />
          <Typography
            sx={{
              color: '#8F8F8F',
              fontSize: 14,
              fontWeight: 600,
              mt: 2.5,
              mb: 2.5,
            }}
          >
            Modification enregistrée avec succès
          </Typography>
          <Button
            onClick={onCloseAll}
            variant="contained"
            color="primary"
            sx={{ textTransform: 'capitalize' }}
          >
            Fermer
          </Button>
        </Box>
      </Modal>
    </Box>
  );
};

const ACCOUNT_PAGE = { label: 'Espace Mon Compte', to: '/account', allowedRoles: ['ROLE_CLIENT'] };
const pages = [
  { label: 'Catalogue Produits', to: '/catalog', allowedRoles: ['ROLE_CLIENT'] },
  // hide only on production
  // { label: 'Promotions', to: null, allowedRoles: ['ROLE_CLIENT'] },
  { label: 'Utilisateurs', to: '/users', allowedRoles: ['ROLE_ADMIN'] },
  { label: 'Gestion des Annonces', to: '/announcements', allowedRoles: ['ROLE_ADMIN'] },
];
export const logoutAndBackToLogin = (dispatch, history, role) => () => {
  localStorage.clear();
  if (role === 'ROLE_ADMIN') {
    window.location.href = authUrl();
  } else {
    dispatch(resetLogin());
    history.push('/login');
  }
};

const Header = () => {
  const history = useHistory();
  const [isSearch, setIsSearch] = React.useState(false);
  const [isOpen, setIsOpen] = React.useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [openModalPwd, setOpenModalPwd] = useState(false);
  const {
    dispatch: dispatchConfig,
    state: { profile, paniers },
  } = useContext(ConfigContext);
  const {
    state: { user },
  } = useContext(UserContext);
  const totalProduct = useMemo(
    () => paniers
      ?.filter((panier) => !panier.validated)
      .flatMap((panier) => panier.products)
      .map((po) => po) || [],
    [paniers],
  );
  const totalPrice = useMemo(
    () => totalProduct
      .reduce(
        (total, po) => total + po.price * ((100.0 - applyRemise(po)) / 100.0) * po.quantity,
        0,
      )
      .toFixed(2) || 0,
    [paniers],
  );
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openCart = Boolean(anchorEl?.id === 'cart');

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const navTo = (to) => {
    if (to) {
      history.push(to);
    }
  };

  const onGetProfile = async () => {
    const data = await getProfile();
    dispatchConfig(setProfile(data));
  };

  const onUpdateProfile = useCallback(async (newProfile) => {
    await updateProfile(newProfile);
    onGetProfile();
  });

  useEffect(() => {
    if (user.role === 'ROLE_CLIENT') {
      onGetProfile();
    }
  }, [user]);

  return (
    <AppBar position="fixed" sx={{ backgroundColor: '#F8F8F8' }}>
      <FullScreenDialog open={openModal} setOpen={setOpenModal}>
        <ProfileInfo
          user={profile}
          onUpdateProfile={onUpdateProfile}
          onClose={() => setOpenModal(false)}
        />
      </FullScreenDialog>
      <FullScreenDialog open={openModalPwd} setOpen={setOpenModalPwd}>
        <ChangePasswordBody onClose={() => setOpenModalPwd(false)} />
      </FullScreenDialog>
      <Grid container>
        <Toolbar
          sx={{
            margin: { sm: 0, md: '0px 112px' }, p: { md: 0 }, width: '100%', height: isSearch && '150px',
          }}
        >
          <RenderOnDemandThenHide visible={isSearch}>
            <SearchHeader onCloseSearch={setIsSearch} />
          </RenderOnDemandThenHide>
          <RenderOnDemandThenHide visible={!isSearch}>
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="menu"
              onClick={() => setIsOpen(true)}
              sx={{ color: '#007bc6', mr: 1.5, display: { xs: 'block', sm: 'block', md: 'none' } }}
            >
              <MenuIcon />
            </IconButton>
            <DrawerMenu
              navTo={navTo}
              pages={[...pages, ACCOUNT_PAGE]}
              setIsOpen={setIsOpen}
              isOpen={isOpen}
              setOpenModalPwd={setOpenModalPwd}
              setOpenModal={setOpenModal}
            />

            <Logo onClick={() => navTo('/')} style={{ marginRight: 20, cursor: 'pointer' }} />

            <>
              <Box
                sx={{
                  flexGrow: 1,
                  height: '100%',
                  display: { xs: 'none', sm: 'none', md: 'flex' },
                }}
              >
                {pages.map((page) => (
                  <WithRolesComponent allowedRoles={page.allowedRoles} key={page.label}>
                    <Button
                      onClick={() => navTo(page.to)}
                      sx={{
                        fontSize: 14,
                        fontWeight: 600,
                        color: history?.location.pathname === page.to ? '#007BC6' : '#4D4D4D',
                        display: 'block',
                        borderBottom: history?.location.pathname === page.to && '5px solid #007BC6',
                        borderRadius: 0,
                        textTransform: 'capitalize',
                        pl: '21px',
                        pr: '21px',
                      }}
                    >
                      {page.label}
                    </Button>
                  </WithRolesComponent>
                ))}
              </Box>
              <Box
                sx={{
                  flexGrow: 0,
                  display: { xs: 'flex', sm: 'flex', md: 'initial' },
                  width: { sm: '100%', md: 'auto' },
                  justifyContent: 'flex-end',
                }}
              >
                <WithRolesComponent allowedRoles={ACCOUNT_PAGE.allowedRoles}>
                  <Button
                    variant="contained"
                    color="warning"
                    sx={{
                      textTransform: 'capitalize',
                      display: { xs: 'none', sm: 'none', md: 'initial' },
                    }}
                    onClick={() => navTo(ACCOUNT_PAGE.to)}
                  >
                    {ACCOUNT_PAGE.label}
                  </Button>
                  <Button
                    startIcon={<ShoppingBagOutlinedIcon sx={{ color: '#007bc6' }} />}
                    onMouseEnter={handleMenu}
                    id="cart"
                    aria-describedby="cart-popover"
                    sx={{
                      display: { xs: 'none', sm: 'none', md: 'initial' },
                      borderLeft: '2px solid #8F8F8F',
                      borderRight: '2px solid #8F8F8F',
                      mr: 2,
                      ml: 2,
                      mt: 0,
                      fontSize: '10px',
                      fontWeight: 'bold',
                      textAlign: 'left',
                      borderRadius: 0,
                      backgroundColor: '#fff',
                      color: '#8F8F8F',
                      height: '64px',
                    }}
                  >
                    {`${totalProduct.length} PRODUITS`}
                    <br />
                    {`${formatAmount(totalPrice)}DH`}
                  </Button>
                  <Cart open={openCart} handleClose={handleClose} anchorEl={anchorEl} />
                  {/* hide only on production */}
                  <RenderOnDemandThenHide visible={false}>
                    <Tooltip title="Open notification">
                      <IconButton size="large" sx={{ color: 'gray' }}>
                        <Badge badgeContent={17} color="error">
                          <NotificationsNoneRoundedIcon />
                        </Badge>
                      </IconButton>
                    </Tooltip>
                  </RenderOnDemandThenHide>
                </WithRolesComponent>

                <Tooltip title="Recherche">
                  <IconButton
                    size="large"
                    id="settings"
                    onClick={() => setIsSearch(true)}
                    sx={{ color: 'gray', display: { xs: 'block', sm: 'block', md: 'none' } }}
                  >
                    <SearchIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Paniers">
                  <IconButton
                    size="large"
                    id="settings"
                    onClick={() => navTo('/cart')}
                    sx={{ color: 'gray', display: { xs: 'initial', sm: 'initial', md: 'none' } }}
                  >
                    <ShoppingBagOutlinedIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Paramètres">
                  <IconButton
                    size="large"
                    id="settings"
                    onClick={handleMenu}
                    sx={{ color: 'gray', display: { xs: 'none', sm: 'none', md: 'initial' } }}
                  >
                    <SettingsOutlinedIcon />
                  </IconButton>
                </Tooltip>
                <Settings
                  anchorEl={anchorEl}
                  setAnchorEl={setAnchorEl}
                  setOpenModalPwd={setOpenModalPwd}
                  setOpenModal={setOpenModal}
                />
              </Box>
            </>
          </RenderOnDemandThenHide>
        </Toolbar>
      </Grid>
    </AppBar>
  );
};

Header.propTypes = {};

export default Header;
