import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Modal from 'Shared/Modal';
import {
  Button, CardMedia, Divider, useMediaQuery,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { toast } from 'react-toastify';
import RenderOnDemandThenHide from 'Components/RenderOnDemandThenHide';
import ConfigContext from 'Shared/ConfigContext';
import { setProfile } from 'Shared/ConfigContext/Actions';
import useNetworkLoader from 'hooks/useNetworkLoader';
import EditIcon from '../../Images/edit@3x.svg';
import DeleteIcon from '../../Images/delete_cart.svg';
import Identification from './ProfileEdition/Identification';
import InfoLegal from './ProfileEdition/InfoLegal';
import Expedition from './ProfileEdition/Expedition';
import ContactView from './ContactView';
import ContactEdit from './ContactEdit';
import { getProfile } from './UsersService';

function TabPanel(props) {
  const {
    children, value, index, ...other
  } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}
const tabSx = {
  '&.Mui-selected': {
    backgroundColor: '#FFF',
    color: '#4D4D4D',
    fontSize: '14px',
    fontWeight: 600,
  },
  color: '#8F8F8F',
};

const LabelValue = ({
  label, description, value = '', isSmall = false,
}) => (
  <Box sx={{ mb: '23px', overflowWrap: 'anywhere' }}>
    <Box sx={{ display: 'flex', flexDirection: isSmall ? 'column' : 'row', mb: isSmall && '10px' }}>
      <Typography
        sx={{
          color: description ? '#4D4D4D' : '#8F8F8F',
          fontSize: '14px',
          fontWeight: description ? 600 : 500,
          mr: '10px',
          whiteSpace: 'nowrap',
        }}
      >
        {`${label}  `}
      </Typography>
      <Typography
        sx={{
          color: description ? '#8F8F8F' : '#4D4D4D',
          fontSize: '14px',
          fontWeight: description ? 500 : 600,
        }}
      >
        {value}
      </Typography>
    </Box>
    <Typography
      sx={{
        color: description ? '#8F8F8F' : '#4D4D4D',
        fontSize: '14px',
        fontWeight: description ? 500 : 600,
      }}
    >
      {description}
    </Typography>
  </Box>
);

const Title = ({ title, setEdit, onRemove }) => (
  <Box sx={{ display: 'flex' }}>
    <Typography
      sx={{
        color: '#4D4D4D',
        fontSize: '18px',
        fontWeight: 'bold',
        mb: '32px',
        mr: '14px',
      }}
    >
      {title}
    </Typography>
    {setEdit && (
      <CardMedia
        component="img"
        src={EditIcon}
        onClick={() => setEdit(true)}
        sx={{
          height: '24px',
          width: '24px',
          cursor: 'pointer',
        }}
      />
    )}
    {onRemove && (
      <CardMedia
        component="img"
        src={DeleteIcon}
        onClick={onRemove}
        sx={{
          height: '24px',
          width: '24px',
          cursor: 'pointer',
          ml: '30px',
        }}
      />
    )}
  </Box>
);

export default function ProfileDetail({ user, onChange, onSubmit }) {
  const {
    dispatch: dispatchConfig,
    state: { profile },
  } = React.useContext(ConfigContext);
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('md'));
  const [value, setValue] = React.useState(0);
  const [edit, setEdit] = React.useState(false);
  const [primary, setPrimary] = React.useState(true);
  const [confirm, setConfirm] = React.useState(false);
  const [contact, setContact] = React.useState(false);
  const [updateProfile, setUpdateProfile] = React.useState(false);
  const { callApi } = useNetworkLoader();

  const [contactIndex, setContactIndex] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleEditContact = (index, c) => {
    setEdit(true);
    setContact(c);
    setContactIndex(index);
  };

  React.useEffect(() => {
    callApi(getProfile, (data) => {
      dispatchConfig(setProfile(data));
    });
  }, [updateProfile]);
  const onUpdateProfile = () => {
    setUpdateProfile(!updateProfile);
  };
  const onSaveChanges = (values) => {
    setEdit(false);
    toast.success('Modification enregistrée avec succès');
    // call
    onSubmit(values);
  };

  const addExpedition = (isPrimary) => () => {
    setPrimary(isPrimary);
    setEdit(true);
  };

  const onHandleRemoveExpedition = (isPrimary) => () => {
    const expeditions = user?.expeditions.filter((exp) => exp.primary !== isPrimary);
    onChange({
      target: {
        name: 'expeditions',
        value: expeditions,
      },
    });
    toast.success('supprission avec succès');
    setConfirm(false);
    // call
    onSubmit({ ...user, expeditions });
  };
  const primaryExpedition = user?.expeditions.find((exp) => exp.primary);
  const secondaryExpedition = user?.expeditions.find((exp) => !exp.primary);
  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
          sx={{
            border: '1px solid #CCCCCC',
            // backgroundColor: '#FFFFFF',
            '& .MuiTabs-indicator': { height: '5px' },
          }}
          TabIndicatorProps={{
            sx: {
              height: '5px',
              backgroundColor: '#007BC6',
            },
          }}
          variant="scrollable"
          scrollButtons
          allowScrollButtonsMobile
        >
          <Tab sx={tabSx} label="Identification" {...a11yProps(0)} />
          <Tab sx={tabSx} label="CONTACT" {...a11yProps(1)} />
          <Tab sx={tabSx} label="Informations légales" {...a11yProps(2)} />
          <Tab sx={tabSx} label="Livraison" {...a11yProps(3)} />
          <Tab sx={tabSx} label="Condition de paiement" {...a11yProps(4)} />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <>
          <Title title="Identification:" setEdit={setEdit} />
          <LabelValue label="Code Client:" value={user?.code} />
          <LabelValue label="Intitulé compte:" value={user?.name} />
          <LabelValue label="Téléphone de l’entreprise:" value={user?.phone} />
          <LabelValue label="E-mail de l’entreprise:" value={user?.email} />
          <LabelValue label="Adresse:" value={user?.address} />
          <LabelValue label="Ville:" value={user?.city} />
        </>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <ContactView user={profile} edit={handleEditContact} onUpdateProfile={onUpdateProfile} />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <Box sx={{ position: 'relative' }}>
          <Title title="Informations légales:" setEdit={setEdit} />
          <Button
            onClick={setEdit}
            variant="contained"
            color="inherit"
            sx={{
              position: 'absolute',
              display: { xs: 'none', md: 'unset' },
              top: 0,
              right: 0,
              border: '2px solid #007BC6',
              color: '#007BC6',
              backgroundColor: '#FFFFFF',
              textTransform: 'capitalize',
            }}
          >
            Ajouter des informations
          </Button>
          <LabelValue
            label={isSmall ? 'ICE:' : 'Identifiant commun Entreprise (ICE):'}
            value={user?.ice}
          />
          <LabelValue
            label={isSmall ? 'RC:' : 'Registre de Commerce (RC):'}
            value={`${user?.rc || ''} (${user?.city || ''})`}
          />
          <LabelValue label="Identifiant Fiscal:" value="" />
          <LabelValue label="Patente:" value={user?.patente} />
        </Box>
      </TabPanel>
      <TabPanel value={value} index={3}>
        <Box sx={{ position: 'relative' }}>
          <Title title="Livraison:" />
          <Button
            disabled={primaryExpedition && secondaryExpedition}
            onClick={addExpedition(!primaryExpedition)}
            variant="contained"
            color="inherit"
            sx={{
              position: 'absolute',
              top: 0,
              right: 0,
              border: '2px solid #007BC6',
              color: '#007BC6',
              backgroundColor: '#FFFFFF',
              textTransform: 'capitalize',
            }}
          >
            Ajouter un transport
          </Button>
          <LabelValue label="Condition de livraison:" value="CR (Compte Remboursement)" />
          <Divider sx={{ mb: '36px', mt: '25px' }} />
          <Title
            title="Transport primaire:"
            setEdit={primaryExpedition && addExpedition(true)}
            onRemove={primaryExpedition && (() => setConfirm('primary'))}
          />
          <RenderOnDemandThenHide visible={primaryExpedition}>
            <LabelValue label="" value={primaryExpedition?.expedition.name} />
            <LabelValue
              label=""
              value={primaryExpedition?.type === 'GARE' ? 'En gare' : 'A domicile'}
            />
          </RenderOnDemandThenHide>
          <RenderOnDemandThenHide visible={!primaryExpedition}>
            <Typography
              sx={{
                color: '#8F8F8F',
                fontSize: '14px',
                fontWeight: 500,
                mb: '33px',
              }}
            >
              Aucun transport ne figure sur votre profile
            </Typography>
            <Typography
              onClick={addExpedition(true)}
              sx={{
                color: '#007BC6',
                fontSize: '14px',
                fontWeight: 500,
                cursor: 'pointer',
              }}
            >
              Ajouter un transport primaire
            </Typography>
          </RenderOnDemandThenHide>
          <Divider sx={{ mb: '36px', mt: '25px' }} />

          <Title
            title="Transport secondaire:"
            setEdit={secondaryExpedition && addExpedition(false)}
            onRemove={secondaryExpedition && (() => setConfirm('secondary'))}
          />
          <RenderOnDemandThenHide visible={secondaryExpedition}>
            <LabelValue label="" value={secondaryExpedition?.expedition.name} />
            <LabelValue
              label=""
              value={secondaryExpedition?.type === 'GARE' ? 'En gare' : 'A domicile'}
            />
          </RenderOnDemandThenHide>
          <RenderOnDemandThenHide visible={!secondaryExpedition}>
            <Typography
              sx={{
                color: '#8F8F8F',
                fontSize: '14px',
                fontWeight: 500,
                mb: '33px',
              }}
            >
              Aucun transport ne figure sur votre profile
            </Typography>
            <Typography
              onClick={addExpedition(false)}
              sx={{
                color: '#007BC6',
                fontSize: '14px',
                fontWeight: 500,
                cursor: 'pointer',
              }}
            >
              Ajouter un transport secondaire
            </Typography>
          </RenderOnDemandThenHide>
          <Divider sx={{ mb: '36px', mt: '25px' }} />
        </Box>
      </TabPanel>
      <TabPanel value={value} index={4}>
        <>
          <LabelValue label="Mode de paiement:" value="Chèque" />
          <Box
            sx={{
              height: '1px',
              backgroundColor: '#ccc',
              width: '100%',
              mb: '20px',
              mt: '20px',
            }}
          />
          <Typography
            sx={{
              color: '#4D4D4D',
              fontSize: '18px',
              fontWeight: 'bold',
              mb: '32px',
              mr: '14px',
            }}
          >
            Echéance de réglement:
          </Typography>
          <LabelValue label="Pièces Moteur (PM):" value="90Jours" />
          <LabelValue label="Huile (H):" value="60Jours" />
          <Box
            sx={{
              height: '1px',
              backgroundColor: '#ccc',
              width: '100%',
              mb: '20px',
              mt: '20px',
            }}
          />
          <Typography
            sx={{
              color: '#4D4D4D',
              fontSize: '18px',
              fontWeight: 'bold',
              mb: '32px',
              mr: '14px',
            }}
          >
            Informations:
          </Typography>
          <LabelValue
            label="Jour fin de mois:"
            value="Quand nous parlons d’échéance, le nombre de jours fin de mois désigne le nombre de jours maximum pour payer la facture à partir du dernier jour du mois où la facture a été émise."
            description="Exemple: Si une facture a été émise le 12/01/2022 et qu’elle est payable à 90 jours fin de mois, la date maximum d’échéance pour le paiement de cette facture est le 30/04/2022."
            isSmall={isSmall}
          />
          <LabelValue
            label="Récéption réglement:"
            value="Merci de noter que nous exigeons que la reception des pièces de règlements se fasse à 10 jours fin de mois."
            description="Exemple: Si une facture à été émise le 12/01/2022, le client devra établir et nous envoyer une pièce de règlement relative à cette facture au plus tard le 10/02/2022, peut importe l’échéance de paiement de cette pièce de règlement."
            isSmall={isSmall}
          />
        </>
      </TabPanel>
      <Modal open={['secondary', 'primary'].includes(confirm)} setOpen={setConfirm}>
        <Box sx={{ textAlign: 'center' }}>
          <Typography
            sx={{
              color: '#8F8F8F',
              fontSize: '14px',
              fontWeight: 600,
              mb: '85px',
              ml: { xs: 0, md: '70px' },
              mr: { xs: 0, md: '70px' },
            }}
          >
            Etes-vous sûr de vouloir supprimer ces informations?
          </Typography>
          <Button
            onClick={() => setConfirm(false)}
            variant="contained"
            color="inherit"
            sx={{
              border: '2px solid #CCCCCC',
              color: '#CCCCCC',
              backgroundColor: '#FFFFFF',
              textTransform: 'capitalize',
            }}
          >
            Annuler
          </Button>
          <Button
            onClick={onHandleRemoveExpedition(confirm === 'primary')}
            variant="contained"
            color="error"
            sx={{
              ml: '27px',
              border: '2px solid #EA2027',
              color: '#EA2027',
              backgroundColor: '#FFFFFF',
              textTransform: 'capitalize',
              '&:hover': {
                color: '#FFF',
              },
            }}
          >
            {isSmall ? 'supprimer' : 'supprimer le transport'}
          </Button>
        </Box>
      </Modal>
      <Modal
        open={edit}
        setOpen={setEdit}
        isLarge={value !== 1}
        xLarge={value === 1}
        sx={{ maxHeight: '650px', overflowY: 'auto' }}
      >
        <RenderOnDemandThenHide visible={value === 0}>
          <Identification
            user={user}
            onChange={onChange}
            onClose={() => setEdit(false)}
            onSave={onSaveChanges}
          />
        </RenderOnDemandThenHide>
        <RenderOnDemandThenHide visible={value === 1}>
          <ContactEdit
            user={profile}
            contact={contact}
            onClose={() => setEdit(false)}
            onSave={onSaveChanges}
            contactIndex={contactIndex}
            onUpdateProfile={onUpdateProfile}
          />
        </RenderOnDemandThenHide>
        <RenderOnDemandThenHide visible={value === 2}>
          <InfoLegal
            user={user}
            onChange={onChange}
            onClose={() => setEdit(false)}
            onSave={onSaveChanges}
          />
        </RenderOnDemandThenHide>
        <RenderOnDemandThenHide visible={value === 3}>
          <Expedition
            user={user}
            onChange={onChange}
            onClose={() => setEdit(false)}
            onSave={onSaveChanges}
            isPrimary={primary}
          />
        </RenderOnDemandThenHide>
      </Modal>
    </Box>
  );
}
