import React, { useContext } from 'react';
import {
  Box, Button, CardMedia, TextField, Typography,
} from '@mui/material';
import DeleteCart from 'Images/delete_cart.svg';
import { formatAmount } from 'Utils/ulilities';
import Image from 'Shared/Image';
import ConfigContext from 'Shared/ConfigContext';
import IconButton from '@mui/material/IconButton';
import { format } from 'date-fns';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import FileDownloadIcon from '@mui/icons-material/FileDownload';

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: 'rgba(0,123,198,0.45)',
    color: '#026BAC',
    maxWidth: 420,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #36ABF3',
    fontWeight: 'bold',
    boxShadow: '0 2px 4px 0 rgba(0,0,0,0.25)',
  },
}));
export const Quantity = ({
  changeQuantity,
  handleChangeQuantity,
  value,
  row,
  v,
  gameQuantity = 1,
}) => (
  <HtmlTooltip
    sx={{ display: gameQuantity === 1 && 'none' }}
    title={`Ce produit  se vend par jeu de 3 quantité, Merci de remplir la quantité par ${gameQuantity}`}
    placement="top"
    arrow
  >
    <Box
      sx={{
        display: 'flex',
        height: { xs: '33px', md: '44px' },
        width: { xs: '94px', md: '147px' },
        border: '1px solid #CCCCCC',
        backgroundColor: '#FFFFFF',
        alignItems: 'center',
        mt: { xs: '13px', md: 0 },
        mb: { xs: '13px', md: 0 },
      }}
    >
      <Box
        sx={{
          color: '#007BC6',
          fontSize: '16px',
          fontWeight: 'bold',
          height: '100%',
          textAlign: 'center',
          width: { xs: '25px', md: '41.5px' },
          borderRight: '1px solid #CCCCCC',
          alignItems: 'center',
          display: 'flex',
          flexShrink: 0,
          cursor: 'pointer',
        }}
        onClick={row.quantity > gameQuantity && changeQuantity(-gameQuantity, row, v)}
      >
        <span style={{ width: '100%' }}>-</span>
      </Box>
      <TextField
        id="outlined-basic"
        label=""
        variant="standard"
        sx={{
          height: '100%',
          width: '100%',
        }}
        autoComplete="off"
        size="small"
        type="tel"
        value={value}
        name={row.id}
        disabled={gameQuantity !== 1}
        onChange={handleChangeQuantity}
        inputProps={{ sx: { height: '100%', border: 'none', textAlign: 'center' } }}
        // eslint-disable-next-line react/jsx-no-duplicate-props
        InputProps={{ sx: { height: '100%' }, disableUnderline: true }}
      />
      <Box
        sx={{
          color: '#007BC6',
          fontSize: '16px',
          fontWeight: 'bold',
          height: '100%',
          textAlign: 'center',
          width: { xs: '25px', md: '41.5px' },
          borderLeft: '1px solid #CCCCCC',
          alignItems: 'center',
          display: 'flex',
          flexShrink: 0,
          cursor: 'pointer',
        }}
        onClick={changeQuantity(gameQuantity, row, v)}
      >
        <span style={{ width: '100%' }}>+</span>
      </Box>
    </Box>
  </HtmlTooltip>
);

/* eslint-disable import/prefer-default-export */
const TitleStyle = {
  color: '#8F8F8F',
  fontSize: '14px',
  fontWeight: 600,
  background: '#FFF',
  borderBottom: 'none',
  paddingBottom: 0,
  paddingLeft: 0,
};
const RowStyle = { padding: '8px 0', backgroundColor: '#FFF' };
const defaultColumnStyle = { color: '#000', fontSize: '14px' };
export const cartColumns = (
  onHandleRemove,
  onHandleDownloadExpedition,
  onValidCart,
  onHandleOpenDetail,
  isSmallScreen,
  isCurrentCarts,
  isPopupCart = false,
  isCartTraiee = false,
) => {
  const validateBtnStyle = isSmallScreen
    ? {
      backgroundColor: 'unset',
      color: '#1976d2',
      textDecoration: 'underline',
      boxShadow: 'none',
    }
    : {};
  const actions = {
    id: 'action',
    label: 'Action',
    rowStyle: !isPopupCart ? RowStyle : { p: 0, borderBottom: 'unset' },
    style: { ...TitleStyle, textAlign: 'center' },
    render: ({ row, index }) => (
      <Box sx={{ display: 'flex', flexDirection: isSmallScreen ? 'row-reverse' : 'row' }}>
        {row.validated === 0 && (
          <CardMedia
            component="img"
            onClick={() => (!row.error ? onHandleRemove(index) : null)}
            src={DeleteCart}
            sx={{
              height: { xs: '18px', md: '24px' },
              width: { xs: '18px', md: '24px' },
              alignSelf: 'center',
              padding: { xs: '0 15px 0 10px', md: '0 30px 0 39px' },
              cursor: 'pointer',
            }}
          />
        )}
        <Button
          disabled={row.validated !== 0 || !row.products || row.products?.length === 0 || row.error}
          onClick={() => onValidCart(index)}
          variant="contained"
          color="primary"
          sx={{ textTransform: 'capitalize', borderRadius: 0, ...validateBtnStyle }}
        >
          {isSmallScreen ? 'Valider' : 'Valider le panier'}
        </Button>
      </Box>
    ),
  };
  const actionsValidatedCart = {
    id: 'action',
    label: "Déclaration d'expedition",
    rowStyle: !isPopupCart ? RowStyle : { p: 0, borderBottom: 'unset' },
    style: { ...TitleStyle, textAlign: 'center' },
    render: ({ row }) => (
      <IconButton
        sx={{
          margin: 'auto',
          padding: { xs: '0 15px 0 10px', md: '0 30px 0 39px' },
          cursor: 'pointer',
        }}
      >
        <FileDownloadIcon onClick={() => onHandleDownloadExpedition(row.bcNumber)} />
      </IconButton>
    ),
  };
  const columns = [
    {
      id: 'createdAt',
      label: 'Date',
      order: 'createdAt',
      style: { ...TitleStyle, paddingLeft: '10px' },
      rowStyle: !isPopupCart ? RowStyle : { p: 0, borderBottom: 'unset' },
      render: ({ value }) => (
        <Typography sx={{ ...defaultColumnStyle, fontWeight: 600, pl: '10px' }}>
          {format(value ? new Date(value) : new Date(), 'dd/MM/yyyy')}
        </Typography>
      ),
    },
    {
      id: 'name',
      label: 'Panier',
      style: TitleStyle,
      rowStyle: !isPopupCart ? RowStyle : { p: 0, borderBottom: 'unset' },
      render: ({ row, index }) => (
        <>
          <Typography
            sx={{
              ...defaultColumnStyle,
              color: '#007BC6',
              fontWeight: 600,
              cursor: 'pointer',
            }}
            onClick={onHandleOpenDetail(index)}
          >
            {row.name}
          </Typography>
          <Typography
            sx={{
              ...defaultColumnStyle,
              color: '#FF0006',
              fontSize: 11,
            }}
          >
            {row.error ? 'Merci de contacter copima pour regler la situation' : null}
          </Typography>
        </>
      ),
    },
    {
      label: 'Montant Brut',
      style: TitleStyle,
      rowStyle: !isPopupCart ? RowStyle : { p: 0, borderBottom: 'unset' },
      render: ({ row }) => (
        <Typography sx={{ ...defaultColumnStyle, fontWeight: 600 }}>
          {formatAmount(
            row.products?.reduce((prev, curr) => prev + curr.price * curr.quantity, 0)?.toFixed(2),
          )}
          DH
        </Typography>
      ),
    },
    {
      label: 'Prix Net',
      style: TitleStyle,
      rowStyle: !isPopupCart ? RowStyle : { p: 0, borderBottom: 'unset' },
      render: ({ row }) => (
        <Typography sx={{ ...defaultColumnStyle, fontWeight: 600 }}>
          {formatAmount(
            row.products
              ?.reduce(
                (prev, curr) => prev
                  + curr.price
                    * ((100.0
                      - (curr.remiseExceptionnelle
                        ? curr.remise + curr.remiseExceptionnelle
                        : curr.remise))
                      / 100.0)
                    * curr.quantity,
                0,
              )
              ?.toFixed(2),
          )}
          DH
        </Typography>
      ),
    },
    {
      id: 'products',
      label: 'Produits',
      style: TitleStyle,
      rowStyle: !isPopupCart ? RowStyle : { p: 0, borderBottom: 'unset' },
      render: ({ value }) => (
        <Typography sx={{ ...defaultColumnStyle, fontWeight: '600' }}>
          {value?.length || 0}
        </Typography>
      ),
    },
  ];
  if (!isCurrentCarts) {
    if (isCartTraiee) {
      return [...columns, actionsValidatedCart];
    }
    return columns;
  }

  if (isPopupCart) return [...columns, actions];

  return isSmallScreen ? [actions, ...columns] : [...columns, actions];
};
export const columns = (onHandleRemove, changeQuantity, v) => [
  {
    id: 'image',
    label: '',
    style: TitleStyle,
    rowStyle: RowStyle,
    render: ({ value }) => <Image component="img" src={value} width="90.67px" />,
  },
  {
    id: 'reference',
    label: 'Référence',
    style: TitleStyle,
    rowStyle: RowStyle,
    render: ({ value }) => (
      <Typography sx={{ ...defaultColumnStyle, color: '#007BC6', fontWeight: 600 }}>
        {value}
      </Typography>
    ),
  },
  {
    id: 'name',
    label: 'Produit',
    style: TitleStyle,
    rowStyle: RowStyle,
    render: ({ value }) => <Typography sx={defaultColumnStyle}>{value}</Typography>,
  },
  {
    id: 'price',
    label: 'Prix Brut ttc',
    style: TitleStyle,
    rowStyle: RowStyle,
    render: ({ value }) => (
      <Typography sx={defaultColumnStyle}>
        {formatAmount(value?.toFixed(2))}
        DH
      </Typography>
    ),
  },
  {
    id: 'remise',
    label: 'Remise',
    style: TitleStyle,
    rowStyle: RowStyle,
    render: ({ row }) => (
      <Typography sx={{ ...defaultColumnStyle, color: '#27AE60', fontWeight: 'bold' }}>
        {row.remise
          && `-${row.remiseExceptionnelle ? row.remise + row.remiseExceptionnelle : row.remise}%`}
      </Typography>
    ),
  },
  {
    id: 'priceNet',
    label: 'Prix Net',
    style: TitleStyle,
    rowStyle: RowStyle,
    render: ({ row }) => (
      <Typography sx={defaultColumnStyle}>
        {formatAmount(
          (
            row.price
            * ((100.0
              - (row.remiseExceptionnelle ? row.remise + row.remiseExceptionnelle : row.remise))
              / 100.0)
          )?.toFixed(2),
        )}
        DH
      </Typography>
    ),
  },
  {
    id: 'action',
    label: 'Action',
    rowStyle: RowStyle,
    style: { ...TitleStyle, textAlign: 'center' },
    render: ({ row }) => (
      <CardMedia
        component="img"
        onClick={!row.error ? onHandleRemove(row.id, row.linkedProduct) : null}
        src={DeleteCart}
        sx={{
          height: '24px',
          width: '24px',
          alignSelf: 'center',
          padding: '0 30px 0 39px',
          cursor: 'pointer',
        }}
      />
    ),
  },
  {
    id: 'quantity',
    label: 'Quantité',
    style: TitleStyle,
    rowStyle: RowStyle,
    render: ({ value, row }) => {
      const {
        state: { paniers },
      } = useContext(ConfigContext);
      const handleChangeQuantity = (event) => {
        const { value: val, name } = event.target;
        const po = paniers[v].products.find((p) => p.id === parseInt(name, 10));
        changeQuantity(0, po, v, parseInt(val || 0, 10))();
      };
      const game = row?.gameQuantity === null || row?.gameQuantity === 0 ? 1 : row?.gameQuantity;
      return (
        <Quantity
          changeQuantity={changeQuantity}
          handleChangeQuantity={handleChangeQuantity}
          gameQuantity={game}
          value={value}
          row={row}
          v={v}
        />
      );
    },
  },
  {
    id: 'name',
    label: 'Prix total',
    style: TitleStyle,
    rowStyle: RowStyle,
    render: ({ row }) => (
      <Typography sx={{ ...defaultColumnStyle, fontWeight: 600 }}>
        {formatAmount(
          (
            row.price
            * ((100.0
              - (row.remiseExceptionnelle ? row.remise + row.remiseExceptionnelle : row.remise))
              / 100.0)
            * row.quantity
          )?.toFixed(2),
        )}
        DH
      </Typography>
    ),
  },
];
