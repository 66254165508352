import * as React from 'react';
import Box from '@mui/material/Box';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import { useHistory } from 'react-router-dom';
import { WithRolesComponent } from 'HOC/withRoles';
import { SettingsItems } from './Settings';
import { ReactComponent as Logo } from '../../Images/logo_bar.svg';

const DrawerMenu = ({
  pages, navTo, isOpen, setIsOpen, setOpenModalPwd, setOpenModal,
}) => {
  const history = useHistory();
  const toggleDrawer = (open) => (event) => {
    if (
      event
        && event.type === 'keydown'
        && ((event).key === 'Tab'
          || (event).key === 'Shift')
    ) {
      return;
    }

    setIsOpen(open);
  };

  return (
    <SwipeableDrawer
      anchor="left"
      open={isOpen}
      onClose={toggleDrawer(false)}
      onOpen={toggleDrawer(true)}
      PaperProps={{
        sx: { width: '97%' },
      }}
    >
      <Box
        // sx={{ width: 250 }}
        sx={{ boxShadow: '0 2px 4px 0 rgba(0,0,0,0.29)' }}
        role="presentation"
        onClick={toggleDrawer(false)}
        onKeyDown={toggleDrawer(false)}
      >
        <List sx={{ boxShadow: '0 2px 4px 0 rgba(0,0,0,0.29)' }}>
          <ListItem disablePadding sx={{ boxShadow: '0 2px 4px 0 rgba(0,0,0,0.09)' }}>
            <ListItemButton sx={{ justifyContent: 'flex-end' }}>
              <ListItem sx={{ justifyContent: 'flex-start' }}>
                <Logo style={{ height: '30px', width: '100px' }} />
              </ListItem>
              <ListItemIcon sx={{ justifyContent: 'flex-end' }}>
                <CancelOutlinedIcon />
              </ListItemIcon>
            </ListItemButton>
          </ListItem>
          {pages.map((page) => (
            <WithRolesComponent allowedRoles={page.allowedRoles} key={page.to}>
              <ListItem disablePadding>
                <ListItemButton
                  sx={{
                    borderLeft: history?.location.pathname === page.to && '5px solid #007BC6',
                    borderBottom: '1px solid #CCCCCC',
                  }}
                  onClick={() => navTo(page.to)}
                >
                  <ListItemText
                    primary={page.label}
                    sx={{
                      color: '#4D4D4D',
                      fontSize: '14px',
                      fontweight: '600',
                    }}
                  />
                </ListItemButton>
              </ListItem>
            </WithRolesComponent>
          ))}
        </List>
        <Divider />
        <SettingsItems
          setOpenModalPwd={setOpenModalPwd}
          setOpenModal={setOpenModal}
          handleClose={() => null}
        />
      </Box>
    </SwipeableDrawer>
  );
};

export default DrawerMenu;
