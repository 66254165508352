import { secureGet } from 'config/apiConfig';
import useSWR from 'swr';

export const defaultMessage = 'Erreur système';

export const getDashboardData = async () => {
  const { data } = await secureGet('/users/GetDashboard');
  return data;
};

export function useCaterogyData() {
  // todo use SWR here get data and errors
  const { data } = useSWR('/families', secureGet);
  return { data: data?.data };
}

export const getCaterogyData = async () => {
  const { data } = await secureGet('/families');
  return data;
};

export const getAnnoncementData = async (page) => {
  const { data } = await secureGet(`/announcements?page=${page}&sort=createdAt,desc`);
  return data;
};
