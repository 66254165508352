import React from 'react';
import {
  Box, Typography,
} from '@mui/material';
import { applyRemise } from './Available';

const Remise = ({ remise, remiseExceptionnelle, promo }) => (
  <Box sx={{
    position: 'absolute', top: 10, left: 10, backgroundColor: promo ? '#F79F1F' : '#007BC6', p: '3px 8px',
  }}
  >
    <Typography sx={{
      verticalAlign: 'middle', color: '#fff', fontSize: '12px', fontWeight: 600,
    }}
    >
      {`-${applyRemise(remise, remiseExceptionnelle)}%`}
    </Typography>
  </Box>
);

export default Remise;
