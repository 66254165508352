import {
  secureDelete, secureGet, securePost, securePut,
} from 'config/apiConfig';

export const defaultMessage = 'Erreur système';
export const getUsers = async (page, filters) => {
  let url = `/users/GetMinimalClients?page=${page.page}&size=${page.size}`;
  Object.keys(filters).forEach((key) => {
    if (filters[key]) {
      url += `&${key}=${filters[key]}`;
    }
  });
  const { data } = await secureGet(url);
  return data;
};
export const deleteUser = async (id) => {
  const { data } = await secureDelete(`/users/delete/${id}`);
  return data;
};
export const addUser = async (user) => {
  const { data } = await securePut('/users/add', user);
  return data;
};
export const resetUserPassword = async (body) => {
  const { data } = await securePost('/users/password/reset', body);
  return data;
};
export const ChangeMyPassword = async (body) => {
  const { data } = await securePost('/users/password/change', body);
  return data;
};
export const getClients = async () => {
  const { data } = await secureGet('/users/GetMinimalClients');
  return data;
};
export const updateProfile = async (profile) => {
  const { data } = await securePost('/users/updateProfile', profile);
  return data;
};
export const getProfile = async () => {
  const { data } = await secureGet('/users/me');
  return data;
};
