import React, { useContext, useEffect } from 'react';
import { Router, Switch, Route } from 'react-router-dom';
// import { useMediaQuery } from '@mui/material';
import { ToastContainer } from 'react-toastify';
import ProtectedRoute from 'Shared/ProtectedRoute';
import ConnectedStore from 'Shared/ConnectedStore';
import { Provider, initialState, UserReducer } from 'Shared/UserContext';
import ConfigContext, {
  Provider as ProviderConfig,
  initialState as initConfig,
  ConfigReducer,
} from 'Shared/ConfigContext';
import history from 'config/history';
import Users from 'Components/Users';
import Home from 'Components/Home';
import Catalog from 'Components/Catalog';
import CartPage from 'Components/CartPage/V1';
import Loader from 'Shared/Loader';
import Announcements from 'Components/Announcements';
import { initPaniers, setCommercials, setExpeditions } from 'Shared/ConfigContext/Actions';
import useNetworkLoader from 'hooks/useNetworkLoader';
import { getCarts, getCommercials, getExpeditions } from 'Services';
import ReactPiwik from 'react-piwik';
import { APP_PATH_NAME } from 'Utils/ulilities';
import Login from './Components/Login';
import Media from './Components/Media';
import Account from './Components/Account';

const MATOMO_SITE_IDS = {
  development: 1,
  production: 2, // uat
  prod: 3,
};

const piwik = new ReactPiwik({
  url: 'https://analytics.copima.ma',
  siteId:
    window.location.host === 'edge.copima.ma'
      ? MATOMO_SITE_IDS.prod
      : MATOMO_SITE_IDS[process.env.NODE_ENV],
});

function App() {
  const {
    dispatch,
    state: { profile },
  } = useContext(ConfigContext);
  const { callApi } = useNetworkLoader();

  const user = JSON.parse(localStorage.user || '{}');

  useEffect(() => {
    if (profile?.id) {
      ReactPiwik.push(['setUserId', profile.code || `id_${profile.id}`]);
      const browserType = `Sys: ${navigator.platform}// Name: ${navigator.appCodeName}, ${navigator.appName} // Version: ${navigator.appVersion}`;
      ReactPiwik.push(['trackEvent', 'Browser', browserType]);
      callApi(getCommercials, (data) => {
        dispatch(
          setCommercials(
            data.map((item) => ({ id: item.id, name: `${item.firstName} ${item.lastName}` })),
          ),
        );
      });
      callApi(getExpeditions, (data) => dispatch(setExpeditions(data)));
      callApi(getCarts, (data) => dispatch(initPaniers(data)), false);
    }
  }, [profile]);

  return (
    <Router history={piwik.connectToHistory(history, false)}>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
      <Switch>
        <Route path="/login" component={Login} exact />
        <ProtectedRoute path="/" component={user.role === 'ROLE_ADMIN' ? Users : Home} exact />
        <ProtectedRoute path="/users" component={Users} exact />
        <ProtectedRoute path={APP_PATH_NAME.catalog} component={Catalog} exact />
        <ProtectedRoute path="/cart" component={CartPage} exact />
        <ProtectedRoute path="/announcements" component={Announcements} exact />
        <ProtectedRoute path="/media" component={Media} exact />
        <ProtectedRoute path="/account" component={Account} exact />
      </Switch>
      <Loader />
    </Router>
  );
}
const ConnectedToUserContext = ConnectedStore(App, UserReducer, initialState, Provider);
export default ConnectedStore(ConnectedToUserContext, ConfigReducer, initConfig, ProviderConfig);
