import React, {
  useContext, useState, useEffect, useMemo,
} from 'react';
import { Box, Typography } from '@mui/material';
import FullScreenDialog from 'Shared/FullModal';
import RenderOnDemandThenHide from 'Components/RenderOnDemandThenHide';
import EnhancedTable from 'Shared/EnhancedTable';
import { toast } from 'react-toastify';
import { addProductCart } from 'Services';
import { setPanierProducts } from 'Shared/ConfigContext/Actions';
import useNetworkLoader from 'hooks/useNetworkLoader';
import ConfigContext from 'Shared/ConfigContext';
import { format } from 'date-fns';
import Footer from './Footer';
import { columns } from '../columns';
import { getTimelineCart } from '../services';
import { STATUS } from './CartStatusFilter';

const WIDTH_BULLET = 15;
const TimeLineITem = ({ date, status = '', isCurrent = false }) => (
  <Box sx={{ display: 'flex' }}>
    <Typography
      sx={{ padding: '12px 0', width: '160px', color: isCurrent ? '#4d4d4d' : '#CCCCCC' }}
    >
      {format(new Date(date), 'dd/MM/yyyy HH:mm:ss')}
    </Typography>
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        margin: '0 30px',
        position: 'relative',
      }}
    >
      <Box
        sx={{
          border: '1px solid #CCCCCC',
          width: '0px',
          left: `${Math.floor(WIDTH_BULLET / 2 - 0.5)}px`,
          height: '100%',
          position: 'absolute',
          zIndex: 1,
        }}
      />
      <Box
        sx={{
          backgroundColor: isCurrent ? '#27AE60' : '#CCCCCC',
          borderRadius: `${WIDTH_BULLET}px`,
          width: `${WIDTH_BULLET}px`,
          height: `${WIDTH_BULLET}px`,
          zIndex: 2,
        }}
      />
    </Box>
    <Typography
      sx={{ color: isCurrent ? '#4d4d4d' : '#CCCCCC', width: { xs: '160px', md: 'unset' } }}
    >
      {STATUS[status]}
    </Typography>
  </Box>
);

const TimeLine = ({ bcNumber }) => {
  const { callApi } = useNetworkLoader();
  const [timeline, setTimeLine] = useState([]);
  useEffect(() => {
    if (bcNumber) {
      callApi(
        () => getTimelineCart(bcNumber),
        (data) => {
          setTimeLine(data);
        },
      );
    }
  }, [bcNumber]);
  return (
    <>
      {timeline.map((item, index) => (
        <TimeLineITem {...item} isCurrent={index === 0} key={item.id} />
      ))}
    </>
  );
};
export const Indecators = ({ createdAt }) => (
  <Box
    sx={{
      border: createdAt ? 'unset' : '1px solid #CCCCCC',
      backgroundColor: '#FFFFFF',
      height: '41.5px',
      display: 'flex',
      justifyContent: { xs: 'space-between', md: 'flex-end' },
      alignItems: 'center',
      borderTopRightRadius: '5px',
      position: 'relative',
    }}
  >
    <RenderOnDemandThenHide visible={createdAt}>
      <Typography
        sx={{
          fontSize: '10px',
          fontWeight: 'bold',
          color: '#8F8F8F',
          position: 'absolute',
          left: '15px',
        }}
      >
        {`Panier créé le: ${createdAt}`}
      </Typography>
    </RenderOnDemandThenHide>
    <Box
      sx={{
        mr: '9px',
        height: '10px',
        width: '10px',
        borderRadius: '50%',
        backgroundColor: '#F79F1F',
      }}
    />
    <Typography sx={{ fontSize: '10px', fontWeight: 'bold', color: '#8F8F8F' }}>LIMITE</Typography>
    <Box
      sx={{
        ml: '20px',
        mr: '9px',
        height: '10px',
        width: '10px',
        borderRadius: '50%',
        backgroundColor: '#27AE60',
      }}
    />
    <Typography
      sx={{
        fontSize: '10px',
        fontWeight: 'bold',
        color: '#8F8F8F',
        mr: '20px',
      }}
    >
      DISPONIBLE
    </Typography>
    <Box
      sx={{
        mr: '9px',
        height: '10px',
        width: '10px',
        borderRadius: '50%',
        backgroundColor: '#EA2027',
      }}
    />
    <Typography
      sx={{
        fontSize: '10px',
        fontWeight: 'bold',
        color: '#8F8F8F',
        mr: '90px',
      }}
    >
      NON-DISPONIBLE
    </Typography>
  </Box>
);

const VIEW = { DETAILS: 0, TIMELINE: 1 };
const CartDetails = ({
  values,
  open,
  setOpen,
  paniers,
  value,
  setOpenConfirm,
  setProductCart,
  onHandleRemove,
}) => {
  const { callApi } = useNetworkLoader();
  const { dispatch } = useContext(ConfigContext);
  const [view, setView] = useState(VIEW.DETAILS);

  const handleChangeView = () => {
    setView(view === VIEW.DETAILS ? VIEW.TIMELINE : VIEW.DETAILS);
  };

  const onHandleProductRemove = (id, linkedProduct) => () => {
    if (paniers[value]?.validated === 0) {
      setOpenConfirm(true);
      setProductCart({ id, name: paniers[value].name, linkedProduct });
    }
  };

  const changeQuantity = (step, po, v, quantity) => () => {
    if (paniers[v]?.validated === 0) {
      let productIndex = -1;
      const pos = paniers[v].products.map((p, _index) => {
        let newP = p;
        if (p.id === po.id) {
          const newQuantity = !step ? quantity : p.quantity + step;
          if (p.stock < newQuantity) {
            toast.warn(
              'La quantité saisie est supérieure à la quantité disponible , Merci de saisir une valeur inférieure',
            );
          } else {
            newP = { ...p, quantity: newQuantity };
            productIndex = _index;
          }
        }
        return newP;
      });
      if (productIndex !== -1) {
        callApi(
          // eslint-disable-next-line max-len
          () => addProductCart(paniers[v].id, pos[productIndex].reference, pos[productIndex].quantity),
          (resp) => {
            dispatch(setPanierProducts(paniers[v].name, resp.products));
          },
        );
      }
    }
  };
  const onHandleClose = () => {
    setOpen(false);
    setView(VIEW.DETAILS);
  };
  console.log(paniers);
  const totalBrut = useMemo(
    () => paniers[value]?.products?.reduce((prev, curr) => prev + curr.price * curr.quantity, 0),
    [value, paniers],
  );
  const totalNet = useMemo(
    () => paniers[value]?.products?.reduce(
      (prev, curr) => prev
          + curr.price
            * ((100.0
              - (curr.remiseExceptionnelle ? curr.remise + curr.remiseExceptionnelle : curr.remise))
              / 100.0)
            * curr.quantity,
      0,
    ),
    [value, paniers],
  );
  return (
    <FullScreenDialog open={open} setOpen={onHandleClose}>
      <Box sx={{ ml: '15px', mr: '15px' }}>
        <Typography
          sx={{
            color: '#8F8F8F',
            fontSize: '18px',
            fontWeight: 600,
            textAlign: 'center',
          }}
        >
          Détails du panier:
        </Typography>
        <Typography
          sx={{
            color: '#4D4D4D',
            fontSize: '18px',
            fontWeight: 600,
            textAlign: 'center',
            mt: '25px',
            mb: '30px',
          }}
        >
          Panier BOUSKOURA
        </Typography>
        <RenderOnDemandThenHide visible={paniers[value]?.validated === 1}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: { xs: 'column', md: 'row' },
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: { xs: 'space-evenly', md: 'unset' },
                mb: '30px',
              }}
            >
              <Typography
                sx={{
                  textTransform: 'uppercase',
                  cursor: 'pointer',
                  color: view === VIEW.DETAILS ? '#007BC6' : '#8F8F8F',
                  fontSize: { xs: '12px', md: '14px' },
                  fontWeight: 600,
                  backgroundColor: 'white',
                  padding: { xs: '12px 0px', md: '12px 22px' },
                  borderRadius: '15px 15px 0 0',
                  border: '2px solid',
                  width: { xs: '50%', md: 'unset' },
                  textAlign: 'center',
                }}
                onClick={handleChangeView}
              >
                Détails
              </Typography>
              <Typography
                sx={{
                  textTransform: 'uppercase',
                  cursor: 'pointer',
                  color: view === VIEW.DETAILS ? '#8F8F8F' : '#007BC6',
                  fontSize: { xs: '12px', md: '14px' },
                  fontWeight: 600,
                  backgroundColor: 'white',
                  padding: { xs: '12px 0px', md: '12px 22px' },
                  borderRadius: '15px 15px 0 0',
                  border: '2px solid',
                  width: { xs: '50%', md: 'unset' },
                  textAlign: 'center',
                }}
                onClick={handleChangeView}
              >
                Suivi de la commande
              </Typography>
            </Box>
          </Box>
        </RenderOnDemandThenHide>
        <RenderOnDemandThenHide visible={view === VIEW.DETAILS}>
          <Box
            sx={{
              border: '1px solid #CCCCCC',
              backgroundColor: '#FFFFFF',
              height: '41.5px',
              display: 'flex',
              justifyContent: { xs: 'space-between', md: 'unset' },
              alignItems: 'center',
              borderTopRightRadius: '5px',
            }}
          >
            <Typography
              sx={{
                fontSize: { xs: '12px', md: '13px' },
                fontWeight: { xs: 'unset', md: 600 },
                marginLeft: { xs: '10px', md: '20.5px' },
              }}
            >
              {`Panier ${paniers[value]?.name} - ${paniers[value]?.products.length} Produits`}
            </Typography>
            <RenderOnDemandThenHide visible={values.currentCarts}>
              <Typography
                sx={{
                  color: '#EA2027',
                  fontSize: '14px',
                  fontWeight: 600,
                  cursor: 'pointer',
                  marginLeft: 'auto',
                  marginRight: '78px',
                }}
                onClick={onHandleRemove}
              >
                Supprimer ce panier
              </Typography>
            </RenderOnDemandThenHide>
          </Box>
          <Box sx={{ border: '1px solid #CCCCCC', borderBottom: 'unset' }}>
            {false && <Indecators createdAt={paniers[value]?.createdAt} />}
            <EnhancedTable
              static
              pagination={false}
              rows={paniers[value]?.products || []}
              headCells={columns(onHandleProductRemove, changeQuantity, value)}
              count={paniers[value]?.products?.length}
              nthStyle={false}
              fill={false}
              slice={false}
            />
          </Box>
          <Footer totalBrut={totalBrut} total={totalNet} />
        </RenderOnDemandThenHide>
        <RenderOnDemandThenHide visible={view === VIEW.TIMELINE}>
          <TimeLine bcNumber={paniers[value]?.bcNumber} />
        </RenderOnDemandThenHide>
      </Box>
    </FullScreenDialog>
  );
};

export default CartDetails;
