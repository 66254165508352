/* eslint-disable react/require-default-props */
import React, { useContext, useEffect, useMemo } from 'react';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import Footer from 'Components/CartPage/V1/Footer';
import { Button, Box, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import ConfigContext from 'Shared/ConfigContext';
import { toast } from 'react-toastify';
import ThumbUpOutlinedIcon from '@mui/icons-material/ThumbUpOutlined';
import {
  removePanier,
  removeProductFromCat,
  setPanierOpenState,
} from 'Shared/ConfigContext/Actions';
import { useHistory } from 'react-router-dom';
import { APP_PATH_NAME, formatAmount } from 'Utils/ulilities';
import RenderOnDemandThenHide from 'Components/RenderOnDemandThenHide';
import useForm from 'hooks/useForm';
import ValidateCart from 'Components/CartPage/ValidateCart';
import useNetworkLoader from 'hooks/useNetworkLoader';
import { deleteCart, deleteProductCart } from 'Services';
import EnhancedTable from 'Shared/EnhancedTable';
import { cartColumns } from 'Components/CartPage/columns';
import Modal from '../Modal';

function TabPanel(props) {
  const {
    children, value, index, ...other
  } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

export const ConfirmRemoveProductOrCart = ({
  cartName,
  cartId,
  id,
  linkedProductId,
  open,
  setOpen,
  isProduct,
  callBackAfterRemove,
}) => {
  const { dispatch } = useContext(ConfigContext);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
  const btnDelete = useMemo(() => (isProduct ? ' le produit' : ' le panier'), [isProduct]);
  const { callApi } = useNetworkLoader();
  const onHandleConfirm = () => {
    if (isProduct) {
      const ids = linkedProductId ? [id, linkedProductId] : [id];
      callApi(
        () => deleteProductCart(cartId, ids),
        () => {
          dispatch(removeProductFromCat(cartName, ids));
          toast.success('Le produit a été retiré du panier');
        },
      );
    } else {
      callApi(
        () => deleteCart(cartId),
        () => {
          dispatch(removePanier(cartName));
          toast.success('Le panier a été supprimé');
          callBackAfterRemove();
        },
      );
    }
    setOpen(false);
  };
  return (
    <Modal open={open} setOpen={setOpen}>
      <Box sx={{ textAlign: 'center' }}>
        <Typography
          sx={{
            color: '#8F8F8F',
            fontSize: { xs: '12px', md: '14px' },
            fontWeight: 600,
            mb: { xs: '15px', md: '85px' },
            ml: { xs: 0, md: '70px' },
            mr: { xs: 0, md: '70px' },
          }}
        >
          {isProduct
            ? 'Etes-vous sûr de vouloir retirer ce produit de votre panier?'
            : 'Etes-vous sûr de vouloir Annuler ce panier? Tous les produits seront supprimer'}
        </Typography>
        <Button
          onClick={() => setOpen(false)}
          variant="contained"
          color="inherit"
          sx={{
            border: '2px solid #CCCCCC',
            color: '#CCCCCC',
            backgroundColor: '#FFFFFF',
            textTransform: 'capitalize',
          }}
        >
          Annuler
        </Button>
        <Button
          onClick={onHandleConfirm}
          variant="contained"
          color="error"
          sx={{
            ml: '27px',
            border: '2px solid #EA2027',
            color: '#EA2027',
            backgroundColor: '#FFFFFF',
            textTransform: 'capitalize',
            '&:hover': {
              color: '#FFF',
            },
          }}
        >
          {isSmallScreen ? 'supprimer' : `supprimer ${btnDelete}`}
        </Button>
      </Box>
    </Modal>
  );
};

const Cart = ({ open: op, anchorEl, handleClose }) => {
  const {
    dispatch,
    state: { paniers: originalCarts },
  } = useContext(ConfigContext);
  const paniers = originalCarts?.filter((cart) => cart.validated === 0);
  const [value, setValue] = React.useState(0);
  const { values, onChange } = useForm({});
  const [openConfirm, setOpenConfirm] = React.useState(false);
  const history = useHistory();
  const [open, setOpen] = React.useState(false);
  const [openSuccessValidation, setOpenSuccessValidation] = React.useState(false);

  useEffect(() => {
    window.dispatchEvent(new CustomEvent('resize'));
  }, []);

  const total = useMemo(() => {
    let sum = 0;
    paniers.forEach((element) => {
      sum += element?.products.reduce(
        (prev, curr) => prev
          + curr.price
            * ((100.0
              - (curr.remiseExceptionnelle ? curr.remise + curr.remiseExceptionnelle : curr.remise))
              / 100.0)
            * curr.quantity,
        0,
      );
    });
    return sum;
  }, [paniers]);
  const totalBrut = useMemo(() => {
    let sum = 0;
    paniers.forEach((element) => {
      sum += element?.products.reduce((prev, curr) => prev + curr.price * curr.quantity, 0);
    });
    return sum;
  }, [paniers]);
  const onHandleRemove = (cartIndex) => {
    setValue(cartIndex);
    setOpenConfirm(true);
  };
  const addNewCart = () => {
    dispatch(setPanierOpenState(true));
  };

  const gotToCart = () => {
    handleClose();
    history.push('/cart');
  };
  const onValidCart = (cartIndex) => {
    setValue(cartIndex);
    setOpen(true);
  };
  const navToCataloge = () => {
    setOpenSuccessValidation(false);
    history.push(APP_PATH_NAME.catalog);
  };
  return (
    <>
      <Modal open={openSuccessValidation} setOpen={setOpenSuccessValidation} isLarge>
        <Box sx={{ textAlign: 'center' }}>
          <ThumbUpOutlinedIcon fontSize="large" color="primary" />
          <Typography
            sx={{
              color: '#8F8F8F',
              fontSize: '14px',
              fontWeight: 600,
              textAlign: 'center',
              mt: '25px',
            }}
          >
            Votre panier a été validée avec succès
          </Typography>
          <Typography
            sx={{
              color: '#8F8F8F',
              fontSize: '14px',
              textAlign: 'center',
              mt: '32px',
            }}
          >
            Vous pouvez suivre votre commande sur votre éspace mon compte
          </Typography>
          <Box
            sx={{
              display: 'flex',
              mt: '45px',
              mr: '20px',
              ml: '20px',
            }}
          >
            <Button
              fullWidth
              onClick={() => setOpenSuccessValidation(false)}
              variant="contained"
              color="primary"
              sx={{ mr: '26px', textTransform: 'capitalize' }}
            >
              Accéder à mon compte
            </Button>
            <Button
              fullWidth
              onClick={navToCataloge}
              variant="contained"
              color="inherit"
              sx={{
                border: '2px solid #CCCCCC',
                color: '#CCCCCC',
                backgroundColor: '#FFFFFF',
                textTransform: 'capitalize',
              }}
            >
              Continuer mes Achats
            </Button>
          </Box>
        </Box>
      </Modal>
      <ValidateCart
        open={open}
        setOpen={setOpen}
        selectedCart={paniers[value]}
        onSuccessValidation={setOpenSuccessValidation}
        values={values}
        onChange={onChange}
      />
      <Popover
        id="cart-popover"
        open={op}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        sx={{
          '& .MuiPaper-root': {
            borderRadius: 0,
            width: paniers?.length === 0 ? '422px' : '732px',
            height: paniers?.length === 0 ? '230px' : '440px',
            backgroundColor: '#F8F8F8',
            boxShadow: '0 2px 4px 0 rgba(0,0,0,0.2)',
          },
        }}
      >
        <Box sx={{ display: 'flex', p: '22px  23px 14.5px 21px' }}>
          <Typography
            sx={{
              color: '#4D4D4D',
              fontSize: { xs: '16px', md: '18px' },
              fontWeight: 600,
            }}
          >
            Total des paniers en cours:
            {formatAmount(total?.toFixed(2))}
            Dhs
          </Typography>
        </Box>

        <RenderOnDemandThenHide visible={paniers?.length > 0}>
          <Box
            sx={{
              borderLeft: '1px solid #CCCCCC',
              borderRight: '1px solid #CCCCCC',
              height: '212px',
              overflowY: 'auto',
              overflowX: 'hidden',
            }}
          >
            <EnhancedTable
              static
              pagination={false}
              rows={paniers || []}
              headCells={
                  cartColumns(onHandleRemove, () => null, onValidCart, () => null, true, true, true)
              }
              count={paniers?.length}
              nthStyle={false}
              fill={false}
              slice={false}
            />
          </Box>
          <Footer totalBrut={totalBrut} total={total} isLightView />
        </RenderOnDemandThenHide>
        <RenderOnDemandThenHide visible={paniers?.length === 0}>
          <Box
            sx={{
              color: '#8F8F8F',
              fontSize: '12px',
              fontWeight: 500,
              textAlign: 'center',
            }}
          >
            <Typography
              sx={{
                color: '#8F8F8F',
                fontSize: '12px',
                fontWeight: 500,
              }}
            >
              Il n`y a aucun panier.
            </Typography>
            <Typography
              sx={{
                color: '#007BC6',
                fontWeight: 'bold',
                cursor: 'pointer',
                fontSize: '12px',
              }}
              onClick={addNewCart}
            >
              Créer un nouveau panier
            </Typography>
            <Typography
              sx={{
                color: '#8F8F8F',
                fontSize: '12px',
                fontWeight: 500,
              }}
            >
              pour commencer à ajouter des produits
            </Typography>
          </Box>
        </RenderOnDemandThenHide>

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row-reverse',
            position: 'absolute',
            bottom: 0,
            borderTop: '1px solid #CCC',
            p: '20px 0',
            width: '100%',
          }}
        >
          <Button
            disabled={!originalCarts?.length}
            sx={{
              mr: '20px',
              border: !paniers[value]?.products?.length ? '2px solid #CCC' : '2px solid #007BC6',
              color: '#007BC6',
              fontSize: '14px',
              fontWeight: 'bold',
              borderRadius: 0,
              textTransform: 'capitalize',
            }}
            onClick={gotToCart}
          >
            Détail des commandes
          </Button>
          <Button
            onClick={() => handleClose(false)}
            sx={{
              mr: '20px',
              border: 'none',
              color: '#555',
              fontSize: '14px',
              fontWeight: 'bold',
              borderRadius: 0,
              textTransform: 'capitalize',
            }}
          >
            Fermer
          </Button>
        </Box>
        <ConfirmRemoveProductOrCart
          open={openConfirm}
          setOpen={setOpenConfirm}
          cartName={paniers[value]?.name}
          cartId={paniers[value]?.id}
          callBackAfterRemove={() => null}
        />
      </Popover>
    </>
  );
};

export default Cart;
