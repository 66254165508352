/* eslint-disable no-case-declarations */
import {
  // eslint-disable-next-line max-len
  SET_NETWORK_ON,
  SET_NETWORK_OFF,
  SET_PRODUCT_TO_ADD,
  CHANGE_PANIER_OPEN_STATE,
  ADD_PANIER,
  REMOVE_PANIER,
  INIT_PANIERS,
  CHANGE_PANIER_LIST_OPEN,
  ADD_PRODUCT_TO_PANIER,
  REMOVE_PRODUCT_FROM_CART,
  SET_PROFILE,
  SET_EXPEDITIONS,
  SET_COMMERCIALS,
  VALIDATE_CART,
  SET_PANIER_PRODOCT,
} from './Actions';

const ConfigReducer = (state, action) => {
  switch (action.type) {
    case SET_NETWORK_ON:
      return { ...state, networking: state.networking + 1 };
    case SET_NETWORK_OFF:
      return { ...state, networking: state.networking - 1 };
    case CHANGE_PANIER_OPEN_STATE:
      return { ...state, panierOpenState: action.isOpen };
    case CHANGE_PANIER_LIST_OPEN:
      return {
        ...state,
        panierListState: action.isOpen,
        selectedProductTobeAdded: action.isOpen ? action.product : null,
      };
    case SET_PRODUCT_TO_ADD:
      return {
        ...state,
        selectedProductTobeAdded: action.product,
      };
    case ADD_PANIER:
      const newPanier = [
        {
          name: action.name,
          id: action.id,
          products: [],
          validated: 0,
          createdAt: new Date(),
        },
        ...state.paniers,
      ];
      if (state.paniers.length === 0 && state.selectedProductTobeAdded !== null) {
        const { handleClick, ...restProduct } = state.selectedProductTobeAdded;
        newPanier[0].products.push({ ...restProduct, quantity: 1 });
        return { ...state, selectedProductTobeAdded: null, paniers: newPanier };
      }
      return { ...state, paniers: newPanier };
    case ADD_PRODUCT_TO_PANIER:
      const { handleClick, ...restProduct } = state.selectedProductTobeAdded;
      const updatedPaniers = state.paniers.map((item) => {
        if (item.name === action.toName) {
          // eslint-disable-next-line max-len
          const isExist = item.products.find((product) => product.id === restProduct.id);
          if (isExist) {
            const tmp = item.products.filter((product) => product.id !== restProduct.id);
            tmp.push({ ...restProduct, quantity: isExist.quantity + action.quantity });
            return { ...item, products: tmp };
          }
          item.products.push({ ...restProduct, quantity: action.quantity });
        }
        return item;
      });
      return { ...state, paniers: updatedPaniers };
    case SET_PANIER_PRODOCT:
      const newCarts = state.paniers.map((p) => {
        let newP = p;
        if (p.name === action.name) {
          newP = { ...p, products: action.products };
        }
        return newP;
      });
      return { ...state, paniers: newCarts };
    case REMOVE_PANIER:
      return { ...state, paniers: state.paniers.filter(({ name }) => name !== action.name) };
    case REMOVE_PRODUCT_FROM_CART:
      const filtredPaniers = state.paniers.map((panier) => {
        if (panier.name === action.name) {
          const newP = panier.products.filter((product) => !action.ids.includes(product.id));
          return { ...panier, products: newP };
        }
        return panier;
      });
      return { ...state, paniers: filtredPaniers };
    case INIT_PANIERS:
      return { ...state, paniers: action.paniers };
    case SET_PROFILE:
      return { ...state, profile: action.profile };
    case SET_EXPEDITIONS:
      return { ...state, expeditions: action.data };
    case SET_COMMERCIALS:
      return { ...state, commercials: action.data };
    case VALIDATE_CART:
      const newPaniers = state.paniers.map((panier) => {
        if (panier.name === action.name) {
          return { ...panier, validated: 1 };
        }
        return panier;
      });
      return { ...state, paniers: newPaniers };
    default:
      return state;
  }
};

export default ConfigReducer;
