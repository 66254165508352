import { createContext } from 'react';

export const initialState = {
  categories: [],
  networking: 0,
  panierOpenState: false,
  panierListState: false,
  selectedProductTobeAdded: null,
  paniers: [],
  profile: {},
  commercials: [],
  expeditions: [],
  families: [],
};

const ConfigContext = createContext(initialState);

export const { Provider } = ConfigContext;

export default ConfigContext;
