import React, {
  useContext, useEffect, useMemo, useState,
} from 'react';
import AddIcon from '@mui/icons-material/Add';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import {
  Box, Button, Paper, Typography,
} from '@mui/material';
import { useHistory } from 'react-router-dom';
import Image from 'Shared/Image';
import { fr } from 'date-fns/locale';
import { format } from 'date-fns';
import ConfigContext from 'Shared/ConfigContext';
import {
  setOpenPanierList,
  setPanierOpenState,
  setSelectedProductToAdd,
} from 'Shared/ConfigContext/Actions';
import { getProductsData } from 'Components/Catalog/CatalogServices';
import useNetworkLoader from 'hooks/useNetworkLoader';
import { APP_PATH_NAME } from 'Utils/ulilities';
import FullScreenDialog from 'Shared/FullModal';
import Event from './event';
import './style.css';

const Flag = ({ text }) => (
  <Box
    sx={{
      backgroundColor: '#007BC6',
      border: '1px solid #005A91',
      height: '34px',
      padding: '0 10px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      position: 'absolute',
      top: '3px',
      left: 0,
    }}
  >
    <Typography color="#FFFFFF">{text}</Typography>
  </Box>
);

const AnnouncementCard = ({
  announcement, load, current, total,
}) => {
  const history = useHistory();
  const {
    dispatch,
    state: { paniers },
  } = useContext(ConfigContext);
  const [onProduct, setOnProduct] = useState(null);
  const [selectedAnnouncement, setSelectedAnnouncement] = useState();
  const [open, setOpen] = useState(false);
  const { callApi } = useNetworkLoader();
  const goToCatalog = () => {
    history.push(APP_PATH_NAME.catalog, announcement);
  };
  const references = useMemo(
    () => announcement?.productToggle
      && [...announcement?.newProducts, ...announcement?.promoProducts].unique(),
    [announcement],
  );
  const enableAdd = useMemo(() => references?.length === 1, [announcement]);
  const stopPropagation = (event) => {
    event.stopPropagation();
  };
  const handleOpenModal = () => {
    setOpen(true);
    setSelectedAnnouncement(announcement);
  };
  useEffect(() => {
    if (enableAdd && (!onProduct || !references.includes(onProduct.reference))) {
      callApi(
        () => getProductsData('', { references }),
        ({ content }) => {
          setOnProduct(content[0]);
        },
        false,
      );
    }
  }, [enableAdd, references, onProduct]);

  const addToCart = (event) => {
    if (paniers?.length === 0) {
      dispatch(setPanierOpenState(true));
      dispatch(setSelectedProductToAdd(onProduct));
    } else {
      dispatch(setOpenPanierList(true, onProduct));
    }
    stopPropagation(event);
  };
  return (
    <>
      <FullScreenDialog
        open={open}
        setOpen={setOpen}
        fullScreen={false}
        props={{ maxWidth: 'lg', fullWidth: true }}
      >
        <Event announcement={selectedAnnouncement} />
      </FullScreenDialog>
      <Paper
        sx={{
          display: 'flex',
          alignItems: 'center',
          width: '100%',
          height: '273px',
          cursor: 'pointer',
        }}
        onClick={announcement.type !== 'EVENT' && goToCatalog}
      >
        <KeyboardArrowLeftIcon
          onClick={current > 0 ? load(current - 1) : stopPropagation}
          sx={[
            { cursor: current === 0 ? 'default' : 'pointer', fontSize: '40px', color: 'gray' },
            current > 0 && {
              '&:hover': { color: 'black' },
            },
          ]}
        />
        <Box
          sx={{
            flexGrow: 1,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            height: '100%',
          }}
        >
          <Typography
            display="block"
            sx={{
              fontSize: { md: '24px', xs: '16px' },
              fontWeight: 'bold',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              display: '-webkit-box',
              '-webkit-line-clamp': { xs: '2', md: '4' },
              'line-clamp': { xs: '2', md: '4' },
              '-webkit-box-orient': 'vertical',
              width: { xs: '140px', md: 'unset' },
            }}
          >
            {announcement?.title}
          </Typography>
          <Typography
            display="block"
            sx={{ fontSize: { xs: '18px', md: '21px' }, fontWeight: 'bold' }}
          >
            {announcement?.description}
          </Typography>
          {announcement?.type === 'PROMOTION' && (
            <Typography
              sx={{
                color: '#8F8F8F',
                fontSize: { xs: '12px', md: '18px' },
                fontWeight: 'bold',
                mt: '15px',
              }}
              display="block"
            >
              {`Offre valable jusqu’au ${format(
                new Date(announcement?.endDate?.replace('+0000', '+0100')),
                'dd/MM/yyyy',
                { locale: fr },
              )}`}
            </Typography>
          )}
          {announcement.type === 'EVENT' && (
            <Button
              onClick={() => handleOpenModal()}
              sx={{
                mt: 1,
                width: { md: '200px', xs: '140px' },
                fontSize: { xs: '0.65rem', md: 'unset' },
              }}
              color="primary"
              variant="outlined"
            >
              Découvrir plus
            </Button>
          )}
          {announcement.type !== 'EVENT' && (
            <>
              {enableAdd && (
                <Button
                  disabled={!onProduct}
                  onClick={(event) => addToCart(event)}
                  sx={{
                    mt: 1,
                    width: { md: '200px', xs: '140px' },
                    fontSize: { xs: '0.65rem', md: 'unset' },
                  }}
                  color="primary"
                  variant="outlined"
                  startIcon={<AddIcon />}
                >
                  Ajouter au panier
                </Button>
              )}
              {!enableAdd && (
                <Button
                  sx={{ mt: 1, width: 'fit-content', fontSize: { xs: '0.65rem', md: 'unset' } }}
                  color="primary"
                  variant="outlined"
                >
                  Voir le détail de la promotion
                </Button>
              )}
            </>
          )}
        </Box>
        <Box
          sx={{
            position: 'relative',
            maxHeight: { md: '200px', xs: '150px' },
            width: 'auto',
            maxWidth: { md: '300px', xs: '225px' },
          }}
        >
          <Image
            src={announcement?.imageUrl}
            alt="annonce"
            style={{ width: 'auto', maxHeight: '200px', maxWidth: '100%' }}
          />
          {announcement?.type === 'PROMOTION' && announcement?.remise && (
            <Flag text={`${announcement?.remise}%`} />
          )}
          {announcement?.type === 'NOUVEAUTE' && <Flag text="Nouveau" />}
        </Box>
        <KeyboardArrowRightIcon
          onClick={current + 1 < total ? load(current + 1) : stopPropagation}
          sx={[
            {
              cursor: current + 1 < total ? 'pointer' : 'default',
              fontSize: '40px',
              color: 'gray',
            },
            current + 1 < total && {
              '&:hover': { color: 'black' },
            },
          ]}
        />
      </Paper>
    </>
  );
};

export default AnnouncementCard;
