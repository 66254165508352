import { secureGet, securePost } from 'config/apiConfig';
import { useState } from 'react';
import useSWR from 'swr';

export const defaultMessage = 'Erreur système';

export const getProductsData = async (params, body) => {
  const { data } = await securePost(`/products/criteria?${params}`, body);
  return data;
};

export function useProductsData() {
  // todo use SWR here get data and errors
  const [params, setParams] = useState('');
  const [body, setBody] = useState({});
  const { data } = useSWR([`/products/criteria?${params}`, body], securePost);
  const fire = (_params, _body) => {
    setParams(_params);
    setBody(_body);
  };
  return { fire, data };
}

export const getEquivalentsProduct = async (productId) => {
  const { data } = await secureGet(`/products/equivalents?productId=${productId}`);
  return data;
};

export const getBrands = async (params) => {
  const { data } = await secureGet(`/brands${params}`);
  return data;
};

export function useBrands(format) {
  const [params, setParams] = useState('');
  const { data } = useSWR(`/brands${params}`, secureGet);
  const fire = (_params) => {
    setParams(_params || '');
  };

  return { fire, data: format(data?.data) };
}
