/* eslint-disable max-len */
import React, { useContext, useEffect, useState } from 'react';
import {
  Box,
  Grid,
  Button,
  Typography,
  Pagination,
  IconButton,
  Divider, useMediaQuery,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import RenderOnDemandThenHide from 'Components/RenderOnDemandThenHide';
import AddIcon from '@mui/icons-material/Add';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import GridViewIcon from '@mui/icons-material/GridView';
import SplitscreenIcon from '@mui/icons-material/Splitscreen';
import { useCaterogyData } from 'Components/Home/HomeServices';
import withRoles from 'HOC/withRoles';
import useNetworkLoader from 'hooks/useNetworkLoader';
import { useLocation } from 'react-router-dom';
import SelectSmall from 'Shared/Select';
import ConfigContext from 'Shared/ConfigContext';
import {
  setOpenPanierList,
  setPanierOpenState,
  setSelectedProductToAdd,
} from 'Shared/ConfigContext/Actions';
import Image from 'Shared/Image';
import Modal from 'Shared/Modal';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import CircleIcon from '@mui/icons-material/Circle';
import useQuery from 'hooks/useQuery';
import { formatAmount, getStocks } from 'Utils/ulilities';
import ReactPiwik from 'react-piwik';
import defaultProduct from '../../Images/default_product.png';
import EdgeButton from '../../Shared/BootstrapInput/EdgeButton';
import FullScreenDialog from '../../Shared/FullModal';
import { useBrands, getEquivalentsProduct, useProductsData } from './CatalogServices';
import Families, { BrandsFilter, OtherFilters, scalePrice } from './Families';
import Deposit from './Deposit';
import Available, { applyRemise } from './Available';
import ProductDetail, { TagPromoNew } from './ProductDetail';
import Remise from './Remise';

const FILTERS_PRODUCT = [
  { name: 'Nouveau', id: 'new' },
  { name: 'Disponible', id: 'available' },
  { name: 'En promotion', id: 'promo' },
];

const Product = (props) => {
  const {
    dispatch,
    state: { paniers },
  } = useContext(ConfigContext);

  const {
    name,
    remise,
    remiseExceptionnelle,
    isNew,
    isLine,
    brand,
    image,
    family,
    reference,
    price,
    handleClick,
    promo,
    stockBouskoura,
    stockAinSebaa,
    stockDerbOmar,
    stockCfcim,
    reserved,
    reservedDO,
    isSmallScreen,
  } = props;
  const { stockDisponibleBouskoura, stockDisponibleDebOmar } = getStocks(
    stockDerbOmar,
    reservedDO,
    stockBouskoura,
    stockAinSebaa,
    stockCfcim,
    reserved,
  );
  const addToPanier = (e) => {
    if (paniers?.length === 0) {
      dispatch(setPanierOpenState(true));
      dispatch(setSelectedProductToAdd(props));
    } else {
      dispatch(setOpenPanierList(true, props));
    }
    e.stopPropagation();
  };

  if (isSmallScreen) {
    return (
      <ProductDetail
        product={props}
        stockDisponibleBouskoura={stockDisponibleBouskoura}
        stockDisponibleDebOmar={stockDisponibleDebOmar}
        addToPanier={addToPanier}
        onHandleClickCard={() => handleClick(props)}
        isSmallScreen
      />
    );
  }

  return (
    <Box
      sx={{
        position: 'relative',
        backgroundColor: '#FFFFFF',
        boxShadow: '0 2px 4px 2px rgba(0,0,0,0.07)',
        p: '20px 10px',
        height: isLine ? 'auto' : '100%',
        cursor: 'pointer',
      }}
      onClick={() => handleClick(props)}
    >
      <RenderOnDemandThenHide visible={applyRemise(remise, remiseExceptionnelle) && !isLine}>
        <Remise promo={promo} remise={remise} remiseExceptionnelle={remiseExceptionnelle} />
      </RenderOnDemandThenHide>
      <RenderOnDemandThenHide visible={isNew}>
        <TagPromoNew title="Nouveau" color="#27AE60" borderColor="#1C8A4A" />
      </RenderOnDemandThenHide>
      <RenderOnDemandThenHide visible={promo}>
        <TagPromoNew title="En promo" color="#F79F1F" borderColor="#CA7800" addMargin={isNew} />
      </RenderOnDemandThenHide>

      <RenderOnDemandThenHide visible={!isLine}>
        <Box sx={{ justifyContent: 'center', display: 'flex', minHeight: 100 }}>
          <Image
            src={(image && image) || defaultProduct}
            alt="categorie"
            width={100}
            style={{ maxHeight: 100 }}
          />
        </Box>
        <Box>
          <Typography
            sx={{
              color: '#8F8F8F',
              fontSize: 14,
              fontWeight: 600,
            }}
          >
            {`Réf: ${reference}`}
          </Typography>
        </Box>
        <Box>
          <Typography
            sx={{
              color: '#4D4D4D',
              fontSize: 14,
            }}
          >
            {name}
          </Typography>
        </Box>
        <Available
          sx={{ mt: 4 }}
          available={stockDisponibleBouskoura > 0 || stockDisponibleDebOmar > 0}
        />
        <Box sx={{ display: 'flex' }}>
          <Box sx={{ flex: 1 }}>
            <Typography
              sx={{
                color: '#8F8F8F',
                fontSize: 10,
                fontWeight: '600',
                textTransform: 'uppercase',
                mb: 1,
              }}
            >
              {family?.name}
            </Typography>
            <RenderOnDemandThenHide visible={applyRemise(remise, remiseExceptionnelle)}>
              <Typography
                sx={{
                  color: '#4D4D4D',
                  fontSize: 14,
                  fontWeight: 500,
                }}
              >
                {`${formatAmount(price)}DH`}
              </Typography>
              <Typography
                sx={{
                  color: '#F79F1F',
                  fontSize: 18,
                  fontWeight: 800,
                }}
              >
                {`${formatAmount(
                  Number((price * (100 - applyRemise(remise, remiseExceptionnelle))) / 100),
                  2,
                )}DH`}
              </Typography>
            </RenderOnDemandThenHide>
            <RenderOnDemandThenHide visible={!applyRemise(remise, remiseExceptionnelle)}>
              <Typography
                sx={{
                  color: '#4D4D4D',
                  fontSize: 18,
                  fontWeight: 800,
                }}
              >
                {`${formatAmount(price)}DH`}
              </Typography>
            </RenderOnDemandThenHide>
          </Box>
          <Box
            sx={{
              flex: 1,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Image
              src={(brand?.logoImage && brand?.logoImage) || defaultProduct}
              alt="categorie"
              width={60}
            />
          </Box>
        </Box>
        <Box
          sx={{
            position: 'absolute',
            bottom: 20,
            width: '100%',
            textAlign: 'center',
            left: 0,
          }}
        >
          <Button onClick={addToPanier} color="primary" variant="outlined" startIcon={<AddIcon />}>
            Ajouter au panier
          </Button>
        </Box>
      </RenderOnDemandThenHide>

      <RenderOnDemandThenHide visible={isLine}>
        <Grid container>
          <Grid item xs={2}>
            <Box sx={{ justifyContent: 'center', display: 'flex' }}>
              <Image
                src={(image && image) || defaultProduct}
                alt="categorie"
                style={{ height: '100px' }}
              />
            </Box>
          </Grid>
          <Grid item xs={10}>
            <Grid container sx={{ height: '100%' }}>
              <Grid item xs={5}>
                <Typography
                  sx={{
                    color: '#8F8F8F',
                    fontSize: 12,
                    fontWeight: 600,
                  }}
                >
                  {`Réf: ${reference}`}
                </Typography>
                <Typography
                  sx={{
                    color: '#4D4D4D',
                    fontSize: 14,
                  }}
                >
                  {name}
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography
                  sx={{
                    color: '#8F8F8F',
                    fontSize: 10,
                    fontWeight: '600',
                    textAlign: 'center',
                    textTransform: 'uppercase',
                  }}
                >
                  {family?.name}
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'flex-start',
                    justifyContent: 'center',
                  }}
                >
                  <Image
                    src={(brand?.logoImage && brand?.logoImage) || defaultProduct}
                    alt="categorie"
                    width={75}
                  />
                </Box>
              </Grid>
              <Grid item xs={2}>
                <Available
                  sx={{}}
                  available={stockDisponibleBouskoura > 0 || stockDisponibleDebOmar > 0}
                />
              </Grid>
              <Grid item xs={12}>
                <Deposit
                  bouskoura={stockDisponibleBouskoura > 0}
                  derbOmar={stockDisponibleDebOmar > 0}
                  isSmall
                />
              </Grid>
              <Grid
                item
                xs={6}
                sx={{
                  display: 'flex',
                  justifyContent: 'end',
                  flexDirection: 'column',
                }}
              >
                <RenderOnDemandThenHide visible={applyRemise(remise, remiseExceptionnelle)}>
                  <Box
                    sx={{
                      backgroundColor: promo ? '#F79F1F' : '#007BC6',
                      p: '3px 8px',
                      width: '40px',
                      mt: '12px',
                    }}
                  >
                    <Typography
                      sx={{
                        textAlign: 'center',
                        color: '#fff',
                        fontSize: '12px',
                        fontWeight: 600,
                      }}
                    >
                      {`-${applyRemise(remise, remiseExceptionnelle)}%`}
                    </Typography>
                  </Box>
                </RenderOnDemandThenHide>
                <RenderOnDemandThenHide visible={applyRemise(remise, remiseExceptionnelle)}>
                  <Box sx={{ display: 'flex', mt: '15px' }}>
                    <Typography
                      sx={{
                        color: '#F79F1F',
                        fontSize: 18,
                        fontWeight: 800,
                        mr: '17px',
                      }}
                    >
                      {`${formatAmount(
                        Number((price * (100 - applyRemise(remise, remiseExceptionnelle))) / 100),
                        2,
                      )}DH`}
                    </Typography>
                    <Typography
                      sx={{
                        color: '#4D4D4D',
                        fontSize: 14,
                        fontWeight: 500,
                        mt: '3px',
                      }}
                    >
                      {`${formatAmount(price)}DH`}
                    </Typography>
                  </Box>
                </RenderOnDemandThenHide>
                <RenderOnDemandThenHide visible={!applyRemise(remise, remiseExceptionnelle)}>
                  <Typography
                    sx={{
                      color: '#4D4D4D',
                      fontSize: 18,
                      fontWeight: 800,
                    }}
                  >
                    {`${formatAmount(price)}DH`}
                  </Typography>
                </RenderOnDemandThenHide>
              </Grid>
              <Grid
                item
                xs={6}
                sx={{ display: 'flex', justifyContent: 'end', flexDirection: 'column' }}
              >
                <Box
                  sx={{
                    width: '100%',
                    textAlign: 'right',
                  }}
                >
                  <Button
                    onClick={addToPanier}
                    color="primary"
                    variant="outlined"
                    startIcon={<AddIcon />}
                  >
                    Ajouter au panier
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </RenderOnDemandThenHide>
    </Box>
  );
};

export const SelectedPorduct = ({ product, onChangeProduct }) => {
  const {
    dispatch,
    state: { paniers },
  } = useContext(ConfigContext);
  const [productFull, setProductFull] = useState({});
  const { callApi } = useNetworkLoader();
  const [openGarantee, setOpenGarantee] = useState(false);
  const [isFr, setIsFr] = useState(true);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
  const { stockDisponibleBouskoura, stockDisponibleDebOmar } = getStocks(
    productFull.product?.stockDerbOmar,
    productFull.product?.reservedDO,
    productFull.product?.stockBouskoura,
    productFull.product?.stockAinSebaa,
    productFull.product?.stockCfcim,
    productFull.product?.reserved,
  );
  useEffect(async () => {
    ReactPiwik.push(['trackEvent', 'Référence', 'Consulté', product.reference]);
    callApi(
      () => getEquivalentsProduct(product.id),
      (data) => setProductFull({ product, items: data }),
    );
  }, [product]);

  const addToPanier = () => {
    if (paniers?.length === 0) {
      dispatch(setPanierOpenState(true));
      dispatch(setSelectedProductToAdd(product));
    } else {
      dispatch(setOpenPanierList(true, product));
    }
  };
  return (
    <>
      <Modal open={openGarantee} setOpen={setOpenGarantee} xLarge>
        <Typography
          sx={{
            color: '#4D4D4D',
            fontSize: { xs: '16px', md: '18px' },
            fontWeight: 600,
            mb: { xs: '10px', md: '38px' },
            textAlign: 'center',
          }}
        >
          Conditions de garanties des batteries
        </Typography>
        <Box sx={{ display: 'flex', mb: { xs: '10px', md: '50px' } }}>
          <Typography
            sx={{
              color: !isFr ? '#007BC6' : '#979797',
              fontSize: { xs: '12px', md: '14px' },
              fontWeight: 600,
              width: '50%',
              textAlign: 'right',
              pr: '31px',
              boxSizing: 'border-box',
              borderBottom: '1px solid #CCCCCC',
              height: '45px',
              lineHeight: '45px',
              cursor: 'pointer',
            }}
            onClick={() => setIsFr(false)}
          >
            العربية
          </Typography>
          <Typography
            sx={{
              color: isFr ? '#007BC6' : '#979797',
              fontSize: { xs: '12px', md: '14px' },
              fontWeight: 600,
              width: 'calc(50% - 1px)',
              borderLeft: '1px solid #CCCCCC',
              textAlign: 'left',
              pl: '31px',
              boxSizing: 'border-box',
              borderBottom: '1px solid #CCCCCC',
              height: '45px',
              lineHeight: '45px',
              cursor: 'pointer',
            }}
            onClick={() => setIsFr(true)}
          >
            français
          </Typography>
        </Box>
        <Box
          sx={{
            overflowY: { xs: 'auto', md: 'unset' },
            height: { xs: '320px', md: 'unset' },
            p: { xs: 0, md: '0 62px' },
            boxSizing: 'border-box',
            mb: { xs: '10px', md: '62px' },
          }}
        >
          <Typography
            sx={{
              direction: isFr ? 'ltr' : 'rtl',
              color: '#4D4D4D',
              pr: { xs: '8px', md: 'unset' },
              fontSize: { xs: '12px', md: '14px' },
              fontWeight: 500,
            }}
          >
            {isFr ? (
              <>
                <span>
                  Conditions de garanties des batteries Merci de noter que toutes les batteries
                  commercialisées par Copima sont sujettes aux conditions suivantes:
                </span>
                <br />
                <br />
                {' '}
                <span>
                  <CircleIcon sx={{ fontSize: '10px', mr: '15px' }} />
                  La date de reception chez Copima de la batterie pour avoir doit être inférieure ou
                  égale à 12 mois de la date de sa facturation par Copima .
                </span>
                <br />
                <span>
                  <CircleIcon sx={{ fontSize: '10px', mr: '15px' }} />
                  Le client doit présenter un certificat de garantie avec une étiquette
                  d’identification identique à celle de la batterie présentée La batterie ne doit
                  présenter aucun vice visible (bac ou couvercle cassé, bouts endommagés, autre
                  dommages physiques, etc.)
                </span>
                <br />
                <span>
                  <CircleIcon sx={{ fontSize: '10px', mr: '15px' }} />
                  Copima se donne le droit de refuser l’avoir des batteries si une -ou plusieursdes
                  conditions citées n’est pas respectée. Afin d’éviter des retours de batteries non
                  justifiés.
                </span>
                <br />
                <span>nous vous prions de tenir en compte les éléments suivants:</span>
                <br />
                <br />
                <span>
                  <span style={{ fontWeight: 'bold' }}>1 &#41;</span>
                  . Il est important de veiller à
                  une bonne rotation du stock (premier entré/ premier sorti), en particulier avec un
                  stock de gammes à faible volume qui s’écoule lentement. Nous améliorons
                  constamment nos délais de livraison afin de limiter pour nos clients un stockage
                  excessif.
                </span>
                <br />
                <br />
                <span>
                  <span style={{ fontWeight: 'bold' }}>2 &#41;</span>
                  . Assurez vous que le véhicule
                  du client est en bon état (vérification des composants électriques, de la tension
                  de la courroie, du système de démarrage allumage et préchauffage, etc.)
                </span>
                <br />
                <br />
                <span>
                  <span style={{ fontWeight: 'bold' }}>3 &#41;</span>
                  . Un chargeur de batterie non
                  adapté aux spécifications du fournisseur peut recharger la batterie trop
                  rapidement et l’endommager.
                </span>
              </>
            ) : (
              <div style={{ fontSize: { xs: '12px', md: '18px' } }}>
                <span>شروط ضمان البطارية:</span>
                <br />
                <span>
                  يرجى ملاحظة أن جميع البطاريات التي يتم تسويقها بواسطة كوبيما هي تخضع للشروط
                  التالية :
                </span>
                <br />
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row-reverse',
                    justifyContent: 'flex-end',
                  }}
                >
                  <span>
                    يجب أن يكون تاريخ استلام البطارية في كوبيما أقل من أو يساوي 12 شهرًا من تاريخ
                    إصدار الفواتير من قبل كوبيما
                  </span>
                  <CircleIcon sx={{ fontSize: '10px', ml: '15px', marginTop: '5px' }} />
                </div>
                <br />
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row-reverse',
                    justifyContent: 'flex-end',
                  }}
                >
                  <span>
                    يجب على العميل تقديم شهادة ضمان مع ملصق تعريف مطابق لتلك الخاصة بالبطارية
                    المقدمة
                  </span>
                  <CircleIcon sx={{ fontSize: '10px', ml: '15px', marginTop: '5px' }} />
                </div>
                <br />
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row-reverse',
                    justifyContent: 'flex-end',
                  }}
                >
                  <span>
                    يجب ألا تحتوي البطارية على عيب مرئي (درج أو غطاء نهايات مكسورة، تالفة، أضرار
                    مادية أخرى، إلخ.)تحتفظ كوبيما بالحق في رفض الحصول على البطاريات إذا لم يتم
                    احترام شرط أو أكثر من الشروط المذكورة.من أجل تجنب عودة البطارية غير المبررة،
                  </span>
                  <CircleIcon sx={{ fontSize: '10px', ml: '15px', marginTop: '5px' }} />
                </div>
                <br />
                <span>نطلب منك أن تضع في اعتباركيشمل العناصر التالية:</span>
                <br />
                <span>
                  1) من المهم ضمان دوران جيد للمخزون (أول دخول /أول خروج)، خاصةً مع مخزون نطاق الحجم
                  المنخفض الذي يتدفق ببطء. نحن نعمل باستمرار على تحسين مهلنا.التسليم للحد من التخزين
                  الزائد لعملائنا.
                </span>
                <br />
                <br />
                <span>
                  2) تأكد من أن سيارة العميل في حالة جيدة (التحقق من المكونات الكهربائية، توتر
                  الحزام، نظام بدء الاشتعال والتسخين، وما إلى ذلك)
                </span>
                <br />
                <br />
                <span>
                  3) شاحن بطارية غير مناسب لمواصفات المورد يمكن شحن البطارية بسرعة كبيرة وإتلافها.
                </span>
              </div>
            )}
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <Button
            onClick={() => setOpenGarantee(false)}
            variant="contained"
            color="primary"
            sx={{
              border: '2px solid #007BC6',
              color: '#007BC6',
              backgroundColor: '#FFFFFF',
              textTransform: 'capitalize',
              '&:hover': { color: '#FFF' },
            }}
          >
            Fermer
          </Button>
        </Box>
      </Modal>
      <Box sx={{ p: { xs: 0, md: '15px 35px' } }}>
        <ProductDetail
          product={productFull.product}
          stockDisponibleBouskoura={stockDisponibleBouskoura}
          stockDisponibleDebOmar={stockDisponibleDebOmar}
          addToPanier={addToPanier}
          onHandleClickCard={() => null}
          setOpenGarantee={setOpenGarantee}
          isSmallScreen={isSmallScreen}
          showRemise
        />
        <Divider sx={{ mt: 4, mb: 4 }} />
        <Typography
          sx={{
            color: '#4D4D4D',
            fontSize: { xs: '14px', md: 18 },
            fontWeight: 600,
            pl: { xs: '15px', md: 0 },
          }}
        >
          Produits équivalents:
        </Typography>
        <Grid container columnSpacing={2} rowSpacing={isSmallScreen ? 1 : 8} sx={{ mt: 1, mb: 5 }}>
          {productFull?.items?.map((item) => (
            <Grid item xs={isSmallScreen ? 12 : 3} key={item.id}>
              {isSmallScreen ? (
                <ProductDetail
                  product={item}
                  addToPanier={addToPanier}
                  onHandleClickCard={onChangeProduct}
                  isSmallScreen
                  {...getStocks(
                    item?.stockDerbOmar,
                    item?.reservedDO,
                    item?.stockBouskoura,
                    item?.stockAinSebaa,
                    item?.stockCfcim,
                    item?.reserved,
                  )}
                />
              ) : (
                <Product {...item} isNew={item.new} handleClick={onChangeProduct} />
              )}
            </Grid>
          ))}
        </Grid>
      </Box>
    </>
  );
};

const Home = () => {
  const [products, setProducts] = useState([]);
  const [open, setOpen] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState({});
  const [caterogies, setCaterogies] = useState([]);
  const [filters, setFilters] = useState([]);
  const [linkedFamilies, setLinkedFamilies] = useState([]);
  const [parentFilters, setParentFilters] = useState([]);
  const [brandsFilter, setBrandsFilter] = useState([]);
  const [productIds, setProductIds] = useState([]);
  const [prices, setPrices] = useState([0, scalePrice(100)]);
  const [pages, setPages] = useState(0);
  const [requestedFilters, setRequestedFilters] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [viewMode, setViewMode] = useState('box');
  const [filterVisibility, setFilterVisibility] = useState(true);
  const [sortBy, setSortBy] = useState('reference_asc');
  const query = useQuery();
  const { state: annoncement } = useLocation();
  const [currentState, setCurrentState] = useState(annoncement);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
  // const { callApi } = useNetworkLoader();
  const { data } = useCaterogyData();
  const { data: brands, fire: callBrandApi } = useBrands(
    (brandsData) => brandsData?.map((item) => item.name) || [],
  );
  useEffect(() => {
    if (isSmallScreen) {
      setViewMode('line');
      setFilterVisibility(false);
    }
  }, [isSmallScreen]);
  useEffect(() => {
    if (data) {
      const firstFamilies = data?.filter((item) => item.familyLevel === 1) || [];
      const family = query.get('family');
      const parentFamily = query.get('parentFamily');
      if (family && parentFamily) {
        // const sub = firstFamilies.find((item) => item.id === family);
        // setFilters([/* family, */...sub.subFamilies.map((item) => item.id)]);
        setFilters([family]);
        setParentFilters([parentFamily]);
      }
      setCaterogies(firstFamilies);
    }
  }, [data]);

  useEffect(async () => {
    let params = '?';
    filters?.forEach((item) => {
      if (!FILTERS_PRODUCT.includes(item)) {
        params += 'family='.concat(item).concat('&');
      }
      return item;
    });
    callBrandApi(params);
  }, [filters]);

  useEffect(() => {
    if (currentState) {
      if (!annoncement.productToggle) {
        const localBrands = (annoncement.linkedFamilies || []).reduce(
          (prev, curr) => [...prev, ...curr.brands].unique(),
          [],
        );
        setBrandsFilter(localBrands);
        const extraFilters = ['available'];
        if (annoncement.type === 'NOUVEAUTE') {
          extraFilters.push('new');
        }
        if (annoncement.type === 'PROMOTION') {
          extraFilters.push('promo');
        }
        const localFilters = (annoncement.linkedFamilies || []).map((l) => l.family);
        setLinkedFamilies(annoncement.linkedFamilies || []);
        setFilters(localFilters);
      } else {
        const extraFilters = ['available'];
        if (annoncement.type === 'NOUVEAUTE') {
          extraFilters.push('new');
        }
        if (annoncement.type === 'PROMOTION') {
          extraFilters.push('promo');
        }
        setFilters(extraFilters);
        setProductIds(
          [
            ...annoncement.newProducts,
            ...annoncement.promoProducts,
            ...annoncement.infoProducts,
          ].unique(),
        );
      }
      setCurrentState(null);
      setRequestedFilters(true);
    }
  }, [
    annoncement,
    setFilters,
    setBrandsFilter,
    currentState,
    setCurrentState,
    setRequestedFilters,
  ]);
  const resetFilters = () => {
    setFilters([]);
    setBrandsFilter([]);
    setProductIds([]);
    setLinkedFamilies([]);
    setParentFilters([]);
  };
  const { data: swrProduct, fire: callProductApi } = useProductsData();
  useEffect(() => {
    if (swrProduct?.data?.content) {
      setProducts(swrProduct?.data?.content);
      setPages(swrProduct?.data?.totalPages);
    }
  }, [swrProduct?.data]);
  useEffect(async () => {
    if (!currentState) {
      const sorttingItems = sortBy.split('_');

      const params = `size=24&sort=${sorttingItems[0]},${sorttingItems[1]}&page=${currentPage - 1}`;
      const body = {
        minPrice: prices[0],
        maxPrice: prices[1],
      };
      filters?.forEach((item) => {
        if (item === 'new') {
          body.new = true;
        } else if (item === 'promo') {
          body.promo = true;
        } else if (item === 'available') {
          body.available = true;
        } else if (item) {
          body.families = body.families ? [...body.families, item] : [item];
        }
      });
      if (brandsFilter && brandsFilter.length) {
        body.brands = brandsFilter;
      }
      if (productIds && productIds.length) {
        body.references = productIds;
      }
      body.linkedFamilies = linkedFamilies;
      callProductApi(params, body);
      /* callApi(() => getProductsData(params, body), ({ content, totalPages }) => {
        setProducts(content);
        setPages(totalPages);
      }); */
    }
  }, [
    currentPage,
    filters,
    prices,
    brandsFilter,
    sortBy,
    productIds,
    currentState,
    linkedFamilies,
  ]);

  const onHandlePage = (event, page) => {
    setCurrentPage(page);
  };

  const onHandleFilters = (id, subFamilies, isParent, isBrand) => {
    if (requestedFilters) {
      setProductIds([]);
      setLinkedFamilies([]);
    }
    setCurrentPage(1);
    if (isBrand) {
      if (brandsFilter.includes(id)) {
        setBrandsFilter(brandsFilter.filter((item) => id !== item));
      } else {
        setBrandsFilter([...brandsFilter, id]);
      }
      return;
    }

    const subIds = subFamilies?.map((item) => item.id) || [];
    if ((isParent ? parentFilters : filters).includes(id)) {
      setFilters(filters.filter((item) => !(isParent ? subIds : [...subIds, id]).includes(item)));
      if (isParent) {
        setParentFilters(parentFilters.filter((item) => item !== id));
      }
    } else {
      setFilters(isParent ? [...filters, ...subIds] : [...filters, id]);
      if (isParent) {
        setParentFilters([...parentFilters, id]);
      }
    }
  };

  const onClickProduct = (product) => {
    setOpen(!open);
    setSelectedProduct(product);
  };

  const isLine = viewMode === 'line';

  const getXS = (isVisible) => (isVisible ? 4 : 3);

  return (
    <Box sx={{ mb: 10, mt: { xs: 0, md: 4 } }}>
      <FullScreenDialog open={open} setOpen={setOpen}>
        <SelectedPorduct product={selectedProduct} onChangeProduct={setSelectedProduct} />
      </FullScreenDialog>
      <Grid container sx={{ position: 'relative' }}>
        <Grid
          item
          xs={(filterVisibility && (isSmallScreen ? 10 : 3)) || 0}
          sx={{
            position: { xs: 'absolute', md: 'unset' },
            top: '70px',
            zIndex: 1,
            backgroundColor: { xs: '#FAFAFA', md: 'unset' },
            boxShadow: {
              xs: '0 0 16px 0 rgb(0 0 0 / 18%), 0 16px 16px 0 rgb(0 0 0 / 24%)',
              md: 'unset',
            },
            display: filterVisibility ? 'block' : 'none',
            pr: 1,
            pl: { xs: 1, md: 0 },
          }}
        >
          <Families items={caterogies} filters={filters} onChangeFilter={onHandleFilters} />
          <Box sx={{ mt: 4, pl: 1, pr: 1 }}>
            <OtherFilters title="Filtre par prix" setPrices={setPrices} isPrice />
          </Box>
          <Box sx={{ mt: 4, mb: 4 }}>
            <OtherFilters
              title="Filtre par produit"
              items={FILTERS_PRODUCT}
              filters={filters}
              onChangeFilter={onHandleFilters}
            />
          </Box>
          <BrandsFilter
            brands={brands}
            filters={brandsFilter}
            onChangeFilter={onHandleFilters}
            restAll={() => setBrandsFilter([])}
          />
        </Grid>
        <Grid item xs={filterVisibility && !isSmallScreen ? 9 : 12}>
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Box sx={{ display: 'flow-root' }}>
              <EdgeButton
                color="primary"
                variant="contained"
                startIcon={
                  filterVisibility && (
                    <ArrowForwardIcon fontSize="small" sx={{ transform: 'rotate(180deg)' }} />
                  )
                }
                endIcon={!filterVisibility && <ArrowForwardIcon fontSize="small" />}
                onClick={() => setFilterVisibility(!filterVisibility)}
              >
                {filterVisibility ? 'Cacher les filtres' : 'Afficher les filtres'}
              </EdgeButton>
              <RenderOnDemandThenHide visible={!isSmallScreen}>
                <IconButton
                  onClick={() => setViewMode('line')}
                  size="small"
                  sx={{
                    ml: 2.5,
                    p: '7px',
                    backgroundColor: isLine ? '#007BC6' : '#fff',
                    borderRadius: 0,
                    border: !isLine && '1px solid #007BC6',
                  }}
                >
                  <SplitscreenIcon sx={{ color: isLine ? '#fff' : '#007BC6' }} />
                </IconButton>
                <IconButton
                  onClick={() => setViewMode('box')}
                  size="small"
                  sx={{
                    ml: 2.5,
                    p: '7px',
                    backgroundColor: !isLine ? '#007BC6' : '#fff',
                    borderRadius: 0,
                    border: isLine && '1px solid #007BC6',
                  }}
                >
                  <GridViewIcon sx={{ color: !isLine ? '#fff' : '#007BC6' }} />
                </IconButton>
              </RenderOnDemandThenHide>
              <IconButton
                onClick={resetFilters}
                size="small"
                sx={{
                  ml: 2.5,
                  p: '7px',
                  backgroundColor: '#fff',
                  borderRadius: 0,
                  border: '1px solid #007BC6',
                }}
              >
                <FilterAltOffIcon sx={{ color: '#007BC6' }} />
              </IconButton>
            </Box>
            <SelectSmall
              name="Trié par"
              options={[
                { value: 'price_asc', label: 'Prix bas à élevé' },
                { value: 'price_desc', label: 'Prix élevé à bas' },
                { value: 'reference_asc', label: 'Référence  croisant' },
                { value: 'reference_desc', label: 'Référence décroisant' },
              ]}
              sxRoot={{ flex: { xs: 1, md: 'unset' } }}
              value={sortBy}
              onChange={(e) => setSortBy(e.target.value)}
            />
            <Box sx={{ flex: 1, display: { xs: 'none', md: 'unset' } }}>
              <Pagination
                size="small"
                page={currentPage}
                count={pages}
                sx={{ float: 'right' }}
                onChange={onHandlePage}
              />
            </Box>
          </Box>
          <Box
            sx={{ display: { xs: 'flex', md: 'none' }, width: '100%', justifyContent: 'center' }}
          >
            <Pagination
              size="small"
              page={currentPage}
              count={pages}
              sx={{ float: 'right' }}
              onChange={onHandlePage}
            />
          </Box>
          <Grid
            container
            columnSpacing={2}
            rowSpacing={isLine ? 2 : 8}
            sx={{ mt: isLine ? 0.5 : -3, mb: 5 }}
          >
            <RenderOnDemandThenHide visible={isLine && !isSmallScreen}>
              <>
                <Grid item xs={2} />
                <Grid item xs={10} sx={{ pr: '10px' }}>
                  <Grid container>
                    <Grid item xs={5}>
                      <Typography
                        sx={{
                          color: '#8F8F8F',
                          fontSize: '14px',
                          fontWeight: 600,
                        }}
                      >
                        Produit/Prix
                      </Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <Typography
                        sx={{
                          color: '#8F8F8F',
                          fontSize: '14px',
                          fontWeight: 600,
                          textAlign: 'center',
                        }}
                      >
                        Catégotie
                      </Typography>
                    </Grid>
                    <Grid item xs={3}>
                      <Typography
                        sx={{
                          color: '#8F8F8F',
                          fontSize: '14px',
                          fontWeight: 600,
                          textAlign: 'center',
                        }}
                      >
                        Marque
                      </Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <Typography
                        sx={{
                          color: '#8F8F8F',
                          fontSize: '14px',
                          fontWeight: 600,
                        }}
                      >
                        Disponibilité
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </>
            </RenderOnDemandThenHide>
            {products.map((product) => (
              <Grid item xs={isLine ? 12 : getXS(filterVisibility)} key={product.id}>
                <Product
                  {...product}
                  key={product.id}
                  isNew={product.new}
                  isLine={isLine}
                  handleClick={onClickProduct}
                  isSmallScreen={isSmallScreen}
                />
              </Grid>
            ))}
          </Grid>
          <Box
            sx={{
              display: 'flex',
              width: '100%',
              justifyContent: { xs: 'center', md: 'flex-end' },
              float: 'right',
              mt: !isLine && '16px',
            }}
          >
            <Pagination
              size="small"
              page={currentPage}
              count={pages}
              sx={{ float: 'right' }}
              onChange={onHandlePage}
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default withRoles('ROLE_CLIENT')(Home);
