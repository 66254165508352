import React, { useEffect, useRef, useState } from 'react';
import {
  Chart as ChartJS,
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  LineController,
  BarController,
} from 'chart.js';
import { Chart } from 'react-chartjs-2';
import { Box } from '@mui/material';

ChartJS.register(
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  LineController,
  BarController,
);

const labels = ['Jan', 'Féb', 'Mar', 'Apr', 'Mai', 'Jui', 'Jul', 'Aou', 'Sep', 'Oct', 'Nov', 'Déc'];

function createGradient(ctx, area) {
  const gradient = ctx.createLinearGradient(0, area.bottom, 0, area.top);

  gradient.addColorStop(0, '#007BC6');
  gradient.addColorStop(1, '#70C5F9');

  return gradient;
}

export const data = {
  labels,
  datasets: [
    {
      type: 'line',
      label: 'Réglement',
      borderColor: '#F79F1F',
      borderWidth: 2,
      fill: false,
      data: labels.map(() => 0),
    },
    {
      type: 'bar',
      label: 'Chiffre d’affaire',
      data: labels.map(() => 0),
      borderRadius: 10,
      barPercentage: 0.2,
      categoryPercentage: 1,
    },
  ],
};

export default function ChartDashbord({ monthList, reglementMonthList }) {
  const chartRef = useRef(null);
  const [chartData, setChartData] = useState(data);

  useEffect(() => {
    const chart = chartRef.current;

    if (!chart || !monthList || monthList?.length === 0) {
      return;
    }
    const tmpData = {
      ...chartData,
      labels: monthList.map((month) => {
        const date = new Date(month.month);
        return `${labels[date.getMonth()]} (${date.getYear() - 100})`;
      }),
      datasets: chartData.datasets.map((dataset) => {
        if (dataset.type === 'bar') {
          return ({
            ...dataset,
            data: monthList.map((month) => month.amount),
            backgroundColor: createGradient(chart.ctx, chart.chartArea),
          });
        }
        return ({
          ...dataset,
          data: reglementMonthList.map((month) => month.amount),
        });
      }),
    };
    setChartData(tmpData);
  }, [monthList]);

  return (
    <Box sx={{
      border: { xs: 'unset', md: '1px solid #CCCCCC' },
      borderRadius: { xs: 0, md: '5px' },
      backgroundColor: '#FFFFFF',
      boxShadow: '0 2px 4px 2px rgba(0,0,0,0.07)',
      position: 'relative',
      height: { xs: '30vh', md: '40vh' },
    }}
    >
      <Chart
        ref={chartRef}
        type="bar"
        data={chartData}
        options={{
          plugins: { legend: { align: 'end' } },
          maintainAspectRatio: false,
          scales: {
            x: { grid: { display: false } },
            y: {
              grid: { display: false },
              title: {
                display: true,
                text: 'M.DH',
              },
            },
          },
        }}
      />
    </Box>
  );
}
