import * as React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Button } from '@mui/material';
import Input from 'Shared/Input';
import useForm from 'hooks/useForm';

const Identification = ({
  user, onChange, onClose, onSave,
}) => {
  const { values, onChange: onChangeForm } = useForm(user || {});

  const OnHandleSave = () => {
    onChange({ target: { name: 'email', value: values?.email } });
    onChange({ target: { name: 'phone', value: values?.phone } });
    onChange({ target: { name: 'address', value: values?.address } });
    onSave(values);
  };

  return (
    <Box>
      <Typography
        sx={{
          color: '#4D4D4D',
          fontSize: '18px',
          fontWeight: 600,
          mb: '42px',
          textAlign: 'center',
        }}
      >
        Ajout des informations de contact de l’entreprise:
      </Typography>
      <Input
        value={values?.email}
        onChange={onChangeForm}
        margin="dense"
        label="e-mail de l’entreprise:"
        name="email"
      />
      <Input
        value={values?.phone}
        onChange={onChangeForm}
        margin="dense"
        label="Numéro de téléphone de l’entreprise:"
        name="phone"
      />
      <Input
        value={values?.address}
        onChange={onChangeForm}
        margin="dense"
        label="Adresse:"
        name="address"
      />
      <Box sx={{
        height: '1px', backgroundColor: '#ccc', width: '100%', mb: '20px', mt: '20px',
      }}
      />
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <Button
          onClick={onClose}
          variant="contained"
          color="inherit"
          sx={{
            border: '2px solid #CCCCCC', color: '#CCCCCC', backgroundColor: '#FFFFFF', textTransform: 'capitalize', mr: '14px',
          }}
        >
          Annuler
        </Button>
        <Button
          onClick={OnHandleSave}
          variant="contained"
          color="primary"
          sx={{ textTransform: 'capitalize' }}
        >
          Enregistrer
        </Button>
      </Box>
    </Box>
  );
};

export default Identification;
