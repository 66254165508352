/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/media-has-caption */
import React, { useEffect, useRef, useState } from 'react';
import withRoles from 'HOC/withRoles';
import { Carousel } from 'react-responsive-carousel';
import { useMediaLinks } from './MediaServices';
import { ReactComponent as Logo } from '../../Images/logo_bar.svg';
import poster from '../../Images/poster.jpeg';

const PlayMedia = ({
  url, isSelected, selectedItem, onHandleNext, isMobile = false,
}) => {
  const vidRef = useRef(null);
  useEffect(() => {
    if (vidRef) {
      if (isMobile ? isSelected && selectedItem !== 0 : isSelected) {
        vidRef.current.play();
      } else {
        vidRef.current.pause();
        vidRef.current.currentTime = 0;
      }
    }
  }, [vidRef, isSelected]);
  return (
    <video ref={vidRef} onEnded={onHandleNext} poster={poster} width="100%" height={isMobile ? '206px' : '576px'} controls>
      <source src={url} type="video/mp4" />
      No media, please try later
    </video>
  );
};

const customRenderThumb = (children) => children.map((item) => <Logo videoUrl={item.props.url} width="100%" />);

const customRenderItem = (item, props) => <item.type {...item.props} {...props} />;

const Media = ({ showArrows = true, showThumbs = true, isMobile = false }) => {
  const { data } = useMediaLinks();
  const [selectedItem, setSelectedItem] = useState(0);
  const onHandleNext = () => {
    const next = selectedItem + 1;
    setSelectedItem(data.length < next ? 0 : next);
  };
  const onChange = (index) => {
    setSelectedItem(index);
  };
  return (
    <Carousel
      selectedItem={selectedItem}
      emulateTouch
      showArrows={showArrows}
      showThumbs={showThumbs}
      showStatus={false}
      infiniteLoop
      renderItem={customRenderItem}
      renderThumbs={customRenderThumb}
      onChange={onChange}
    >
      {data.map((mediaLink, index) => (
        <PlayMedia key={`track-id-${index}`} url={mediaLink} selectedItem={selectedItem} onHandleNext={onHandleNext} isMobile={isMobile} />
      ))}
    </Carousel>
  );
};

export default withRoles('ROLE_CLIENT')(Media);
