import * as React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import {
  Button, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup,
} from '@mui/material';
import SelectSmall from 'Shared/Select';
import ConfigContext from 'Shared/ConfigContext';
import useForm from 'hooks/useForm';

const Expedition = ({
  user, onChange, onClose, onSave, isPrimary = false,
}) => {
  const {
    state: {
      expeditions,
    },
  } = React.useContext(ConfigContext);
  const current = user?.expeditions.filter((exp) => exp.primary === isPrimary)
    ?.map((exp) => ({ ...exp, expedition: exp.expedition.id }));
  const { values, onChange: onChangeForm } = useForm(current ? current[0] : {});

  const OnHandleSave = () => {
    const expedition = {
      expedition: expeditions.find((exp) => exp.id === values?.expedition),
      type: values?.type,
      primary: isPrimary,
    };
    const oldExp = user?.expeditions.filter((exp) => exp.primary !== isPrimary);
    onChange({ target: { name: 'expeditions', value: [...oldExp, expedition] } });
    onSave({ ...values, expeditions: [...oldExp, expedition] });
  };
  return (
    <Box>
      <Box sx={{ pl: '40px', pr: '40px' }}>
        <Typography
          sx={{
            color: '#4D4D4D',
            fontSize: '18px',
            fontWeight: 600,
            mb: '42px',
            textAlign: 'center',
          }}
        >
          Ajout d’un transport
        </Typography>
        <Typography
          sx={{
            color: '#8F8F8F',
            fontSize: '14px',
            fontWeight: 600,
            mb: '18px',
          }}
        >
          Seléctionnez votre transport:
        </Typography>
        <SelectSmall
          sx={{ width: '100%' }}
          options={expeditions || []}
          value={values?.expedition}
          label=""
          name="expedition"
          onChange={onChangeForm}
          large
        />
        <FormControl>
          <FormLabel
            id="buttons-group-label"
            sx={{
              color: '#8F8F8F',
              fontSize: '14px',
              fontWeight: 600,
              mt: '30px',
              mb: '18px',
            }}
          >
            Type de transport:
          </FormLabel>
          <RadioGroup
            row
            aria-labelledby="buttons-group-label"
            name="type"
          >
            <FormControlLabel
              value="GARE"
              checked={values?.type === 'GARE'}
              sx={{
                ml: 0,
                color: '#4D4D4D',
                fontSize: '14px',
                fontWeight: 500,
              }}
              onChange={onChangeForm}
              control={<Radio />}
              label="En gare"
            />
            <FormControlLabel
              value="DOMICILE"
              checked={values?.type === 'DOMICILE'}
              sx={{
                ml: { xs: 0, md: '172px' },
                color: '#4D4D4D',
                fontSize: '14px',
                fontWeight: 500,
              }}
              onChange={onChangeForm}
              control={<Radio />}
              label="A domicile"
            />
          </RadioGroup>
        </FormControl>
      </Box>
      <Box sx={{
        height: '1px', backgroundColor: '#ccc', width: '100%', mb: '20px', mt: '20px',
      }}
      />
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <Button
          onClick={onClose}
          variant="contained"
          color="inherit"
          sx={{
            border: '2px solid #CCCCCC', color: '#CCCCCC', backgroundColor: '#FFFFFF', textTransform: 'capitalize', mr: '14px',
          }}
        >
          Annuler
        </Button>
        <Button
          disabled={!values?.expedition || !values?.type}
          onClick={OnHandleSave}
          variant="contained"
          color="primary"
          sx={{ textTransform: 'capitalize' }}
        >
          Enregistrer
        </Button>
      </Box>
    </Box>
  );
};

export default Expedition;
