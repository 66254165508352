import {
  Box, FormControl, InputLabel, OutlinedInput, Paper, Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import useStyles from './style';
import UserAdd from './UserAdd';
import UserList from './UserList';

const Users = () => {
  const [open, setOpen] = useState(false);
  const [update, setUpdate] = useState(false);
  const [user, setUser] = useState();
  const [values, setValues] = useState({});
  const classes = useStyles();
  const handleChangeInput = (event) => {
    const { value, name } = event.target;
    setValues((prev) => ({ ...prev, [name]: value }));
  };
  const handleClose = () => {
    setOpen(false);
    setUser(undefined);
  };
  const selectUser = (u) => () => {
    setUser(u);
    setOpen(true);
  };
  useEffect(() => {
    const interval = setInterval(() => {
      setUpdate((prev) => !prev);
    }, 60000);
    return () => clearInterval(interval);
  }, []);
  return (
    <Box>
      {open && <UserAdd handleClose={handleClose} user={user} updateList={setUpdate} />}
      <Box>
        <Typography variant="h5" style={{ margin: '20px 50px' }}>
          Gestion des utilisateurs
        </Typography>
        <Box style={{ display: 'flex', margin: '20px 50px', alignItems: 'center' }}>
          <Box style={{ flexGrow: 1 }}>
            <FormControl margin="dense" variant="outlined">
              <InputLabel>Intitulé client</InputLabel>
              <OutlinedInput
                style={{ width: 360, marginRight: '15px' }}
                type="text"
                value={values.name}
                onChange={handleChangeInput}
                labelWidth={70}
                name="name"
              />
            </FormControl>
            <FormControl margin="dense" variant="outlined">
              <InputLabel>Code client</InputLabel>
              <OutlinedInput
                style={{ width: 360 }}
                type="text"
                value={values.code}
                onChange={handleChangeInput}
                labelWidth={70}
                name="code"
              />
            </FormControl>
          </Box>
        </Box>

        <Paper elevation={0} classes={{ root: classes.paper }}>
          <UserList filters={values} selectUser={selectUser} update={update} />
        </Paper>
      </Box>
    </Box>
  );
};

export default Users;
