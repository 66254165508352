import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
// import Typography from '@mui/material/Typography';
import Slide from '@mui/material/Slide';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CloseIcon from '@mui/icons-material/Close';

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

const FullScreenDialog = ({
  open, setOpen, goBack, children, fullScreen = true, props,
}) => {
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog
      fullScreen={fullScreen}
      open={open}
      onClose={handleClose}
      TransitionComponent={Transition}
      sx={{ '& .MuiDialog-paper': { backgroundColor: '#F8F8F8' } }}
      {...props}
    >
      <Box>
        <Box sx={{
          display: 'flex', flexDirection: 'row', justifyContent: 'space-between', p: '10px 40px',
        }}
        >
          <IconButton
            edge="start"
            onClick={goBack || handleClose}
            aria-label="close"
            sx={{ borderRadius: '50%', border: '1px solid #CCCCCC' }}
          >
            <ArrowBackIcon fontSize="small" />
          </IconButton>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
            sx={{ borderRadius: '50%', border: '1px solid #CCCCCC' }}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </Box>
        <Box>
          {children}
        </Box>
      </Box>
    </Dialog>
  );
};

export default FullScreenDialog;
