import SearchIcon from '@mui/icons-material/Search';
import {
  // eslint-disable-next-line max-len
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  Step,
  StepButton,
  StepLabel,
  Stepper,
  Typography,
} from '@mui/material';
import { getCaterogyData } from 'Components/Home/HomeServices';
import RenderOnDemandThenHide from 'Components/RenderOnDemandThenHide';
import { format } from 'date-fns';
import withRoles from 'HOC/withRoles';
import useDebounce from 'hooks/useDebounce';
import useForm from 'hooks/useForm';
import useNetworkLoader from 'hooks/useNetworkLoader';
import React, { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import EnhancedTable from 'Shared/EnhancedTable';
import FullScreenDialog from 'Shared/FullModal';
import { SuccessModal } from 'Shared/Header';
import Input, { InputFile } from 'Shared/Input';
import Modal from 'Shared/Modal';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { getProductsData } from 'Components/Catalog/CatalogServices';
import {
  ANNOUCE_TYPE, columns, stepsAnnancement, options,
} from './constants';
import ProductCategory from './ProductCategory';
import {
  addAnnouncements,
  addAttachement,
  deleteAnnouncements,
  getAnnouncements,
} from './Services';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

const InfoGenerale = ({
  values, onChange, onSubmit, onClose, onChangeWysiwyg, onResetFile,
}) => {
  const onHandleResetFile = (name) => () => onResetFile(name);
  return (
    <Grid container sx={{ mt: 4 }} spacing={4}>
      <Grid item xs={4}>
        <Input
          value={values.title}
          onChange={onChange}
          margin="dense"
          label="Titre de l’annonce:"
          name="title"
        />
      </Grid>
      <Grid item xs={12}>
        <FormControl>
          <FormLabel id="demo-row-radio-buttons-group-label">Type de l’annonce:</FormLabel>
          <RadioGroup row aria-labelledby="demo-row-radio-buttons-group-label" name="type">
            <FormControlLabel
              value="NOUVEAUTE"
              checked={values?.type === 'NOUVEAUTE'}
              onChange={onChange}
              control={<Radio />}
              label="Nouveauté"
            />
            <FormControlLabel
              value="PROMOTION"
              checked={values?.type === 'PROMOTION'}
              onChange={onChange}
              control={<Radio />}
              label="Promotion"
            />
            <FormControlLabel
              value="INFORMATION"
              checked={values?.type === 'INFORMATION'}
              onChange={onChange}
              control={<Radio />}
              label="Information"
            />
            <FormControlLabel
              value="EVENT"
              checked={values?.type === 'EVENT'}
              onChange={onChange}
              control={<Radio />}
              label="Evénement/autres"
            />
          </RadioGroup>
        </FormControl>
      </Grid>
      <RenderOnDemandThenHide visible={values?.type === 'EVENT'}>
        <Grid item xs={8}>
          <Typography sx={{ color: '#8F8F8F' }}>Déscription (300/300):</Typography>
          <Box sx={{ border: '2px solid #F0F0FA', mt: 1 }}>
            <Editor
              editorState={values?.description.editorState}
              toolbarClassName="toolbarClassName"
              wrapperClassName="wrapperClassName"
              toolbar={options}
              editorClassName="editorClassName"
              onEditorStateChange={(editorState) => onChangeWysiwyg({ editorState })}
            />
          </Box>
        </Grid>
        <Grid item xs={4} />
      </RenderOnDemandThenHide>
      <Grid item xs={4}>
        <Input
          value={values.startDate}
          onChange={onChange}
          type="date"
          margin="dense"
          label="Date début:"
          name="startDate"
        />
      </Grid>
      <Grid item xs={4}>
        <Input
          value={values.endDate}
          onChange={onChange}
          type="date"
          margin="dense"
          label="Date fin:"
          name="endDate"
        />
      </Grid>
      <Grid item xs={4} />
      <RenderOnDemandThenHide visible={values?.type !== 'EVENT'}>
        <Grid item xs={4}>
          <Input
            value={values.remise}
            onChange={onChange}
            type="number"
            margin="dense"
            label="Taux de remise:"
            name="remise"
            disabled={values.type !== 'PROMOTION'}
          />
        </Grid>
      </RenderOnDemandThenHide>
      <Grid item xs={4}>
        <InputFile
          name="image"
          label="Image de l’annonce:"
          value={values.image?.name || values.image}
          onChange={onChange}
          onResetFile={onHandleResetFile('image')}
        />
      </Grid>
      <RenderOnDemandThenHide visible={values?.type === 'EVENT'}>
        <Grid item xs={4}>
          <InputFile
            name="imageEvent"
            label="Image secondaire (optionelle):"
            value={values.imageEvent?.name || values.imageEvent}
            onChange={onChange}
            onResetFile={onHandleResetFile('imageEvent')}
          />
        </Grid>
        <Grid item xs={8}>
          <InputFile
            name="fileEvent"
            label="Fichier joint: (optionelle):"
            value={values.fileEvent?.name || values.fileEvent}
            onChange={onChange}
            onResetFile={onHandleResetFile('fileEvent')}
          />
        </Grid>
        <Grid item xs={8}>
          <Input
            value={values.linkEvent}
            onChange={onChange}
            margin="dense"
            label="Lien externe (optionnel):"
            name="linkEvent"
          />
        </Grid>
      </RenderOnDemandThenHide>
      <Grid item xs={6} />
      <Grid item xs={3}>
        <Button
          fullWidth
          onClick={onClose}
          variant="contained"
          color="inherit"
          sx={{
            border: '2px solid #CCCCCC',
            color: '#CCCCCC',
            backgroundColor: '#FFFFFF',
            textTransform: 'capitalize',
          }}
        >
          Annuler
        </Button>
      </Grid>
      <Grid item xs={3}>
        <Button
          fullWidth
          onClick={onSubmit}
          variant="contained"
          color="primary"
          sx={{ textTransform: 'capitalize' }}
        >
          Suivant
        </Button>
      </Grid>
    </Grid>
  );
};

const ErrorText = ({ label }) => (
  <Typography sx={{ fontSize: 10 }} variant="caption" color="error">
    {label}
  </Typography>
);

const NewAnnoucement = ({
  activeStep,
  setActiveStep,
  onClose,
  annouceToedit,
  savedCheckedProduct,
}) => {
  const [hasError, setError] = useState({});
  const [open, setOpen] = useState(false);
  const { values, onChange } = useForm(
    annouceToedit || {
      productToggle: ANNOUCE_TYPE.PRODUCT,
      all: { promo: false, new: false, info: false },
      description: {
        editorState: EditorState.createEmpty(),
      },
    },
  );
  const { callApi } = useNetworkLoader();
  const [caterogies, setCaterogies] = useState([]);
  const [eventAttachementImg, setEventAttachementImg] = useState(null);
  const [eventAttachementImg2, setEventAttachementImg2] = useState(null);
  const [eventAttachementFile, setEventAttachementFile] = useState(null);
  const { values: formFitlers, onChange: onChangeFilters } = useForm({
    ref: '',
    family: annouceToedit?.families?.length > 0 ? annouceToedit.families[0] : '',
    brand: annouceToedit?.brands?.length > 0 ? annouceToedit.brands[0] : '',
  });
  const isValidForm = (form) => {
    if (!form.title) {
      setError({ step: 0, message: <ErrorText label="/ajouter un titre" /> });
      return false;
    }
    if (!form.image?.name && !form.image) {
      setError({ step: 0, message: <ErrorText label="/ajouter Attachement" /> });
      return false;
    }
    if (!form.startDate) {
      setError({ step: 0, message: <ErrorText label="/Date début" /> });
      return false;
    }
    if (!form.endDate) {
      setError({ step: 0, message: <ErrorText label="/Date de fin" /> });
      return false;
    }
    return true;
  };
  useEffect(async () => {
    callApi(getCaterogyData, (data) => {
      const firstFamilies = data?.filter((item) => item.familyLevel === 1) || [];
      setCaterogies(firstFamilies);
    });
  }, []);

  useEffect(() => {
    if (eventAttachementImg && eventAttachementImg2 !== null && eventAttachementFile !== null) {
      callApi(
        () => addAnnouncements({
          ...values,
          description: draftToHtml(
            convertToRaw(values.description.editorState.getCurrentContent()),
          ),
          productToggle: values.productToggle === ANNOUCE_TYPE.PRODUCT,
          startDate: format(new Date(values.startDate), "yyyy-MM-dd'T'00:00:00zzzz"),
          endDate: format(new Date(values.endDate), "yyyy-MM-dd'T'23:59:59zzzz"),
          image: eventAttachementImg.fileName,
          imageEvent: eventAttachementImg2.fileName || '',
          fileEvent: eventAttachementFile.fileName || '',
        }),
        () => {
          setOpen(true);
          setEventAttachementImg(null);
          setEventAttachementImg2(null);
          setEventAttachementFile(null);
        },
      );
    }
  }, [eventAttachementImg, eventAttachementImg2, eventAttachementFile]);
  const onSubmitAnnouceEvent = () => {
    if (!isValidForm(values)) {
      return;
    }
    if (!values.description.editorState.getCurrentContent()) {
      setError({ step: 0, message: <ErrorText label="/Description" /> });
      return;
    }

    if (values?.image.name) {
      callApi(
        () => addAttachement(values.image),
        (data) => setEventAttachementImg(data),
      );
    } else {
      setEventAttachementImg({ fileName: values.image });
    }
    if (values?.imageEvent?.name) {
      callApi(
        () => addAttachement(values.imageEvent),
        (data) => setEventAttachementImg2(data),
      );
    } else {
      setEventAttachementImg2({ fileName: values.imageEvent || '' });
    }
    if (values?.fileEvent?.name) {
      callApi(
        () => addAttachement(values.fileEvent),
        (data) => setEventAttachementFile(data),
      );
    } else {
      setEventAttachementFile({ fileName: values.fileEvent || '' });
    }
  };
  const onSubmit = () => {
    if (!isValidForm(values)) {
      return;
    }
    if (
      values.productToggle === ANNOUCE_TYPE.CATEGORY_PRODUCT
      && values.linkedFamilies?.length === 0
    ) {
      setError({ step: 1, message: <ErrorText label="/Ajouter une catégorie marque" /> });
      return;
    }
    const callBackUploadAttachement = (data) => {
      if (values.productToggle && (values.all.promo || values.all.new)) {
        values.families = values.filter.family !== '' ? [values.filter.family] : [];
        values.brands = values.filter.brand !== '' ? [values.filter.brand] : [];
      }
      callApi(
        () => addAnnouncements({
          ...values,
          productToggle: values.productToggle === ANNOUCE_TYPE.PRODUCT,
          startDate: format(new Date(values.startDate), "yyyy-MM-dd'T'00:00:00zzzz"),
          endDate: format(new Date(values.endDate), "yyyy-MM-dd'T'23:59:59zzzz"),
          image: data.fileName,
          description: '',
        }),
        () => {
          setOpen(true);
        },
      );
    };
    if (values?.image.name) {
      callApi(() => addAttachement(values.image), callBackUploadAttachement);
    } else {
      callBackUploadAttachement(values.image);
    }
  };
  const handleStep = (step) => () => {
    if (values.type === 'EVENT') {
      onSubmitAnnouceEvent();
    } else {
      setActiveStep(step);
    }
  };

  const onCloseAll = () => {
    onClose();
    setOpen(false);
  };

  const onChangeWysiwyg = (value) => {
    onChange({ target: { name: 'description', value } });
  };
  const onResetFile = (name) => {
    onChange({ target: { name, value: null } });
  };
  return (
    <Box sx={{ ml: '100px', mr: '100px', mb: '40px' }}>
      <Typography
        sx={{
          color: '#4D4D4D',
          fontSize: 21,
          fontWeight: 600,
          textAlign: 'center',
        }}
      >
        Création d’une nouvelle annonce
      </Typography>
      <RenderOnDemandThenHide visible={values?.type !== 'EVENT'}>
        <Stepper nonLinear alternativeLabel activeStep={activeStep}>
          {stepsAnnancement.map((label, index) => (
            <Step key={label}>
              <StepButton color="inherit" onClick={handleStep(index)}>
                <StepLabel error={hasError?.step === index}>
                  {label}
                  {' '}
                  {hasError?.step === index && hasError.message}
                </StepLabel>
              </StepButton>
            </Step>
          ))}
        </Stepper>
      </RenderOnDemandThenHide>
      <RenderOnDemandThenHide visible={activeStep === 0}>
        <InfoGenerale
          values={values}
          onChange={onChange}
          onClose={onClose}
          onSubmit={handleStep(1)}
          onResetFile={onResetFile}
          onChangeWysiwyg={onChangeWysiwyg}
        />
      </RenderOnDemandThenHide>
      <RenderOnDemandThenHide visible={activeStep === 1}>
        <ProductCategory
          values={values}
          onChange={onChange}
          onPrev={handleStep(0)}
          onClose={onClose}
          onSubmit={onSubmit}
          caterogies={caterogies}
          savedCheckedProduct={savedCheckedProduct}
          formFitlers={formFitlers}
          onChangeFilters={onChangeFilters}
        />
      </RenderOnDemandThenHide>
      <SuccessModal open={open} setOpen={setOpen} onCloseAll={onCloseAll} />
    </Box>
  );
};

const ConfirmRemoveAnnouce = ({
  id, open, setOpen, callBack,
}) => {
  const { callApi } = useNetworkLoader();

  const onHandleConfirm = () => {
    callApi(
      () => deleteAnnouncements(id),
      () => {
        toast.success('Annonce supprimé avec succès');
        setOpen(false);
        callBack();
      },
    );
  };
  return (
    <Modal open={open} setOpen={setOpen}>
      <Box sx={{ textAlign: 'center' }}>
        <Typography
          sx={{
            color: '#8F8F8F',
            fontSize: '14px',
            fontWeight: 600,
            mb: '85px',
            ml: '70px',
            mr: '70px',
          }}
        >
          Etes-vous sûr de vouloir supprimer l`annonce?
        </Typography>
        <Button
          onClick={() => setOpen(false)}
          variant="contained"
          color="inherit"
          sx={{
            border: '2px solid #CCCCCC',
            color: '#CCCCCC',
            backgroundColor: '#FFFFFF',
            textTransform: 'capitalize',
          }}
        >
          Annuler
        </Button>
        <Button
          onClick={onHandleConfirm}
          variant="contained"
          color="error"
          sx={{
            ml: '27px',
            border: '2px solid #EA2027',
            color: '#EA2027',
            backgroundColor: '#FFFFFF',
            textTransform: 'none',
            '&:hover': {
              color: '#FFF',
            },
          }}
        >
          supprimer l&#39;annonce
        </Button>
      </Box>
    </Modal>
  );
};

const initialHtml = (html) => {
  const contentBlock = htmlToDraft(html);
  if (contentBlock) {
    const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
    return EditorState.createWithContent(contentState);
  }

  return EditorState.createEmpty();
};
const Announcements = () => {
  const { values, onChange } = useForm({});
  const [announcements, setAnnouncements] = useState([]);
  const [savedCheckedProduct, setSavedCheckedProduct] = useState([]);
  const [openConfirm, setOpenConfirm] = React.useState(false);
  const [annouceId, setAnnouceId] = React.useState(null);
  const [annouceEdit, setAnnouceEdit] = React.useState(null);
  const [activeStep, setActiveStep] = useState(0);
  const [open, setOpen] = useState(false);
  const { callApi } = useNetworkLoader();
  const debounceValue = useDebounce(values.search, 200);
  const getAnnoucementsApi = useCallback(
    (params) => callApi(() => getAnnouncements(params || {}, debounceValue), setAnnouncements),
    [setAnnouncements, debounceValue],
  );
  useEffect(() => {
    getAnnoucementsApi();
  }, [getAnnoucementsApi]);
  const onHandleRemoveAnnouce = (id) => () => {
    setOpenConfirm(true);
    setAnnouceId(id);
  };
  const getCheckedProduct = (references) => callApi(
    () => getProductsData(`page=0&size=${references.length}`, { references }),
    ({ content }) => {
      setSavedCheckedProduct(content);
    },
    false,
  );
  const onHandleEdit = (annouce) => () => {
    setOpen(true);
    if (annouce.type !== 'EVENT' && annouce.productToggle) {
      const checkedProducts = [
        ...annouce.infoProducts,
        ...annouce.newProducts,
        ...annouce.promoProducts,
      ];
      getCheckedProduct([...new Set(checkedProducts)]);
    }
    setAnnouceEdit({
      ...annouce,
      description: annouce.type === 'EVENT' && {
        editorState: initialHtml(annouce.descriptionEvent),
      },
      announcementId: annouce.id,
      productToggle: annouce.productToggle ? ANNOUCE_TYPE.PRODUCT : ANNOUCE_TYPE.CATEGORY_PRODUCT,
      startDate: annouce.startDate.split('T')[0],
      endDate: annouce.endDate.split('T')[0],
      all: {
        promo: annouce.productToggle && annouce.excludePromoProducts?.length > 0,
        new: annouce.productToggle && annouce.excludeNewProducts?.length > 0,
        info: annouce.productToggle && annouce.excludeInfoProducts?.length > 0,
      },
    });
  };

  const onCloseAnnouceModal = () => {
    setOpen(false);
    getAnnoucementsApi();
    setActiveStep(0);
  };
  const onHandleNew = () => {
    setAnnouceEdit(null);
    setSavedCheckedProduct([]);
    setOpen(true);
  };
  const goBack = () => {
    if (activeStep === 1) {
      setActiveStep(0);
    } else {
      setOpen(false);
    }
  };
  const onClose = (state) => {
    setOpen(state);
    setActiveStep(0);
  };
  return (
    <Box sx={{ mb: 10, mt: 3, minHeight: 400 }}>
      <FullScreenDialog open={open} setOpen={onClose} goBack={goBack}>
        <NewAnnoucement
          annouceToedit={annouceEdit}
          onClose={onCloseAnnouceModal}
          activeStep={activeStep}
          setActiveStep={setActiveStep}
          savedCheckedProduct={savedCheckedProduct}
        />
      </FullScreenDialog>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Typography
          sx={{
            color: '#4D4D4D',
            fontSize: '16px',
            fontWeight: 'bold',
            flexGrow: 1,
          }}
        >
          Gestion des annonces:
        </Typography>
        <Box sx={{ width: '350px' }}>
          <Input
            type="text"
            value={values.search}
            onChange={onChange}
            margin="dense"
            name="search"
            size="small"
            iconEnd={<SearchIcon />}
            placeholder="Recherche par titre"
          />
        </Box>
      </Box>
      <Box
        sx={{
          width: '100%',
          borderTop: '1px solid #CCCCCC',
          mt: 3,
          mb: 3,
        }}
      />
      <Button
        onClick={onHandleNew}
        color="primary"
        variant="outlined"
        sx={{ float: 'right', textTransform: 'capitalize' }}
      >
        Créer une nouvelle annonce
      </Button>
      <EnhancedTable
        pagination
        getData={getAnnoucementsApi}
        update={false}
        rows={announcements?.content || []}
        headCells={columns(onHandleRemoveAnnouce, onHandleEdit)}
        count={announcements?.totalElements}
        rowsPerPageOptions={[10, 15, 20]}
      />
      <RenderOnDemandThenHide visible={announcements.length === 0}>
        <Typography sx={{ textAlign: 'center' }}>&emsp; ⚠️ No Data Available &emsp;</Typography>
      </RenderOnDemandThenHide>
      <ConfirmRemoveAnnouce
        open={openConfirm}
        setOpen={setOpenConfirm}
        id={annouceId}
        callBack={getAnnoucementsApi}
      />
    </Box>
  );
};

export default withRoles('ROLE_ADMIN')(Announcements);
