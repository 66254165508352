import React from 'react';
import PropTypes from 'prop-types';
import {
  TableSortLabel, TableRow, TableHead, TableCell,
} from '@mui/material';
import useStyles from './style';

function EnhancedTableHead(props) {
  const {
    classes, order, orderBy, onRequestSort, headCells,
  } = props;
  const classess = useStyles();
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align="left"
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.order ? order : false}
            classes={{ stickyHeader: classess.stickyHeader }}
            style={headCell.parentStyle || headCell.style}
            onClick={headCell.onClick ? headCell.onClick : null}
          >
            {headCell.order && (
              <TableSortLabel
                active={orderBy === headCell.order}
                direction={orderBy === headCell.order ? order : 'asc'}
                onClick={createSortHandler(headCell.order)}
                className={classes.title}
                style={headCell.style}
              >
                {headCell.label}
              </TableSortLabel>
            )}
            <span className={classes.title} style={headCell.style}>
              {!headCell.order && headCell.label}
            </span>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
};

export default EnhancedTableHead;
