import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import { round } from 'Shared/Layout/SubHeader';
import { findExpireDateAndToday, formatAmount } from 'Utils/ulilities';

const titles = {
  PM: { MF: 'Devis PM:', default: 'Encours PM:' },
  H: { MF: 'Devis H:', default: 'Encours H:' },
  notValid: { MF: 'Devis non validés', default: 'Factures non réglées' },
  expired: { MF: 'devis échus', default: 'factures échues' },
  notExpired: { MF: 'devis non échus', default: 'factures non échues' },
  unpaid: { MF: 'Rejetés', default: 'Impayés' },
  currentReg: { MF: 'En cours de validation', default: 'Réglements En Cours' },
  expiredReg: { MF: 'En cours de validation', default: 'Règlements échus' },
  check: { MF: 'CAT 1', default: 'chèques' },
  effet: { MF: 'CAT 2', default: 'effets' },
  notReg: { MF: 'Non validés', default: 'Non réglées' },
  reg: { MF: 'Validés', default: 'Réglées' },
  invoice: { MF: 'Mes devis', default: 'Mes factures' },
  pieceReg: { MF: 'Nº de pièce', default: 'Réglement' },
  recReg: { MF: 'Date de réception', default: 'Date Réglement' },
  ecd: { MF: '', default: 'Evolution mensuelle du chiffre d’affaire' },
};

export const getTitleOfTypeMF = (titleKey) => {
  const user = JSON.parse(localStorage.user || '{}');
  const isMF = user.type === 'MF';
  return isMF ? titles[titleKey].MF : titles[titleKey].default;
};

const Detail = ({
  title, amount, isInvoice, isLast, invoices1 = [], invoices2 = [],
}) => (
  <Box
    sx={{
      pt: { xs: '20px', md: '30px' },
      pb: '30px',
      borderRight: !isLast && '1px solid #CCCCCC',
      borderTop: '1px solid #CCCCCC',
      textAlign: 'center',
      width: '100%',
    }}
  >
    <Typography sx={{ color: '#4D4D4D', fontSize: { xs: '12px', md: '16px' }, fontWeight: '600' }}>
      {title}
    </Typography>
    <Typography
      sx={{
        color: '#F79F1F',
        fontSize: { xs: '14px', md: '21px' },
        fontWeight: '600',
        mt: { xs: '11px', md: '30px' },
        mb: { xs: '14px', md: '30px' },
      }}
    >
      {`${formatAmount(amount)}Dhs`}
    </Typography>
    <Typography sx={{ color: '#4D4D4D', fontSize: { xs: '12px', md: '16px' } }}>
      {`${invoices1.length} ${
        isInvoice ? getTitleOfTypeMF('expired') : getTitleOfTypeMF('check')
      }: ${formatAmount(invoices1.reduce((prev, item) => prev + item.amount, 0)?.toFixed(2))}Dhs`}
    </Typography>
    <Typography sx={{ color: '#4D4D4D', fontSize: { xs: '12px', md: '16px' } }}>
      {`${invoices2.length} ${
        isInvoice ? getTitleOfTypeMF('notExpired') : getTitleOfTypeMF('effet')
      }: ${formatAmount(invoices2.reduce((prev, item) => prev + item.amount, 0).toFixed(2))}Dhs`}
    </Typography>
  </Box>
);

const ActualState = ({
  totalRegEncours,
  totalImpaye,
  totalNonRegle,
  encours,
  impayes,
  nonRegles,
  totalRegEncoursPm = 0,
  totalRegEncoursHuile = 0,
  totalImpayePm = 0,
  totalNonReglePm = 0,
  totalImpayeHuile = 0,
  totalNonRegleHuile = 0,
  plafond = 0,
  plafondHuile = 0,
}) => (
  <Box
    sx={{
      border: '1px solid #CCCCCC',
      borderRadius: { xs: 0, md: '5px' },
      backgroundColor: '#FFFFFF',
      boxShadow: '0 2px 4px 2px rgba(0,0,0,0.07)',
    }}
  >
    <Box sx={{ mt: '35px', mb: '35px', textAlign: 'center' }}>
      <Box sx={{ mb: '30px', display: 'flex', justifyContent: 'center' }}>
        <Typography
          sx={{
            color: '#F79F1F',
            fontSize: { xs: '14px', md: '21px' },
            fontWeight: 'bold',
          }}
        >
          {`${formatAmount(
            totalImpayePm
              + totalNonReglePm
              + totalRegEncoursPm
              + totalImpayeHuile
              + totalNonRegleHuile
              + totalRegEncoursHuile,
          )}Dhs`}
        </Typography>
        <Typography
          sx={{
            color: '#4D4D4D',
            fontSize: { xs: '14px', md: '21px' },
            fontWeight: 'bold',
          }}
        >
          {`/${formatAmount(plafond + plafondHuile)}Dhs (${
            formatAmount(
              round(
                ((totalImpayePm
                  + totalNonReglePm
                  + totalRegEncoursPm
                  + totalImpayeHuile
                  + totalNonRegleHuile
                  + totalRegEncoursHuile)
                  * 100)
                  / (plafond + plafondHuile),
              ),
              0,
            ) || 0
          }%)`}
        </Typography>
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <Typography
          sx={{ color: '#4D4D4D', fontSize: { xs: '12px', md: '16px' }, fontWeight: '600' }}
        >
          {getTitleOfTypeMF('PM')}
        </Typography>
        <Typography
          sx={{
            color: '#8F8F8F',
            fontSize: { xs: '12px', md: '16px' },
            fontWeight: '600',
            ml: '4px',
          }}
        >
          {`${formatAmount(totalImpayePm + totalNonReglePm + totalRegEncoursPm)}Dhs/${formatAmount(
            plafond,
          )}Dhs (${
            formatAmount(
              round(((totalImpayePm + totalNonReglePm + totalRegEncoursPm) * 100) / plafond),
              0,
            ) || 0
          }%)`}
        </Typography>
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <Typography
          sx={{ color: '#4D4D4D', fontSize: { xs: '12px', md: '16px' }, fontWeight: '600' }}
        >
          {getTitleOfTypeMF('H')}
        </Typography>
        <Typography
          sx={{
            color: '#8F8F8F',
            fontSize: '16px',
            fontWeight: '600',
            ml: '4px',
          }}
        >
          {`${formatAmount(
            totalImpayeHuile + totalNonRegleHuile + totalRegEncoursHuile,
          )}Dhs/${formatAmount(plafondHuile)}Dhs (${
            formatAmount(
              round(
                ((totalImpayeHuile + totalNonRegleHuile + totalRegEncoursHuile) * 100)
                  / plafondHuile,
              ),
              0,
            ) || 0
          } %)`}
        </Typography>
      </Box>
    </Box>
    <Box sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' } }}>
      <Detail
        title={getTitleOfTypeMF('notValid')}
        amount={totalNonRegle}
        invoices1={nonRegles?.filter((item) => {
          const { currentDate, dateEcheance } = findExpireDateAndToday(item);
          return currentDate >= dateEcheance;
        })}
        invoices2={nonRegles?.filter((item) => {
          const { currentDate, dateEcheance } = findExpireDateAndToday(item);
          return currentDate < dateEcheance;
        })}
        isInvoice
      />
      <Detail
        title={getTitleOfTypeMF('unpaid')}
        amount={totalImpaye}
        invoices1={impayes?.filter((item) => item.type === 'CHEQUE')}
        invoices2={impayes?.filter((item) => item.type !== 'CHEQUE')}
      />
      <Detail
        title={getTitleOfTypeMF('currentReg')}
        amount={totalRegEncours}
        invoices1={encours?.filter((item) => item.type === 'CHEQUE')}
        invoices2={encours?.filter((item) => item.type !== 'CHEQUE')}
        isLast
      />
    </Box>
  </Box>
);

export default ActualState;
